import React  from 'react';
import './App.css';
import './Recent.css';
import RecentLang from './RecentLang.json';

import { useNavigate } from "react-router-dom";

class RecentOccupancy extends React.Component {

	constructor(props) {

		super(props);

		this.state = {
			dashboardListJsonObj: null,
		};

		this.serverDataConvertCsvToJson = this.serverDataConvertCsvToJson.bind(this);
		this.getOccupancyForDashboard = this.getOccupancyForDashboard.bind(this);
		this.serverGetDashboardValues = this.serverGetDashboardValues.bind(this);
		this.handleSelectDashboard = this.handleSelectDashboard.bind(this);				
		this.renderYearSummaryOneMonth = this.renderYearSummaryOneMonth.bind(this);
		this.renderYearSummary = this.renderYearSummary.bind(this);
		this.renderAllDashboards = this.renderAllDashboards.bind(this);				
	}
	
	componentDidMount() {
		this.serverGetDashboardValues();
	}
	
	async serverDataConvertCsvToJson(dashboardID) {

		var baseURLAPI = this.props.baseURLAPI;
		var dataUrl = "/dashboard/" + dashboardID + "/roomsWholeYear/current.csv"
		var url = baseURLAPI + "/dataconvertcsvtojson?url=" +  encodeURIComponent(dataUrl);
		
		const res = await fetch(url);
		const jsonObj = await res.json();

		return jsonObj;
	}
	
	getItemMonthDay(jsonObj, month1, day1) {
		
		if (jsonObj === null) {
			return null;
		}

		var dataObj = jsonObj.data;
		var i;
		var itemObj;
		var month2;
		var day2;
		
		for(i=0; i<dataObj.length; i++) {
			itemObj = dataObj[i];
			month2 = itemObj.month;
			day2 = itemObj.day;
			if ((month1 === month2) && (day1 === day2)) {
				return itemObj;
			}
		}
		
		return null;
	}
	
	calcRoomsSumMonth(jsonObj, month1) {

		var i;
		var itemObj;
		var dayCount = 0;
		var rooms;
		var roomsSum = 0;
		var revenue;
		var revenueSum = 0;

		for(i=1; i<=31; i++) {
			itemObj = this.getItemMonthDay(jsonObj, month1, i);
			if (itemObj !== null) {
				rooms = parseInt(itemObj.rooms);
				revenue = parseFloat(itemObj.revenue);
				roomsSum = roomsSum + rooms;
				revenueSum = revenueSum + revenue;
				dayCount ++;
			}
		}

		return [roomsSum, revenueSum, dayCount]
	}

	calcAverageMonthYear(jsonObj, dashboardID) {
		
		if (jsonObj === null) {
			return;
		}

		var calcHelpersObj = this.props.calcHelpersObj;
		var totalRooms = calcHelpersObj.getTotalRooms(dashboardID);
		var i;
		var itemObj;
		var averageRoomsMonthArray = [];
		var averageRoomPriceMonthArray = [];
		var averageDailyRevenueMonthArray = [];
		var totalRevenueMonthArray = [];
		var totalRoomsMonthArray = [];
		var dayCountMonthArray = [];
		var roomsSumMonth;
		var revenueSumMonth;
		var dayCountMonth;
		var roomsPerDay;
		var roomPrice;
		var roomsSumYear = 0;
		var dayCountYear = 0;
		var averageDailyRevenue;
		var totalRevenueYear = 0;
		
		for(i=1; i<=12; i++) {
			[roomsSumMonth, revenueSumMonth, dayCountMonth] = this.calcRoomsSumMonth(jsonObj, i);
			roomsSumYear = roomsSumYear + roomsSumMonth;
			if (revenueSumMonth > 0) {
				dayCountYear = dayCountYear + dayCountMonth;
			}
			roomsPerDay = 0;
			averageDailyRevenue = 0;
			if (dayCountMonth > 0) {
				roomsPerDay = roomsSumMonth / dayCountMonth;
				averageDailyRevenue = revenueSumMonth / dayCountMonth;
			}
			if (roomsSumMonth === 0) {
				roomPrice = 0;
			} else {
				roomPrice = revenueSumMonth / roomsSumMonth;
			}
			averageRoomsMonthArray.push(roomsPerDay);
			averageRoomPriceMonthArray.push(roomPrice);
			totalRevenueMonthArray.push(revenueSumMonth);
			totalRoomsMonthArray.push(roomsSumMonth);
			dayCountMonthArray.push(dayCountMonth);
			averageDailyRevenueMonthArray.push(averageDailyRevenue);
			totalRevenueYear = totalRevenueYear + revenueSumMonth;
		}
		
		if (dayCountYear === 0) {
			dayCountYear = 1;
		}
		var averageRoomsYear = roomsSumYear / dayCountYear;
		var averageRoomPriceYear = 0;
		if (roomsSumYear > 0) {
			averageRoomPriceYear = totalRevenueYear / roomsSumYear;
		}
		var occupancyRateYear = 100 * averageRoomsYear / totalRooms;

		return [averageRoomsMonthArray, averageRoomPriceMonthArray, averageDailyRevenueMonthArray, totalRevenueMonthArray, totalRoomsMonthArray, dayCountMonthArray, averageRoomsYear, occupancyRateYear, averageRoomPriceYear, totalRevenueYear, totalRooms, dayCountYear];
	}
	
	async getOccupancyForDashboard(itemObj) {
		var dashboardID = itemObj.dashboardID;
		var jsonObj = await this.serverDataConvertCsvToJson(dashboardID);
		var averageRoomsMonthArray, averageRoomPriceMonthArray, averageDailyRevenueMonthArray, totalRevenueMonthArray, totalRoomsMonthArray, dayCountMonthArray, averageRoomsYear, occupancyRateYear, averageRoomPriceYear, totalRevenueYear, totalRooms, dayCountYear;
		[averageRoomsMonthArray, averageRoomPriceMonthArray, averageDailyRevenueMonthArray, totalRevenueMonthArray, totalRoomsMonthArray, dayCountMonthArray, averageRoomsYear, occupancyRateYear, averageRoomPriceYear, totalRevenueYear, totalRooms, dayCountYear] = this.calcAverageMonthYear(jsonObj, dashboardID);
		itemObj.averageRoomsMonthArray = averageRoomsMonthArray;
		itemObj.averageRoomPriceMonthArray = averageRoomPriceMonthArray;
		itemObj.averageDailyRevenueMonthArray = averageDailyRevenueMonthArray;
		itemObj.totalRevenueMonthArray = totalRevenueMonthArray;
		itemObj.totalRoomsMonthArray = totalRoomsMonthArray;
		itemObj.dayCountMonthArray = dayCountMonthArray;
		itemObj.averageRoomsYear = averageRoomsYear;
		itemObj.occupancyRateYear = occupancyRateYear;
		itemObj.averageRoomPriceYear = averageRoomPriceYear;
		itemObj.totalRevenueYear = totalRevenueYear;
		itemObj.totalRooms = totalRooms;
		itemObj.dayCountYear = dayCountYear;
	}

	async serverGetDashboardValues() {

		var email = this.props.email;
		var dashboardAppUserType = this.props.dashboardAppUserType;
		var dashboardListJsonObj = this.props.dashboardListJsonObj;
		
		var dashboardListJsonObj2 = [];
		var i;
		var itemObj;
		var dashboardID;
		var dashboardUserType;
		
		for(i=0; i<dashboardListJsonObj.length; i++) {
			itemObj = dashboardListJsonObj[i];
			if (dashboardAppUserType >= 100) {
				await this.getOccupancyForDashboard(itemObj);
				dashboardListJsonObj2.push(itemObj);
			} else {
				dashboardID = itemObj.dashboardID;
				await this.getOccupancyForDashboard(itemObj);
				dashboardListJsonObj2.push(itemObj);
			}
		}
		
		this.setState({
			dashboardListJsonObj: dashboardListJsonObj2,
		});
	}
	
	handleSelectDashboard(dashboardID) {
		this.props.setSelectedDashboard(dashboardID);
		const navigate = this.props.navigate;
  		navigate("/monthly");
	}

	renderOneBoxDay(itemObj) {

		var year = itemObj.year;
		var month = itemObj.month;
		var day = itemObj.day;
		var key = year + "-" + month + "-" + day;
		var rooms = itemObj.value1;
		var roomPrice = itemObj.value2;
		var roomRevenue = rooms * roomPrice;
		var dayOfWeek = this.props.getDayOfWeek(year, month, day);
		var weekdayString = this.props.getWeekdayStringShort(dayOfWeek);
		weekdayString = weekdayString.toUpperCase();
		
		return (
			<div key={key} className="RecentBoxDayContainer">
				<div className="RecentBoxDayLeft">{weekdayString} {day}.{month}.</div>
				<div className="RecentBoxDayRight">{rooms} x {this.props.getEURString(roomPrice, 0)} = <b>{this.props.getEURString(roomRevenue, 0)}</b></div>
			</div>
		);
	}

	renderYearSummaryOneMonth(itemObj, month1) {

		var averageRoomsMonthArray = itemObj.averageRoomsMonthArray;
		var averageRoomPriceMonthArray = itemObj.averageRoomPriceMonthArray;
		var totalRevenueMonthArray = itemObj.totalRevenueMonthArray;

		var totalRooms = itemObj.totalRooms;
		var averageRoomsMonth = averageRoomsMonthArray[month1-1];
		var roomPrice = averageRoomPriceMonthArray[month1-1];
		var revenue = totalRevenueMonthArray[month1-1];
		var occupancyRate = Math.round(100 * averageRoomsMonth / totalRooms);
		var monthString = this.props.getMonthString(month1);
		var roomPriceString = Math.round(roomPrice);
		var revenueString = this.props.getEURString(revenue, 0);
		
		if (revenue === 0) {
			return null;
		}

		return (
			<div key={month1} className="DailyOccupancyBoxLineContainer">
				<div className="DailyOccupancyBoxLeft">{monthString}</div>
				<div className="DailyOccupancyBoxRight">{Math.round(averageRoomsMonth)} x {roomPriceString} => <b>{revenueString}</b> - {occupancyRate}%</div>
			</div>
		)
	}
	
	renderYearSummary(itemObj) {

		var i;
		var jsxCode;
		var jsxArray = [];
		var dashboardName = itemObj.dashboardName;
		var averageRoomsYear = itemObj.averageRoomsYear;
		var occupancyRateYear = itemObj.occupancyRateYear;
		var averageRoomPriceYear = itemObj.averageRoomPriceYear;
		var totalRevenueYear = itemObj.totalRevenueYear;
		var totalRevenueYearString = this.props.getEURString(totalRevenueYear, 0);
		var totalRooms = itemObj.totalRooms;
		var dayCountYear = itemObj.dayCountYear;
		var trevparDay = totalRevenueYear / totalRooms / dayCountYear;
		var trevparYear = totalRevenueYear / totalRooms;
		
		var trevparString = this.props.getEURString(trevparDay, 0) + " (" + this.props.getEURString(trevparYear, 0) + ")";
		
		for(i=1; i<=12; i++) {
			jsxCode = this.renderYearSummaryOneMonth(itemObj, i);
			jsxArray.push(jsxCode);
		}

		return (
			<div className="DailyOccupancyOneBox">
				<div className="DailyOccupancyBoxTitle">{dashboardName}</div>
				{jsxArray}
				<div className="DailyOccupancyBoxLineContainer">
					<div className="DailyOccupancyBoxLeft"><b>{this.props.getLang(RecentLang, "average")}:</b></div>
					<div className="DailyOccupancyBoxRight">{Math.round(averageRoomsYear)} x {Math.round(averageRoomPriceYear)} - {Math.round(occupancyRateYear)}%</div>
				</div>
				<div className="DailyOccupancyBoxLineContainer">
					<div className="DailyOccupancyBoxLeft"><b>RRevPAR:</b></div>
					<div className="DailyOccupancyBoxRight">{trevparString}</div>
				</div>
				<div className="DailyOccupancyBoxLineContainer">
					<div className="DailyOccupancyBoxLeft"><b>{this.props.getLang(RecentLang, "total")}:</b></div>
					<div className="DailyOccupancyBoxRight">{totalRevenueYearString}</div>
				</div>				
			</div>
		);
	}

	renderAllDashboards() {

		var dashboardListJsonObj = this.state.dashboardListJsonObj;
		if (dashboardListJsonObj === null) {
			return (<div>Loading data...</div>);
		}

		var length = dashboardListJsonObj.length;
		var i;
		var itemObj;
		var jsxCode;
		var jsxArray = [];
		
		for(i=0; i<length; i++) {
			itemObj = dashboardListJsonObj[i];
			jsxCode = this.renderYearSummary(itemObj);
			jsxArray.push(jsxCode);
		}
		
		return (
			<div className="RecentBoxContainer">
				{jsxArray}
			</div>
		);
	}

	render() {
		return (
			<div className="SelectDashboardTopContainer">
				<div style={{height: "20px"}}></div>
				{this.renderAllDashboards()}
			</div>
		);
	}
}

export default function RecentOccupancyFunction(props) {
	const navigate = useNavigate();
	return <RecentOccupancy {...props} navigate={navigate} />;
}
