import React  from 'react';
import './App.css';
import './YearMonthPopupMenu.css';

class YearMonthPopupMenu extends React.Component {

	constructor(props) {

		super(props);
		this.ref = React.createRef();

		this.handleClickOutside = this.handleClickOutside.bind(this);
		this.handleYearClick = this.handleYearClick.bind(this);
		this.handleMonthClick = this.handleMonthClick.bind(this);
		this.renderOneYear = this.renderOneYear.bind(this);
		this.renderAllYears = this.renderAllYears.bind(this);
		this.renderOneMonth = this.renderOneMonth.bind(this);
		this.renderAllMonths = this.renderAllMonths.bind(this);
	}

	componentDidMount() {
		document.addEventListener('click', this.handleClickOutside.bind(this), true);
	}

	componentWillUnmount() {
		document.removeEventListener('click', this.handleClickOutside.bind(this), true);
	};
	
	handleClickOutside(event) {
		if (this.ref.current && !this.ref.current.contains(event.target)) {
			this.props.closePopupMenu();
		}
	};

	handleYearClick(year) {
		this.props.closePopupMenu();
		this.props.setSelectedYear(year);
		//this.props.calcDaysToDisplay(year, this.props.selectedMonth, this.props.locationID);
	}

	handleMonthClick(month) {
		this.props.closePopupMenu();
		this.props.setSelectedMonth(month);
		//this.props.calcDaysToDisplay(this.props.selectedYear, month, this.props.locationID);
	}

	renderOneYear(year) {
		return (
			<div 
				key={year} 
				className="YearMonthPopupMenuOneYear"
				onClick={() => this.handleYearClick(year)}
			>
				{year}
			</div>
		);
	}

	renderAllYears() {
		var i;
		var rows = [];
		var year = this.props.selectedYear - 4;
		for (i=0; i<9; i++) {
			rows.push(this.renderOneYear(year));
			year = year + 1;
		}
		return (
			<div className="YearMonthPopupMenuYears">{rows}</div>
		);
	}

	renderOneMonth(month) {

		var monthString = "";

		monthString = this.props.getMonthString(month+1);

		return (
			<div 
				key={month} 
				className="YearMonthPopupMenuOneMonth"
				onClick={() => this.handleMonthClick(month+1)}
			>
				{monthString}
			</div>
		);		
	}

	renderAllMonths() {

		var rows = [];
		var fullYearYN = this.props.fullYearYN;
		if (fullYearYN) {
			rows.push(this.renderOneMonth(-1));
		}		
		
		var i;
		for (i=0; i<12; i++) {
			rows.push(this.renderOneMonth(i));
		}
		return (
			<div className="YearMonthPopupMenuMonths">{rows}</div>
		);
	}

	render() {
		
		return (
			<div ref={this.ref} className="YearMonthPopupMenuContainer1">
				<div className="YearMonthPopupMenuContainer">
					{this.renderAllYears()}
					{this.renderAllMonths()}
				</div>
			</div>
		);
	}
}

export default YearMonthPopupMenu;