
export class CalcHelpers {

	constructor() {
		this.baseURLAPI = "";
	}

	getTotalRooms(dashboardID) {

		if (dashboardID === 1) {
			return 158;
		}
		
		if (dashboardID === 2) {
			return 34;
		}
		
		if (dashboardID === 3) {
			return 12;
		}
		
		if (dashboardID === 4) {
			return 13;
		}
		
		if (dashboardID === 5) {
			return 2;
		}
		
		return 0;
	}
	
	getDashboardVariableIDFromVariableName(dashboardVariableListJsonObj, variableName1) {

		if (dashboardVariableListJsonObj === null) {
			return 0;
		}

		var i;
		var itemObj;
		var variableName2;
		var dashboardVariableID;
		
		for(i=0; i<dashboardVariableListJsonObj.length; i++) {
			itemObj = dashboardVariableListJsonObj[i];
			variableName2 = itemObj.variableName;
			dashboardVariableID = itemObj.dashboardVariableID;
			if (variableName1 === variableName2) {
				return dashboardVariableID;
			}
		}
		
		return 0;
	}
	
	getDashboardVariableNameFromVariableID(dashboardVariableListJsonObj, dashboardVariableID1) {
		
		if (dashboardVariableListJsonObj === null) {
			return "";
		}

		var i;
		var itemObj;
		var variableName = "";
		var dashboardVariableID2;
		
		dashboardVariableID1 = parseInt(dashboardVariableID1);

		for(i=0; i<dashboardVariableListJsonObj.length; i++) {
			itemObj = dashboardVariableListJsonObj[i];
			dashboardVariableID2 = itemObj.dashboardVariableID;
			dashboardVariableID2 = parseInt(dashboardVariableID2);
			variableName = itemObj.variableName;
			if (dashboardVariableID1 === dashboardVariableID2) {
				return variableName;
			}
		}
		
		return "";
	}
	
	async serverGetDashboardValueYear(dashboardVariableID, year) {

		var baseURLAPI = this.baseURLAPI;
		var url = baseURLAPI + "/getdashboardvalueyear?dashboardvariableid=" + dashboardVariableID;
		url = url + "&year=" + year;
		
		const res = await fetch(url);
		var responseText = await res.text();

		var jsonObj = null;
		if (responseText !== "") {
			jsonObj = JSON.parse(responseText);
		}
		
		return jsonObj;
	}
	
	async serverGetDashboardValueMonth(dashboardVariableID, year, month) {

		var baseURLAPI = this.baseURLAPI;
		var url = baseURLAPI + "/getdashboardvaluemonth?dashboardvariableid=" + dashboardVariableID;
		url = url + "&year=" + year;
		url = url + "&month=" + month;
		
		const res = await fetch(url);
		var responseText = await res.text();

		var jsonObj = null;
		if (responseText !== "") {
			jsonObj = JSON.parse(responseText);
		}
		
		return jsonObj;
	}
	
	async calcRoomRevenueValueYear(dashboardVariableListJsonObj, year) {

		var i;
		var j;
		var roomsMonthArray = [];
		var roomPriceMonthArray = [];
		var dayArray;
		
		for(i=0; i<13; i++) {

			dayArray = [];
			for(j=0; j<32; j++) {
				dayArray.push(0);
			}
			roomsMonthArray.push(dayArray);

			dayArray = [];
			for(j=0; j<32; j++) {
				dayArray.push(0);
			}
			roomPriceMonthArray.push(dayArray);
		}

		var dashboardVariableID;
		var jsonObj;
		var itemObj;
		var day;
		var month;
		var value;
		var array;
		var array1;
		var array2;
		var rooms;
		var roomPrice;
		var roomRevenue;
		
		// get rooms

		dashboardVariableID = this.getDashboardVariableIDFromVariableName(dashboardVariableListJsonObj, "rooms");
		jsonObj = await this.serverGetDashboardValueYear(dashboardVariableID, year);
		
		for(i=0; i<jsonObj.length; i++) {
			itemObj = jsonObj[i];
			day = itemObj.day;
			month = itemObj.month;
			value = itemObj.valueInt;
			array = roomsMonthArray[month];
			array[day] = value;
		}
		
		// get room price

		dashboardVariableID = this.getDashboardVariableIDFromVariableName(dashboardVariableListJsonObj, "roomprice");
		jsonObj = await this.serverGetDashboardValueYear(dashboardVariableID, year);
		
		for(i=0; i<jsonObj.length; i++) {
			itemObj = jsonObj[i];
			day = itemObj.day;
			month = itemObj.month;
			value = itemObj.valueFloat;
			array = roomPriceMonthArray[month];
			array[day] = value;
		}
		
		// calc room revenue

		jsonObj = [];

		for(i=1; i<=12; i++) {
			array1 = roomsMonthArray[i];
			array2 = roomPriceMonthArray[i];
			for(j=1; j<=31; j++) {
				rooms = array1[j];
				roomPrice = array2[j];
				roomRevenue = Math.round(rooms * roomPrice);
				if (roomRevenue > 0) {
					itemObj = {};
					itemObj.dashboardVariableID = 0;
					itemObj.year = year;
					itemObj.month = i;
					itemObj.day = j;
					itemObj.valueInt = roomRevenue;
					itemObj.valueFloat = 0;
					jsonObj.push(itemObj);
				}
			}
		}
		
		return jsonObj;
	}
	
	isVariableCurrencyYN(variableName) {

		var currencyYN = false;

		if (variableName === "totalrevenue") {
			currencyYN = true;
		}
		if (variableName === "roomrevenue") {
			currencyYN = true;
		}
		if (variableName === "roomprice") {
			currencyYN = true;
		}
		if (variableName === "restaurantrevenue") {
			currencyYN = true;
		}
		if (variableName === "transferrevenue") {
			currencyYN = true;
		}
		if (variableName === "parkingrevenue") {
			currencyYN = true;
		}
		if (variableName === "minibarrevenue") {
			currencyYN = true;
		}
		if (variableName === "wellnessrevenue") {
			currencyYN = true;
		}
		if (variableName === "touristtaxrevenue") {
			currencyYN = true;
		}
		if (variableName === "otherrevenue") {
			currencyYN = true;
		}

		if (variableName === "totalexpenses") {
			currencyYN = true;
		}
		if (variableName === "ebitda") {
			currencyYN = true;
		}
		if (variableName === "totalsalary") {
			currencyYN = true;
		}
		if (variableName === "salary") {
			currencyYN = true;
		}
		if (variableName === "pdv") {
			currencyYN = true;
		}
		if (variableName === "receptionsalary") {
			currencyYN = true;
		}
		if (variableName === "housekeepingsalary") {
			currencyYN = true;
		}
		if (variableName === "laundrysalary") {
			currencyYN = true;
		}
		if (variableName === "housemansalary") {
			currencyYN = true;
		}
		if (variableName === "salessalary") {
			currencyYN = true;
		}
		if (variableName === "adminsalary") {
			currencyYN = true;
		}
		if (variableName === "hotelexpense") {
			currencyYN = true;
		}
		if (variableName === "housekeepinglaundryexpense") {
			currencyYN = true;
		}
		if (variableName === "miceexpense") {
			currencyYN = true;
		}
		if (variableName === "housemanexpense") {
			currencyYN = true;
		}
		if (variableName === "kitchensalary") {
			currencyYN = true;
		}
		if (variableName === "barsalary") {
			currencyYN = true;
		}
		if (variableName === "kitchenexpense") {
			currencyYN = true;
		}
		if (variableName === "barexpense") {
			currencyYN = true;
		}
		if (variableName === "transfersalary") {
			currencyYN = true;
		}
		if (variableName === "transferexpense") {
			currencyYN = true;
		}
		if (variableName === "parkingexpense") {
			currencyYN = true;
		}
		if (variableName === "minibarexpense") {
			currencyYN = true;
		}
		if (variableName === "wellnesssalary") {
			currencyYN = true;
		}
		if (variableName === "wellnessexpense") {
			currencyYN = true;
		}
		if (variableName === "electricity") {
			currencyYN = true;
		}
		if (variableName === "water") {
			currencyYN = true;
		}
		if (variableName === "bookingfees") {
			currencyYN = true;
		}
		if (variableName === "expediafees") {
			currencyYN = true;
		}
		if (variableName === "siteminderfees") {
			currencyYN = true;
		}
		if (variableName === "otherexpenses") {
			currencyYN = true;
		}
		if (variableName === "laundryexpenses") {
			currencyYN = true;
		}

		if (variableName === "cpor") {
			currencyYN = true;
		}
		if (variableName === "goppar") {
			currencyYN = true;
		}
		if (variableName === "trevpar") {
			currencyYN = true;
		}

		return currencyYN;
	}
	
	isVariableStaffYN(variableName) {

		var staffYN = false;

		if (variableName === "receptionemployees") {
			staffYN = true;
		}
		if (variableName === "housekeepingemployees") {
			staffYN = true;
		}
		if (variableName === "laundryemployees") {
			staffYN = true;
		}
		if (variableName === "housemanemployees") {
			staffYN = true;
		}
		if (variableName === "salesemployees") {
			staffYN = true;
		}
		if (variableName === "adminemployees") {
			staffYN = true;
		}
		if (variableName === "kitchenemployees") {
			staffYN = true;
		}
		if (variableName === "baremployees") {
			staffYN = true;
		}
		if (variableName === "transferemployees") {
			staffYN = true;
		}
		if (variableName === "wellnessemployees") {
			staffYN = true;
		}
		if (variableName === "otheremployees") {
			staffYN = true;
		}
		
		return staffYN;
	}
	
	isVariableSalaryYN(variableName) {

		var salaryYN = false;
		
		if (variableName === "salary") {
			salaryYN = true;
		}
		if (variableName === "receptionsalary") {
			salaryYN = true;
		}
		if (variableName === "housekeepingsalary") {
			salaryYN = true;
		}
		if (variableName === "laundrysalary") {
			salaryYN = true;
		}
		if (variableName === "housemansalary") {
			salaryYN = true;
		}
		if (variableName === "salessalary") {
			salaryYN = true;
		}
		if (variableName === "adminsalary") {
			salaryYN = true;
		}
		if (variableName === "kitchensalary") {
			salaryYN = true;
		}
		if (variableName === "barsalary") {
			salaryYN = true;
		}
		if (variableName === "transfersalary") {
			salaryYN = true;
		}
		if (variableName === "wellnesssalary") {
			salaryYN = true;
		}

		return salaryYN;
	}

	getStaffVariableFromSalaryYN(salaryVariableName) {

		var staffVariableName = "";
		
		if (salaryVariableName === "totalsalary") {
			staffVariableName = "totalstaff";
		}
		if (salaryVariableName === "receptionsalary") {
			staffVariableName = "receptionemployees";
		}
		if (salaryVariableName === "housekeepingsalary") {
			staffVariableName = "housekeepingemployees";
		}
		if (salaryVariableName === "laundrysalary") {
			staffVariableName = "laundryemployees";
		}
		if (salaryVariableName === "housemansalary") {
			staffVariableName = "housemanemployees";
		}
		if (salaryVariableName === "salessalary") {
			staffVariableName = "salesemployees";
		}
		if (salaryVariableName === "adminsalary") {
			staffVariableName = "adminemployees";
		}
		if (salaryVariableName === "kitchensalary") {
			staffVariableName = "kitchenemployees";
		}
		if (salaryVariableName === "barsalary") {
			staffVariableName = "baremployees";
		}
		if (salaryVariableName === "transfersalary") {
			staffVariableName = "transferemployees";
		}
		if (salaryVariableName === "wellnesssalary") {
			staffVariableName = "wellnessemployees";
		}

		return staffVariableName;
	}

	async serverGetDashboardMonthTotalMultiVariables(dashboardVariableIDArray, year, month) {

		var i;
		var id;
		var dashboardVariableID;
		var baseURLAPI = this.baseURLAPI;
		var count = dashboardVariableIDArray.length;

		var url = baseURLAPI + "/getdashboardmonthtotalmultivariables?count=" + count;
		url = url + "&year=" + year;
		url = url + "&month=" + month;

		for(i=0; i<count; i++) {
			dashboardVariableID = dashboardVariableIDArray[i];
			id = "id" + i;
			url = url + "&" + id + "=" + dashboardVariableID;
		}
		
		const res = await fetch(url);
		var jsonObj = await res.json();

		return jsonObj;
	}

	async serverGetDashboardYearTotalMultiVariables(dashboardVariableIDArray, year) {

		var i;
		var id;
		var dashboardVariableID;
		var baseURLAPI = this.baseURLAPI;
		var count = dashboardVariableIDArray.length;

		var url = baseURLAPI + "/getdashboardyeartotalmultivariables?count=" + count;
		url = url + "&year=" + year;

		for(i=0; i<count; i++) {
			dashboardVariableID = dashboardVariableIDArray[i];
			id = "id" + i;
			url = url + "&" + id + "=" + dashboardVariableID;
		}
		
		const res = await fetch(url);
		var jsonObj = await res.json();

		return jsonObj;
	}

	getDaysInMonth(year, month) {
		return new Date(year, month, 0).getDate();
	}
	
	getDaysInYear(year) {

		var i;
		var month;
		var daysInMonth;
		var daysInYear = 0;

		for(i=0; i<12; i++) {
			month = i+1;
			daysInMonth = this.getDaysInMonth(year, month);
			daysInYear += daysInMonth;
		}
		
		return daysInYear;
	}

	getMonthValue(currentYear, currentMonth, year, month1, variableName, jsonObj) {
		
		if (jsonObj === null) {
			return 0;
		}
		
		var i;
		var itemObj;
		var month2;
		var valueInt;
		var valueFloat;
		var value;
		var sum = 0;
		var count = 0;
		
		for(i=0; i<jsonObj.length; i++) {
			itemObj = jsonObj[i];
			month2 = itemObj.month;
			if (month1 === month2) {
				valueInt = itemObj.valueInt;
				valueFloat = itemObj.valueFloat;
				value = valueInt;
				if (valueInt === 0) {
					value = valueFloat;
				}
				sum += value;
				if (value > 0) {
					count ++;
				}
			}
		}

		var daysInMonth = this.getDaysInMonth(year, month1);
		
		var monthValue = Math.round(sum);

		if (variableName === "roomprice") {
			if ((year === currentYear) && (month1 === currentMonth)) {
				monthValue = 0;
				if (count > 0) {
					monthValue = Math.round(sum / count);
				}
			} else {
				monthValue = Math.round(sum / daysInMonth);
			}
		}

		return monthValue;
	}

	getYearValue(year, variableName, monthArray) {
		
		if (monthArray === null) {
			return 0;
		}
		
		var i;
		var value;
		var sum = 0;
		var count = 0;
		
		for(i=0; i<monthArray.length; i++) {
			value = monthArray[i];
			sum += value;
			if (value > 0) {
				count = count + 1;
			}
		}

		//var daysInYear = this.getDaysInYear(year);
		
		var yearValue = Math.round(sum);
		if (variableName === "roomprice") {
			if (count > 0) {
				yearValue = Math.round(sum / count);
			}
		}

		return yearValue;
	}

	async calcTotalRevenueValueYear(currentYear, currentMonth, dashboardVariableListJsonObj, year) {
		
		var month;
		var monthValue;
		var monthRoomRevenueArray = [];

		var jsonObj = await this.calcRoomRevenueValueYear(dashboardVariableListJsonObj, year);
		for(month=0; month<12; month++) {
			monthValue = this.getMonthValue(currentYear, currentMonth, year, month+1, "roomrevenue", jsonObj);
			monthRoomRevenueArray.push(monthValue);
		}

		var i;
		var itemObj;
		var dashboardVariableID;
		var variableName;
		var currencyYN;
		var dashboardVariableIDArray = [];

		for(i=0; i<dashboardVariableListJsonObj.length; i++) {
			itemObj = dashboardVariableListJsonObj[i];
			variableName = itemObj.variableName;
			dashboardVariableID = itemObj.dashboardVariableID;
			currencyYN = this.isVariableCurrencyYN(variableName);
			if (variableName === "roomprice") {
				currencyYN = false;
			}
			if (currencyYN) {
				dashboardVariableIDArray.push(dashboardVariableID);
			}
		}

		var month;
		var jsonObj;
		var monthTotal;
		var sum;
		var monthArray = [];		
		
		for(month=0; month<12; month++) {
			sum = monthRoomRevenueArray[month];
			jsonObj = await this.serverGetDashboardMonthTotalMultiVariables(dashboardVariableIDArray, year, month+1);
			for(i=0; i<jsonObj.length; i++) {
				itemObj = jsonObj[i];
				monthTotal = itemObj.monthTotal;
				sum = sum + monthTotal;
			}
			monthArray.push(sum);
		}

		return monthArray;
	}
	
	async calcTotalExpensesValueYear(currentYear, currentMonth, dashboardVariableListMonthlyJsonObj, year) {
		
		var i;
		var itemObj;
		var dashboardVariableID;
		var variableName;
		var currencyYN;
		var dashboardVariableIDArray = [];

		for(i=0; i<dashboardVariableListMonthlyJsonObj.length; i++) {
			itemObj = dashboardVariableListMonthlyJsonObj[i];
			variableName = itemObj.variableName;
			dashboardVariableID = itemObj.dashboardVariableID;
			currencyYN = this.isVariableCurrencyYN(variableName);
			if (variableName === "roomprice") {
				currencyYN = false;
			}
			if (currencyYN) {
				dashboardVariableIDArray.push(dashboardVariableID);
			}
		}
		
		var monthArray = [];		
		for(month=0; month<12; month++) {
			monthArray.push(0);
		}

		var j;
		var jsonObj;
		var month;
		var monthTotal;
		var valueInt;
		var valueFloat;
		var value;
		
		for(i=0; i<dashboardVariableIDArray.length; i++) {
			dashboardVariableID = dashboardVariableIDArray[i];
			jsonObj = await this.serverGetDashboardValueYear(dashboardVariableID, year);
			if (jsonObj !== null) {
				for(j=0; j<jsonObj.length; j++) {
					itemObj = jsonObj[j];
					month = itemObj.month;
					valueInt = itemObj.valueInt;
					valueFloat = itemObj.valueFloat;
					value = 0;
					if (valueInt > 0) {
						value = valueInt;
					}
					if (valueFloat > 0) {
						value = valueFloat;
					}
					monthArray[month-1] += value;
				}
			}
		}

		return monthArray;
	}
	
	async calcTotalStaffValueYear(currentYear, currentMonth, dashboardVariableListMonthlyJsonObj, year) {
		
		var i;
		var itemObj;
		var dashboardVariableID;
		var variableName;
		var staffYN;
		var dashboardVariableIDArray = [];

		for(i=0; i<dashboardVariableListMonthlyJsonObj.length; i++) {
			itemObj = dashboardVariableListMonthlyJsonObj[i];
			variableName = itemObj.variableName;
			dashboardVariableID = itemObj.dashboardVariableID;
			staffYN = this.isVariableStaffYN(variableName);
			if (staffYN) {
				dashboardVariableIDArray.push(dashboardVariableID);
			}
		}
		
		var monthArray = [];		
		for(month=0; month<12; month++) {
			monthArray.push(0);
		}

		var j;
		var jsonObj;
		var month;
		var monthTotal;
		var valueInt;
		var valueFloat;
		var value;
		
		for(i=0; i<dashboardVariableIDArray.length; i++) {
			dashboardVariableID = dashboardVariableIDArray[i];
			jsonObj = await this.serverGetDashboardValueYear(dashboardVariableID, year);
			if (jsonObj !== null) {
				for(j=0; j<jsonObj.length; j++) {
					itemObj = jsonObj[j];
					month = itemObj.month;
					valueInt = itemObj.valueInt;
					valueFloat = itemObj.valueFloat;
					value = 0;
					if (valueInt > 0) {
						value = valueInt;
					}
					if (valueFloat > 0) {
						value = valueFloat;
					}
					monthArray[month-1] += value;
				}
			}
		}

		return monthArray;
	}
	
	calcTotalRevenueGetValues(dashboardVariableListJsonObj, daysInMonth, variableName1) {

		var i;
		var data1 = [];
		
		for(i=0; i<daysInMonth; i++) {
			data1.push(0);
		}

		var itemObj;
		var variableName2;
		var variableType = "";
		var jsonObj = null;

		for(i=0; i<dashboardVariableListJsonObj.length; i++) {
			itemObj = dashboardVariableListJsonObj[i];
			variableName2 = itemObj.variableName;
			if (variableName1 === variableName2) {
				variableType = itemObj.variableType;
				jsonObj = itemObj.values;
			}
		}
		
		if (jsonObj === null) {
			return null;
		}

		var day;
		var valueInt;
		var valueFloat;
		
		for(i=0; i<jsonObj.length; i++) {
			itemObj = jsonObj[i];
			day = itemObj.day;
			valueInt = itemObj.valueInt;
			valueFloat = itemObj.valueFloat;
			if (variableType === "int") {
				data1[day-1] = valueInt;
			}
			if (variableType === "float") {
				data1[day-1] = valueFloat;
			}
		}
		
		return data1;
	}

	calcRoomRevenue(dashboardVariableListJsonObj, daysInMonth) {

		if (dashboardVariableListJsonObj === null) {
			return 0;
		}

		var i;
		var itemObj = {};
		var values = [];
		var roomRevenueTotal = 0;

		var dataRooms = this.calcTotalRevenueGetValues(dashboardVariableListJsonObj, daysInMonth, "rooms");
		var dataRoomPrice = this.calcTotalRevenueGetValues(dashboardVariableListJsonObj, daysInMonth, "roomprice");
		if ((dataRooms !== null) && (dataRoomPrice !== null)) {
			for(i=0; i<dataRooms.length; i++) {
				var rooms = dataRooms[i];
				var roomPrice = dataRoomPrice[i];
				var roomRevenue = rooms * roomPrice;
				roomRevenueTotal = roomRevenueTotal + roomRevenue;
			}
		}
		
		return roomRevenueTotal;
	}
	
	calcTotalRevenue(dashboardVariableListJsonObj, daysInMonth) {
		
		if (dashboardVariableListJsonObj === null) {
			return 0;
		}

		var i;
		var itemObj = {};
		var values = [];
		var variableName;
		var currencyYN;
		var data;
		var dataArray = [];
		
		for(i=0; i<dashboardVariableListJsonObj.length; i++) {
			itemObj = dashboardVariableListJsonObj[i];
			variableName = itemObj.variableName;
			currencyYN = this.isVariableCurrencyYN(variableName);
			if (variableName === "roomprice") {
				currencyYN = false;
			}
			if (currencyYN) {
				data = this.calcTotalRevenueGetValues(dashboardVariableListJsonObj, daysInMonth, variableName);
				dataArray.push(data);
			}
		}
		
		var j;
		var oneValue;
		var sum;
		var totalRevenue = 0;

		for(i=0; i<daysInMonth; i++) {
			sum = 0;
			for(j=0; j<dataArray.length; j++) {
				data = dataArray[j];
				if (data !== null) {
					oneValue = data[i];
					sum = sum + oneValue;
				}
			}

			totalRevenue = totalRevenue + sum;
		}

		var roomRevenue = this.calcRoomRevenue(dashboardVariableListJsonObj, daysInMonth);
		totalRevenue = totalRevenue + roomRevenue;

		return totalRevenue;
	}
	
	
}
