import React  from 'react';
import './App.css';
import './YearlyRevenue.css';
import DashboardLang from './DashboardLang.json';
import ReactTooltip from 'react-tooltip';
import YearlyRevenueToolbar from './YearlyRevenueToolbar.js';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import YearlyRevenueCustomTooltip from './YearlyRevenueCustomTooltip.js';

class YearlyRevenue extends React.Component {

	constructor(props) {

		super(props);
		
		this.state = {
			innerWidth: 0,
			innerHeight: 0,
			tab: "roomrevenue",
			currentYear: 0,
			currentMonth: 0,
			yearNumberArray: null,
			yearSelectedArray: null,
			yearValuesArray: null,
			showPopupMenu1YN: false,
			data: null,
			dashboardVariableListJsonObj: null,
			colorArray: ["#82ca9d", "#30ff30", "orange", "blue", "green", "red", "grey", "#ca829d", "#82ca9d", "#8884d8"],
		};
		
		this.updateDimensions = this.updateDimensions.bind(this);
		this.serverGetDashboardVariableList = this.serverGetDashboardVariableList.bind(this);		
		this.storeCurrentYear = this.storeCurrentYear.bind(this);		
		this.calcMonthsToDisplay = this.calcMonthsToDisplay.bind(this);
		this.setTab = this.setTab.bind(this);
		this.onClickYear = this.onClickYear.bind(this);
		this.renderHeaderOneYear = this.renderHeaderOneYear.bind(this);
		this.renderHeaderAllYears = this.renderHeaderAllYears.bind(this);
		this.renderOneBarTag = this.renderOneBarTag.bind(this);
		this.renderBarChart = this.renderBarChart.bind(this);		
		this.renderFooter = this.renderFooter.bind(this);
	}

	componentDidMount() {

		this.updateDimensions();
		
		var dashboardID = this.props.dashboardID;
		if (dashboardID > 0) {
			this.serverGetDashboardVariableList(dashboardID);
		}

		window.addEventListener('resize', this.updateDimensions);
	}
	
	componentWillUnmount() {
		document.removeEventListener('resize', this.updateDimensions, true);
	};
	
	updateDimensions = () => {
		this.setState({ innerWidth: window.innerWidth, innerHeight: window.innerHeight });
	};
	
	async serverGetDashboardVariableList(dashboardID) {

		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/getdashboardvariablelist?dashboardid=" + dashboardID + "&variablefrequency=daily";
		
		const res = await fetch(url);
		const dashboardVariableListJsonObj = await res.json();
		
		var i;
		var itemObj;
		
		for(i=0; i<dashboardVariableListJsonObj.length; i++) {
			itemObj = dashboardVariableListJsonObj[i];
			itemObj.values = null;
		}

		this.setState({
			dashboardVariableListJsonObj: dashboardVariableListJsonObj,
		});

		this.storeCurrentYear(dashboardVariableListJsonObj);
	}
	
	storeCurrentYear(dashboardVariableListJsonObj) {

		var currentYear;
		var currentMonth;

		var dateObj = new Date();
		currentYear = dateObj.getFullYear();		
		currentMonth = dateObj.getMonth() + 1;
		
		var i;
		var year;
		var yearNumberArray = [];
		var yearSelectedArray = [];
		var yearValuesArray = [];

		for(i=0; i<10; i++) {
			year = currentYear - 9 + i;
			yearNumberArray.push(year);
			yearValuesArray.push(null);
			if (i>7) {
				yearSelectedArray.push(true);
			} else {
				yearSelectedArray.push(false);
			}
		}
		
		this.setState({
			currentYear: currentYear,
			currentMonth: currentMonth,
			yearNumberArray: yearNumberArray,
			yearSelectedArray: yearSelectedArray,
			yearValuesArray: yearValuesArray,
		});

		this.calcMonthsToDisplay(dashboardVariableListJsonObj, this.state.tab, yearNumberArray, yearSelectedArray, yearValuesArray);
	}
	
	async calcMonthsToDisplay(dashboardVariableListJsonObj, variableName, yearNumberArray, yearSelectedArray, yearValuesArray) {

		var calcHelpersObj = this.props.calcHelpersObj;
		var dashboardVariableID = calcHelpersObj.getDashboardVariableIDFromVariableName(dashboardVariableListJsonObj, variableName);
		var i;
		var year;
		var month;
		var selectedYN;
		var jsonObj;
		var monthArray;
		var monthValue;
		var currentYear = this.state.currentYear;
		var currentMonth = this.state.currentMonth;
		
		if (dashboardVariableID > 0) {
			for(i=0; i<yearSelectedArray.length; i++) {
				selectedYN = yearSelectedArray[i];
				year = yearNumberArray[i];
				monthArray = [];
				if (selectedYN) {
					jsonObj = await calcHelpersObj.serverGetDashboardValueYear(dashboardVariableID, year);
					for(month=0; month<12; month++) {
						monthValue = calcHelpersObj.getMonthValue(currentYear, currentMonth, year, month+1, variableName, jsonObj);
						monthArray.push(monthValue);
					}
					yearValuesArray[i] = monthArray;
				}
			}
		}
		
		if (variableName === "roomrevenue") {
			for(i=0; i<yearSelectedArray.length; i++) {
				selectedYN = yearSelectedArray[i];
				year = yearNumberArray[i];
				monthArray = [];
				if (selectedYN) {
					jsonObj = await calcHelpersObj.calcRoomRevenueValueYear(dashboardVariableListJsonObj, year);
					for(month=0; month<12; month++) {
						monthValue = calcHelpersObj.getMonthValue(currentYear, currentMonth, year, month+1, variableName, jsonObj);
						monthArray.push(monthValue);
					}
					yearValuesArray[i] = monthArray;
				}
			}
		}

		if (variableName === "totalrevenue") {
			for(i=0; i<yearSelectedArray.length; i++) {
				selectedYN = yearSelectedArray[i];
				year = yearNumberArray[i];
				if (selectedYN) {
					monthArray = await calcHelpersObj.calcTotalRevenueValueYear(currentYear, currentMonth, dashboardVariableListJsonObj, year);
					yearValuesArray[i] = monthArray;
				}
			}
		}

		this.setState({
			yearValuesArray: yearValuesArray,
		});

		var j;
		var yearString;
		var monthString;
		var itemObj;
		var data = [];

		for(i=0; i<12; i++) {

			itemObj = {};
			monthString = this.props.getMonthStringShort(i+1);
			monthString = monthString.toUpperCase();
			itemObj.name = monthString;

			for(j=0; j<yearNumberArray.length; j++) {
				year = yearNumberArray[j];
				yearString = year.toString();
				selectedYN = yearSelectedArray[j];
				if (selectedYN){
					monthValue = yearValuesArray[j];
					itemObj[yearString] = monthValue[i];
				}
			}
			data.push(itemObj);
		}

		this.setState({
			data: data,
		});
	}
	
	async setTab(tab) {

		this.setState({
			tab: tab,
		});

		this.calcMonthsToDisplay(this.state.dashboardVariableListJsonObj, tab, this.state.yearNumberArray, this.state.yearSelectedArray, this.state.yearValuesArray);
	}
	
	onClickYear(year) {
		
		var i;
		var year2;
		var selected;
		var yearSelectedArray = [];

		for(i=0; i<this.state.yearNumberArray.length; i++) {
			year2 = this.state.yearNumberArray[i];
			selected = this.state.yearSelectedArray[i];
			if (year === year2) {
				selected = !selected;
			}
			yearSelectedArray.push(selected);
		}

		this.setState({
			yearSelectedArray: yearSelectedArray,
		});

		this.calcMonthsToDisplay(this.state.dashboardVariableListJsonObj, this.state.tab, this.state.yearNumberArray, yearSelectedArray, this.state.yearValuesArray);
	}
	
	renderHeaderOneYear(year, selected) {
		
		var className = "";
		
		if (selected) {
			className = "YearlyRevenueTopHeaderOneYearSelected";
		} else {
			className = "YearlyRevenueTopHeaderOneYear";
		}
		
		return (
			<div key={year} className={className} onClick={() => this.onClickYear(year)}>{year}</div>
		);
	}

	renderHeaderAllYears() {
		
		var innerWidth = this.state.innerWidth;
		
		var yearStart = 0;
		if (innerWidth < 1000) {
			yearStart = 5;
		}

		var i;
		var rows = [];
		var year;
		var selected;

		for(i=yearStart; i<this.state.yearNumberArray.length; i++) {
			year = this.state.yearNumberArray[i];
			selected = this.state.yearSelectedArray[i];
			rows.push(this.renderHeaderOneYear(year, selected));
		}

		return (
			<div className="YearlyRevenueTopHeaderContainer">{rows}</div>
		);
	}
	
	renderOneBarTag(yearIndex) {

		var colorArray = this.state.colorArray;
		var color = colorArray[yearIndex];

		var year = this.state.yearNumberArray[yearIndex];
		var selectedYN = this.state.yearSelectedArray[yearIndex];

		if (!selectedYN) {
			return;
		}

		var yearString = year.toString();

		return (<Bar key={yearIndex} dataKey={yearString} fill={color} />);
	}
	
	renderBarChart() {
		
		var calcHelpersObj = this.props.calcHelpersObj;
		var tab = this.state.tab;
		var variableName = tab;
		var yearSelectedArray = this.state.yearSelectedArray;
		var dashboardVariableID = calcHelpersObj.getDashboardVariableIDFromVariableName(this.state.dashboardVariableListJsonObj, variableName);
		var i;
		var jsxCode;
		var jsxArray = [];
		
		var variableType = "int";
		var currencyYN = calcHelpersObj.isVariableCurrencyYN(variableName);

		if (dashboardVariableID > 0) {
			for(i=0; i<yearSelectedArray.length; i++) {
				jsxCode = this.renderOneBarTag(i);
				jsxArray.push(jsxCode);
			}
		}
		
		if (variableName === "totalrevenue") {
			for(i=0; i<yearSelectedArray.length; i++) {
				jsxCode = this.renderOneBarTag(i);
				jsxArray.push(jsxCode);
			}
		}
		
		if (variableName === "roomrevenue") {
			for(i=0; i<yearSelectedArray.length; i++) {
				jsxCode = this.renderOneBarTag(i);
				jsxArray.push(jsxCode);
			}
		}
		
		return (
			<ResponsiveContainer width="100%" height={400}>
				<BarChart
					width={500}
					height={400}
					data={this.state.data}
					margin={{
						top: 0,
						right: 20,
						left: 20,
						bottom: 5,
				}}
				>
					<XAxis dataKey="name" />
					<YAxis />
					<Tooltip content={
						<YearlyRevenueCustomTooltip
							variableType={variableType}
							currencyYN={currencyYN}
							getLang={this.props.getLang}
							getEURString={this.props.getEURString}								
						/>}
						cursor={false} 
					/>
					<Legend />
					{jsxArray}
				</BarChart>
			</ResponsiveContainer>
	  );
	}
	
	renderFooter() {

		var calcHelpersObj = this.props.calcHelpersObj;
		var i;
		var rows = [];
		var year;
		var selectedYN;
		var value;
		var valueString;
		var key2;
		var variableName = this.state.tab;
		
		var currencyYN = calcHelpersObj.isVariableCurrencyYN(variableName);

		var decimalPlaces = 0;
		var colorArray = this.state.colorArray;
		var monthArray;

		for(i=0; i<this.state.yearNumberArray.length; i++) {

			year = this.state.yearNumberArray[i];
			key2 = year + "-2";

			selectedYN = this.state.yearSelectedArray[i];

			if (selectedYN) {

				monthArray = this.state.yearValuesArray[i];
				value = 0;
				if (monthArray !== null) {
					value = calcHelpersObj.getYearValue(year, variableName, monthArray);
				}

				valueString = value;
				if (currencyYN) {
					valueString = this.props.getEURString(value, decimalPlaces);
				}

				var color = colorArray[i];
				var style = {color: color};

				rows.push(<div key={year} className="YearlyRevenueFooterItem1" style={style}>{year}</div>);
				rows.push(<div key={key2} className="YearlyRevenueFooterItem2" style={style}>{valueString}</div>);
			}
		}
		
		var yearLabel = this.props.getLang(DashboardLang, "year");

		return (
			<div className="YearlyRevenueFooterContainer">
				<div className="YearlyRevenueFooterDiv">
					<div className="YearlyRevenueFooterTitle1">{yearLabel}</div>
					<div className="YearlyRevenueFooterTitle2">Total</div>
					{rows}
				</div>
			</div>
		);
	}
	
	render() {

		if (this.state.yearNumberArray === null) {
			return;
		}

		return (
			<div className="YearlyRevenueTopContainer">
				<ReactTooltip
					type="info"
					multiline={true}
					delayShow={200}
				/>
				<YearlyRevenueToolbar
					dashboardID={this.props.dashboardID}
					dashboardName={this.props.dashboardName}
					dashboardVariableListJsonObj={this.state.dashboardVariableListJsonObj}
					tab={this.state.tab}
					setTab={this.setTab}
					getLang={this.props.getLang}
				/>
				{this.renderHeaderAllYears()}
				{this.renderBarChart()}
				<div style={{height: "20px"}}></div>
				{this.renderFooter()}
				<div style={{height: "20px"}}></div>
			</div>
		);
	}
}

export default YearlyRevenue;

