import React  from 'react';
import './App.css';
import './Recent.css';
import { useNavigate } from "react-router-dom";
import RecentLang from './RecentLang.json';

import ToolbarPopupMenu from "./ToolbarPopupMenu.js"

class RecentMainToolbar extends React.Component {

	constructor(props) {

		super(props);
				
		this.state = {
			innerWidth: 0,
			innerHeight: 0,
			showPopupMenuYN: false,
		}
		
		this.updateDimensions = this.updateDimensions.bind(this);
		this.handleSelectLink = this.handleSelectLink.bind(this);
		this.handlePopupLink = this.handlePopupLink.bind(this);
		this.closePopupMenu = this.closePopupMenu.bind(this);
		this.renderLink = this.renderLink.bind(this);
		this.renderRight = this.renderRight.bind(this);
	}
	
	componentDidMount() {
		this.updateDimensions();
		window.addEventListener('resize', this.updateDimensions);
	}

	componentWillUnmount() {
		document.removeEventListener('resize', this.updateDimensions, true);
	};
	
	updateDimensions = () => {
		this.setState({ innerWidth: window.innerWidth, innerHeight: window.innerHeight });
	};
	
	handleSelectLink(tab) {
		this.props.setTab(tab);
	}
	
	handlePopupLink() {
		this.setState({showPopupMenuYN: true});
	}

	closePopupMenu() {
		this.setState({showPopupMenuYN: false});
	}
	
	renderLink(linkTitle, linkValue, key, selectedYN) {

		var classNameString = "RecentMainToolbarLink";
					
		if (selectedYN) {
			classNameString = "RecentMainToolbarSelectedLink";
		}
		
		return (
			<div 
				key={key}
				className={classNameString}
				onClick={() => this.handleSelectLink(linkValue)}
			>
				{linkTitle}
			</div>
		);
	}
	
	renderPopupLink(linkTitle, variableNameArray) {

		var classNameString = "RecentMainToolbarSelectedLink";
		
		return (
			<div 
				key="popup-link"
				className={classNameString}
			>
				<div 
					className={classNameString}
					onClick={() => this.handlePopupLink()}
				>
					{linkTitle}
				</div>
				{this.state.showPopupMenuYN && (
					<ToolbarPopupMenu
						variableNameArray={variableNameArray}
						handleSelectLink={this.handleSelectLink}
						getLang={this.props.getLang}
						closePopupMenu={this.closePopupMenu}
					/>
				)}
			</div>
		);
	}
	
	renderRight() {

		var i;
		var variableName;
		var variableString;
		var jsxCode;
		var jsxArray = [];
		var skipVariableYN;
		var key1;
		var key2;
		var variableNameArray = [];
		
		variableNameArray.push("rooms");
		variableNameArray.push("occupancy");
		
		var displayPopupYN = false;
		
		var tab = this.props.tab;
		var selectedYN;
		
		if (displayPopupYN) {

			for(i=0; i<variableNameArray.length; i++) {
				
				variableName = variableNameArray[i];
				variableString = this.props.getLang(RecentLang, variableName);

				selectedYN = false;
				if (tab === variableName) {
					selectedYN = true;
				}

				if (selectedYN) {
					jsxCode = this.renderPopupLink(variableString, variableNameArray);
					jsxArray.push(jsxCode);
				}				
			}
			
			return jsxArray;
		}

		for(i=0; i<variableNameArray.length; i++) {
			
			if (i>0) {
				key1 = "a-" + i;
				jsxCode = (<div key={key1} className="RecentMainToolbarText">|</div>);
				jsxArray.push(jsxCode);
			}

			key2 = "b-" + i;

			variableName = 	variableNameArray[i];
			variableString = this.props.getLang(RecentLang, variableName);

			selectedYN = false;
			if (tab === variableName) {
				selectedYN = true;
			}

			jsxCode = this.renderLink(variableString, variableName, key2, selectedYN);
			jsxArray.push(jsxCode);
		}

		return jsxArray;
	}
	
	render() {
		
		return (
			<div className="RecentMainToolbarContainer1">
				<div className="RecentMainToolbarLeft">
				</div>
				<div className="RecentMainToolbarRight">
					<div className="RecentMainToolbarContainer2">
						{this.renderRight()}
					</div>
				</div>
			</div>
		);
	}
}

export default function RecentMainToolbarFunction(props) {
	const navigate = useNavigate();
	return <RecentMainToolbar {...props} navigate={navigate} />;
}

