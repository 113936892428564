import React  from 'react';
import './App.css';
import './DashboardEdit.css';
import { useNavigate } from "react-router-dom";
import DashboardLang from './DashboardLang.json';

class DashboardEditToolbar extends React.Component {

	constructor(props) {

		super(props);
		
		this.handleSelectLink = this.handleSelectLink.bind(this);
		this.renderLink = this.renderLink.bind(this);
	}
	
	handleSelectLink(page) {
		const navigate = this.props.navigate;
  		navigate(page);
	}
	
	renderLink(linkTitle, linkValue, selectedYN) {

		var classNameString = "DashboardEditToolbarLink";
		if (selectedYN) {
			classNameString = "DashboardEditToolbarSelectedLink";
		}
		
		return (
			<div 
				className={classNameString}
				onClick={() => this.handleSelectLink(linkValue)}
			>
				{linkTitle}
			</div>
		);
	}
	
	render() {
		
		var dashboardName = this.props.dashboardName;
		var	frequency = this.props.frequency;

		var dailyString = this.props.getLang(DashboardLang, "daily");
		var monthlyString = this.props.getLang(DashboardLang, "monthly");
		
		var dailySelectedYN = false;
		var monthlySelectedYN = false;
		if (frequency === "daily") {
			dailySelectedYN = true;
		}
		if (frequency === "monthly") {
			monthlySelectedYN = true;
		}
		
		var dashboardAppUserType = this.props.dashboardAppUserType;
		var dashboardUserType = this.props.dashboardUserType;

		var showExpensesYN = false;
		if (dashboardAppUserType >= 100) {
			showExpensesYN = true;
		}
		if ((dashboardUserType === 2) || (dashboardUserType === 101) || (dashboardUserType === 102)) {
			showExpensesYN = true;
		}

		return (
			<div className="DashboardEditToolbarContainer1">
				<div className="DashboardEditToolbarLeft">
					<div 
						className="DashboardEditToolbarLink"
						onClick={() => this.handleSelectLink("/selectdashboard")}
					>
						{dashboardName}
					</div>
				</div>
				<div className="DashboardEditToolbarRight">
					<div className="DashboardEditToolbarContainer2">
						{this.renderLink(dailyString, "/edit/daily", dailySelectedYN)}
						{showExpensesYN && (<div className="DashboardMainToolbarText">|</div>)}
						{showExpensesYN && this.renderLink(monthlyString, "/edit/monthly", monthlySelectedYN)}
					</div>
				</div>
			</div>
		);
	}
}

export default function DashboardEditToolbarFunction(props) {
	const navigate = useNavigate();
	return <DashboardEditToolbar {...props} navigate={navigate} />;
}

