import React  from 'react';
import './App.css';
import './Dashboard.css';

import { useNavigate } from "react-router-dom";

class SelectDashboard extends React.Component {

	constructor(props) {

		super(props);
		
		this.handleSelectDashboard = this.handleSelectDashboard.bind(this);				
		this.renderOneDashboard = this.renderOneDashboard.bind(this);				
		this.renderAllDashboards = this.renderAllDashboards.bind(this);				
	}
	
	componentDidMount() {
	}
	
	handleSelectDashboard(dashboardID) {
		this.props.setSelectedDashboard(dashboardID);
		const navigate = this.props.navigate;
  		navigate("/monthly");
	}

	renderOneDashboard(itemObj) {
		var dashboardID = itemObj.dashboardID;
		var dashboardName = itemObj.dashboardName;
		return (
			<div key={dashboardID} className="SelectDashboardQuestionText" onClick={() => this.handleSelectDashboard(dashboardID)}>
				{dashboardName}
			</div>
		);
	}

	renderAllDashboards() {

		var dashboardListJsonObj = this.props.dashboardListJsonObj;
		if (dashboardListJsonObj === null) {
			return;
		}

		var length = dashboardListJsonObj.length;
		var i;
		var itemObj;
		var jsxCode;
		var jsxArray = [];
		
		for(i=0; i<length; i++) {
			itemObj = dashboardListJsonObj[i];
			jsxCode = this.renderOneDashboard(itemObj);
			jsxArray.push(jsxCode);
		}
		
		return jsxArray;
	}

	render() {
		return (
			<div className="SelectDashboardTopContainer">
				<div style={{height: "20px"}}></div>
				{this.renderAllDashboards()}
			</div>
		);
	}
}

export default function SelectDashboardFunction(props) {
	const navigate = useNavigate();
	return <SelectDashboard {...props} navigate={navigate} />;
}

