import React  from 'react';
import './App.css';
import './DashboardMain.css';
import DashboardLang from './DashboardLang.json';

class DashboardMainGraphCustomTooltip extends React.Component {

	constructor(props) {
		super(props);
	}
	
	render() {
		
		var selectedYear = this.props.selectedYear;
		var selectedMonth = this.props.selectedMonth;
		var endString = this.props.endString;
		var variableType = this.props.variableType;
		var currencyYN = this.props.currencyYN;
		var active = this.props.active;
		var payload = this.props.payload;
		
		if (!active) {
			return null;
		}

		if (payload === null) {
			return null;
		}

		var itemObj = payload[0];
		var name = itemObj.dataKey;
		var color = itemObj.color;
		var onePayload = itemObj.payload;
		var time = onePayload.time;
		var value = onePayload[name];
		var dayOfWeek = this.props.getDayOfWeek(selectedYear, selectedMonth, time);
		var weekdayString = this.props.getWeekdayStringShort(dayOfWeek);
		weekdayString = weekdayString.toUpperCase();
		var dateString = weekdayString + " " + time + "." + selectedMonth + "." + selectedYear;
		
		
		const divStyle = { color: color };

		var decimalPlaces = 0;
		if (variableType === "float") {
			decimalPlaces = 2;
		}
		
		var valueString = value;
		if (currencyYN) {
			valueString = this.props.getEURString(value, decimalPlaces);
		}

		return (
			<div className="DashboardMainGraphCustomTooltip">
				<div>
					<div>{dateString}</div>
					<div style={divStyle}>{name}: {valueString}</div>
				</div>
			</div>
		);
	}
}

export default DashboardMainGraphCustomTooltip;

