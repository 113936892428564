import React  from 'react';
import './App.css';
import './DashboardEdit.css';
import DashboardLang from './DashboardLang.json';
import YearMonthPopupMenu from "./YearMonthPopupMenu.js"
import ReactTooltip from 'react-tooltip';
import DashboardEditToolbar from './DashboardEditToolbar.js';

class DashboardEditMonthly extends React.Component {

	constructor(props) {

		super(props);
		
		this.state = {
			dateYear: 0,
			dateMonth: 0,
			dashboardVariableListJsonObj: null,
			showPopupMenu1YN: false,
		};
		
		this.serverGetDashboardVariableList = this.serverGetDashboardVariableList.bind(this);
		this.serverGetDashboardValueMonth = this.serverGetDashboardValueMonth.bind(this);
		this.serverGetDashboardDataForOneMonth = this.serverGetDashboardDataForOneMonth.bind(this);
		this.serverSetDashboardValue = this.serverSetDashboardValue.bind(this);
		this.onValueChanged = this.onValueChanged.bind(this);
		this.onValueSave = this.onValueSave.bind(this);
		this.onClickYearMonth = this.onClickYearMonth.bind(this);
		this.closePopupMenu1 = this.closePopupMenu1.bind(this);
		this.setSelectedMonth = this.setSelectedMonth.bind(this);
		this.setSelectedYear = this.setSelectedYear.bind(this);
		this.renderOneVariable = this.renderOneVariable.bind(this);
		this.renderAllVariables = this.renderAllVariables.bind(this);
	}

	componentDidMount() {

		var dateYear = 0;
		var dateMonth = 0;

		var currentDateTime = new Date();
		dateYear = currentDateTime.getFullYear();
		dateMonth = currentDateTime.getMonth() + 1;

		this.setState({
			dateYear: dateYear,
			dateMonth: dateMonth,
		});

		var dashboardID = this.props.dashboardID;
		this.serverGetDashboardVariableList(dashboardID, dateYear, dateMonth);
	}
	
	async serverGetDashboardVariableList(dashboardID, dateYear, dateMonth) {

		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/getdashboardvariablelist?dashboardid=" + dashboardID + "&variablefrequency=monthly";
		
		const res = await fetch(url);
		const dashboardVariableListJsonObj = await res.json();
		
		var i;
		var itemObj;
		
		for(i=0; i<dashboardVariableListJsonObj.length; i++) {
			itemObj = dashboardVariableListJsonObj[i];
			itemObj.value = "";
		}

		this.setState({
			dashboardVariableListJsonObj: dashboardVariableListJsonObj,
		});

		this.serverGetDashboardDataForOneMonth(dashboardVariableListJsonObj, dateYear, dateMonth);
	}
	
	async serverGetDashboardValueMonth(dashboardVariableID, year, month) {

		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/getdashboardvaluemonth?dashboardvariableid=" + dashboardVariableID;
		url = url + "&year=" + year;
		url = url + "&month=" + month;
		
		const res = await fetch(url);
		var responseText = await res.text();

		var jsonObj = null;
		if (responseText !== "") {
			jsonObj = JSON.parse(responseText);
		}
		
		return jsonObj;
	}
	
	async serverGetDashboardDataForOneMonth(dashboardVariableListJsonObj, dateYear, dateMonth) {
		
		this.setState({dashboardVariableListJsonObj: null});

		if (dashboardVariableListJsonObj === null) {
			return;
		}
		
		var i;
		var itemObj1;
		var dashboardVariableID;
		var variableType;
		var jsonObj;
		var itemObj2;
		var valueInt;
		var valueFloat;
		var valueString;
		var length = dashboardVariableListJsonObj.length;
		
		for(i=0; i<length; i++) {
			itemObj1 = dashboardVariableListJsonObj[i];
			dashboardVariableID = itemObj1.dashboardVariableID;
			variableType = itemObj1.variableType;
			jsonObj = await this.serverGetDashboardValueMonth(dashboardVariableID, dateYear, dateMonth);
			itemObj1.value = "";
			itemObj1.fullName = "";
			itemObj1.unixTimeSec = 0;
			if (jsonObj !== null) {
				if (jsonObj.length === 1) {
					itemObj2 = jsonObj[0];
					valueInt = itemObj2.valueInt;
					valueFloat = itemObj2.valueFloat;
					valueString = itemObj2.valueString;
					if (variableType === "int") {
						itemObj1.value = valueInt;
					}
					if (variableType === "float") {
						itemObj1.value = valueFloat;
					}
					if (variableType === "text") {
						itemObj1.value = valueString;
					}
					itemObj1.fullName = itemObj2.fullName;
					itemObj1.unixTimeSec = itemObj2.unixTimeSec;
				}
			}
		}

		this.setState({dashboardVariableListJsonObj: dashboardVariableListJsonObj});
	}
	
	async serverSetDashboardValue(dashboardVariableID, year, month, valueInt, valueFloat, valueString, userID, fullName) {
		
		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/setdashboardvalue?dashboardvariableid=" + dashboardVariableID;
		url = url + "&year=" + year;
		url = url + "&month=" + month;
		url = url + "&day=" + -1;
		url = url + "&valueint=" + valueInt;
		url = url + "&valuefloat=" + valueFloat;
		url = url + "&valuestring=" + encodeURIComponent(valueString);
		url = url + "&userid=" + userID;
		url = url + "&fullname=" + encodeURIComponent(fullName);
		
		const res = await fetch(url);
		await res.text();
	}
	
	onValueChanged(dashboardVariableID1, value) {

		var dashboardVariableListJsonObj = this.state.dashboardVariableListJsonObj;
		if (dashboardVariableListJsonObj === null) {
			return;
		}
		
		var i;
		var itemObj;
		var dashboardVariableID2;
		
		for(i=0; i<dashboardVariableListJsonObj.length; i++) {
			itemObj = dashboardVariableListJsonObj[i];
			dashboardVariableID2 = itemObj.dashboardVariableID;
			if (dashboardVariableID1 === dashboardVariableID2) {
				itemObj.value = value;
			}
		}
		
		this.setState({dashboardVariableListJsonObj: dashboardVariableListJsonObj});
	}

	onValueSave(dashboardVariableID, variableType, value) {

		value = value.trim();
		if (value === "") {
			return;
		}

		var valueInt = 0;
		var valueFloat = 0;
		var valueString = "";
		
		if (variableType === "int") {
			valueInt = parseInt(value);
		}
		
		if (variableType === "float") {
			valueFloat = parseFloat(value);
		}
		
		if (variableType === "text") {
			valueString = value;
		}
		
		this.serverSetDashboardValue(dashboardVariableID, this.state.dateYear, this.state.dateMonth, valueInt, valueFloat, valueString, this.props.userID, this.props.fullName);
	}

	onClickYearMonth() {
		var showPopupMenu1YN = !this.state.showPopupMenu1YN;
		this.setState({showPopupMenu1YN: showPopupMenu1YN});
	}

	closePopupMenu1() {
		this.setState({showPopupMenu1YN: false});
	}

	setSelectedMonth(month) {
		var dateMonth = this.state.dateMonth;
		if (dateMonth === month) {
			return;
		}
		this.setState({
			dateMonth: month,
		});
		this.serverGetDashboardDataForOneMonth(this.state.dashboardVariableListJsonObj, this.state.dateYear, month);
	}

	setSelectedYear(year) {
		var dateYear = this.state.dateYear;
		if (dateYear === year) {
			return;
		}
		this.setState({
			dateYear: year,
		});
		this.serverGetDashboardDataForOneMonth(this.state.dashboardVariableListJsonObj, year, this.state.dateMonth);
	}
	
	renderOneVariable(itemObj, showSalaryYN) {
		
		var calcHelpersObj = this.props.calcHelpersObj;
		var key = itemObj.dashboardVariableID;
		var dashboardVariableID = itemObj.dashboardVariableID;
		var variableName = itemObj.variableName;		
		var variableType = itemObj.variableType;
		var value = itemObj.value;
		var fullName = itemObj.fullName;
		var unixTimeSec = itemObj.unixTimeSec;
		
		var salaryYN;
		var skipVariableYN = false;
		if (!showSalaryYN) {
			salaryYN = calcHelpersObj.isVariableSalaryYN(variableName);
			if (salaryYN) {
				skipVariableYN = true;
				return null;				
			}
		}
		

		if (skipVariableYN) {
			return null;
		}

		var editString = "";		
		if (unixTimeSec > 0) {
			editString = fullName + " - " + this.props.getDateTimeStringFromUnixTimeSec(unixTimeSec);
		}

		var variableString = this.props.getLang(DashboardLang, variableName);
		
		var inputType = "text";
		if (variableType === "int") {
			inputType = "number";
		}
		if (variableType === "float") {
			inputType = "number";
		}
		
		if (inputType === "text") {
			return (
				<tr key={key}>
					<td className="DashboardEditVariable">{variableString}</td>
					<td align="left" className="DashboardEditRight">
						<textarea
							className="DashboardEditText" 
							id={dashboardVariableID}
							type={inputType} 
							value={value}
							rows="10"
							cols="60"
							onChange={(event) => this.onValueChanged(dashboardVariableID, event.target.value)}
							onBlur={(event) => this.onValueSave(dashboardVariableID, variableType, event.target.value)}
						/>
					</td>
					<td>
						{editString}
					</td>
				</tr>
			);
		}

		return (
			<tr key={key}>
				<td className="DashboardEditVariable">{variableString}</td>
				<td align="left" className="DashboardEditRight">
					<input
						className="DashboardEditNumber" 
						id={dashboardVariableID}
						type={inputType} 
						value={value}
						onChange={(event) => this.onValueChanged(dashboardVariableID, event.target.value)}
						onBlur={(event) => this.onValueSave(dashboardVariableID, variableType, event.target.value)}
					/>
				</td>
				<td>
					{editString}
				</td>
			</tr>
		);
	}

	renderAllVariables(showSalaryYN) {

		var dashboardVariableListJsonObj = this.state.dashboardVariableListJsonObj;

		var i;
		var itemObj;
		var jsxCode;
		var jsxArray = [];
		
		for(i=0; i<dashboardVariableListJsonObj.length; i++) {
			itemObj = dashboardVariableListJsonObj[i];
			jsxCode = this.renderOneVariable(itemObj, showSalaryYN);
			jsxArray.push(jsxCode);
		}
		
		return (
			<table>
				<tbody>
					{jsxArray}
				</tbody>
			</table>
		);
	}
	
	render() {
		
		var dashboardAppUserType = this.props.dashboardAppUserType;
		var dashboardUserType = this.props.dashboardUserType;

		var allowedYN = false;
		var showSalaryYN = false;
		if (dashboardAppUserType >= 100) {
			allowedYN = true;
			showSalaryYN = true;
		}
		if (dashboardUserType === 101) {
			allowedYN = true;
		}
		if (dashboardUserType === 102) {
			allowedYN = true;
			showSalaryYN = true;
		}
		
		if (!allowedYN) {
			return null;
		}

		var dashboardVariableListJsonObj = this.state.dashboardVariableListJsonObj;
		if (dashboardVariableListJsonObj === null) {
			return (<div><br/>Loading data...</div>);
		}
		
		var yearMonthString = this.state.dateYear + " " + this.props.getMonthString(this.state.dateMonth);
		var dataTip = "";

		return (
			<div>
				<ReactTooltip
					type="info"
					multiline={true}
					delayShow={200}
				/>
				<DashboardEditToolbar
					dashboardAppUserType={this.props.dashboardAppUserType}
					dashboardUserType={this.props.dashboardUserType}
					dashboardID={this.props.dashboardID}
					dashboardName={this.props.dashboardName}
					frequency="monthly"
					getLang={this.props.getLang}
				/>
				<div 
					className="DashboardEditMonthlyTopHeaderItem1" 
					onClick={() => this.onClickYearMonth()}
					data-tip={dataTip}
				>
					{yearMonthString}
					{this.state.showPopupMenu1YN && (
						<YearMonthPopupMenu
							fullYearYN={false}
							selectedYear={this.state.dateYear}
							selectedMonth={this.state.dateMonth}
							dashboardAppUserType={this.props.dashboardAppUserType}
							getMonthString={this.props.getMonthString}
							setSelectedMonth={this.setSelectedMonth}
							setSelectedYear={this.setSelectedYear}
							closePopupMenu={this.closePopupMenu1}
						/>
					)}
				</div>
				<div style={{height: "15px"}}/>
				{this.renderAllVariables(showSalaryYN)}
				<div style={{height: "100px"}}/>
			</div>
		);
	}
}

export default DashboardEditMonthly;

