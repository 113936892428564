import React  from 'react';
import './App.css';
import './DashboardMain.css';
import DashboardLang from './DashboardLang.json';
import ReactTooltip from 'react-tooltip';
import DashboardMainToolbar from './DashboardMainToolbar.js';

import YearMonthPopupMenu from "./YearMonthPopupMenu.js"
import DashboardMainRevenue from "./DashboardMainRevenue.js"
import DashboardMainExpenses from "./DashboardMainExpenses.js"
import DashboardMainGraph from "./DashboardMainGraph.js"

class DashboardMain extends React.Component {

	constructor(props) {

		super(props);
		
		this.state = {
			tab: "revenue",
			currentYear: 0,
			currentMonth: 0,
			selectedYear: 0,
			selectedMonth: 0,
			daysInMonth: 0,
			dashboardVariableListJsonObj: null,
			compareMonthTotalRoomRevenue: 0,
			compareMonthTotalOtherRevenue: 0,
			dashboardID: 0,
		};
		
		this.serverGetDashboardVariableList = this.serverGetDashboardVariableList.bind(this);		
		this.serverGetDashboardValueMonth = this.serverGetDashboardValueMonth.bind(this);		
		this.serverGetDashboardDataForOneMonth = this.serverGetDashboardDataForOneMonth.bind(this);
		this.serverGetDashboardMonthTotal = this.serverGetDashboardMonthTotal.bind(this);		
		this.serverGetDashboardDataForOneMonthTotal = this.serverGetDashboardDataForOneMonthTotal.bind(this);		
		this.calcTotalOtherRevenueForMonth = this.calcTotalOtherRevenueForMonth.bind(this);
		this.calcTotalRoomRevenueForMonth = this.calcTotalRoomRevenueForMonth.bind(this);
		this.getDaysInMonth = this.getDaysInMonth.bind(this);
		this.setTab = this.setTab.bind(this);
		this.onClickYearMonth = this.onClickYearMonth.bind(this);
		this.closePopupMenu1 = this.closePopupMenu1.bind(this);
		this.setSelectedMonth = this.setSelectedMonth.bind(this);
		this.setSelectedYear = this.setSelectedYear.bind(this);
		this.renderTopHeader = this.renderTopHeader.bind(this);
		this.renderRevenue = this.renderRevenue.bind(this);
		this.renderExpenses = this.renderExpenses.bind(this);
		this.renderGraph = this.renderGraph.bind(this);
		this.renderTab = this.renderTab.bind(this);
	}

	componentDidMount() {

		var calcHelpersObj = this.props.calcHelpersObj;
		var currentYear = 0;
		var currentMonth = 0;

		var currentDateTime = new Date();
		currentYear = currentDateTime.getFullYear();
		currentMonth = currentDateTime.getMonth() + 1;

		var daysInMonth = this.getDaysInMonth(currentYear, currentMonth);

		var totalRooms = 0;
		var dashboardID = this.props.dashboardID;
		if (dashboardID > 0) {
			this.serverGetDashboardVariableList(dashboardID, currentYear, currentMonth);
			totalRooms = calcHelpersObj.getTotalRooms(dashboardID);
		}
		
		this.setState({
			currentYear: currentYear,
			currentMonth: currentMonth,
			selectedYear: currentYear,
			selectedMonth: currentMonth,
			daysInMonth: daysInMonth,
			dashboardID: dashboardID,
			totalRooms: totalRooms,
		});
	}
	
	componentDidUpdate() {

		var dashboardID1 = this.state.dashboardID;
		var dashboardID2 = this.props.dashboardID;

		if (dashboardID1 === dashboardID2) {
			return;
		}

		var selectedYear = this.state.selectedYear;
		var selectedMonth = this.state.selectedMonth;
		if (dashboardID2 > 0) {
			this.serverGetDashboardVariableList(dashboardID2, selectedYear, selectedMonth);
		}
		
		this.setState({
			dashboardID: dashboardID2
		});
	}

	async serverGetDashboardVariableList(dashboardID, dateYear, dateMonth) {

		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/getdashboardvariablelist?dashboardid=" + dashboardID + "&variablefrequency=daily";
		
		const res = await fetch(url);
		const dashboardVariableListJsonObj = await res.json();
		
		var i;
		var itemObj;
		
		for(i=0; i<dashboardVariableListJsonObj.length; i++) {
			itemObj = dashboardVariableListJsonObj[i];
			itemObj.values = null;
		}

		this.setState({
			dashboardVariableListJsonObj: dashboardVariableListJsonObj,
		});

		this.serverGetDashboardDataForOneMonth(dashboardVariableListJsonObj, dateYear, dateMonth);
	}
	
	async serverGetDashboardValueMonth(dashboardVariableID, year, month) {

		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/getdashboardvaluemonth?dashboardvariableid=" + dashboardVariableID;
		url = url + "&year=" + year;
		url = url + "&month=" + month;
		
		const res = await fetch(url);
		var responseText = await res.text();

		var jsonObj = null;
		if (responseText !== "") {
			jsonObj = JSON.parse(responseText);
		}
		
		return jsonObj;
	}
	
	async serverGetDashboardDataForOneMonth(dashboardVariableListJsonObj, dateYear, dateMonth) {
		
		if (dashboardVariableListJsonObj === null) {
			return;
		}
		
		this.setState({dashboardVariableListJsonObj: null});

		var i;
		var itemObj1;
		var dashboardVariableID;
		var variableType;
		var jsonObj;
		var itemObj2;
		var valueString;
		
		for(i=0; i<dashboardVariableListJsonObj.length; i++) {
			itemObj1 = dashboardVariableListJsonObj[i];
			dashboardVariableID = itemObj1.dashboardVariableID;
			variableType = itemObj1.variableType;
			jsonObj = await this.serverGetDashboardValueMonth(dashboardVariableID, dateYear, dateMonth);
			itemObj1.values = jsonObj;
			itemObj1.compareMonthTotal = 0;
		}

		this.setState({dashboardVariableListJsonObj: dashboardVariableListJsonObj});
		this.serverGetDashboardDataForOneMonthTotal(dashboardVariableListJsonObj, dateYear, dateMonth);
	}
	
	async serverGetDashboardMonthTotal(dashboardVariableID, year, month) {

		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/getdashboardmonthtotal?dashboardvariableid=" + dashboardVariableID;
		url = url + "&year=" + year;
		url = url + "&month=" + month;
		
		const res = await fetch(url);
		var responseText = await res.text();

		return parseInt(responseText);
	}
	
	async serverGetDashboardDataForOneMonthTotal(dashboardVariableListJsonObj, dateYear, dateMonth) {
		
		var i;
		var itemObj1;
		var dashboardVariableID;
		var variableType;
		var itemObj2;
		var valueString;
		var compareMonthTotal;
		
		for(i=0; i<dashboardVariableListJsonObj.length; i++) {
			itemObj1 = dashboardVariableListJsonObj[i];
			dashboardVariableID = itemObj1.dashboardVariableID;
			variableType = itemObj1.variableType;
			compareMonthTotal = await this.serverGetDashboardMonthTotal(dashboardVariableID, dateYear-1, dateMonth);
			itemObj1.compareMonthTotal = compareMonthTotal;
		}

		var selectedYear = this.state.selectedYear;
		var selectedMonth = this.state.selectedMonth;
		var compareMonthTotalRoomRevenue = await this.calcTotalRoomRevenueForMonth(dashboardVariableListJsonObj, selectedYear-1, selectedMonth);
		var compareMonthTotalOtherRevenue = this.calcTotalOtherRevenueForMonth(dashboardVariableListJsonObj);
		
		this.setState({
			compareMonthTotalRoomRevenue: compareMonthTotalRoomRevenue,
			compareMonthTotalOtherRevenue: compareMonthTotalOtherRevenue,
			dashboardVariableListJsonObj: dashboardVariableListJsonObj
		});
		
	}
	
	calcTotalOtherRevenueForMonth(dashboardVariableListJsonObj) {

		var calcHelpersObj = this.props.calcHelpersObj;
		var i;
		var itemObj1;
		var variableName;
		var currencyYN;
		var compareMonthTotal;
		var sum = 0;

		for(i=0; i<dashboardVariableListJsonObj.length; i++) {
			itemObj1 = dashboardVariableListJsonObj[i];
			variableName = itemObj1.variableName;
			currencyYN = calcHelpersObj.isVariableCurrencyYN(variableName);
			if (variableName === "roomprice") {
				currencyYN = false;
			}
			if (currencyYN) {
				compareMonthTotal = itemObj1.compareMonthTotal;
				sum = sum + compareMonthTotal;
			}
		}
		return sum;
	}
	
	async calcTotalRoomRevenueForMonth(dashboardVariableListJsonObj, year, month) {

		var i;
		var itemObj;
		var variableName;
		var dashboardVariableID;
		var roomsJsonObj = null;
		var roomPriceJsonObj = null;

		for(i=0; i<dashboardVariableListJsonObj.length; i++) {
			itemObj = dashboardVariableListJsonObj[i];
			variableName = itemObj.variableName;
			dashboardVariableID = itemObj.dashboardVariableID;
			if (variableName === "rooms") {
				roomsJsonObj = await this.serverGetDashboardValueMonth(dashboardVariableID, year, month);
			}
			if (variableName === "roomprice") {
				roomPriceJsonObj = await this.serverGetDashboardValueMonth(dashboardVariableID, year, month);
			}
		}

		if ((roomsJsonObj === null) || (roomPriceJsonObj === null)) {
			return 0;
		}
		
		var data1 = [];
		var data2 = [];
		for(i=0; i<31; i++) {
			data1.push(0);
			data2.push(0);
		}

		var day;
		for(i=0; i<roomsJsonObj.length; i++) {
			itemObj = roomsJsonObj[i];
			day = itemObj.day;
			data1[day-1] = itemObj.valueInt;
		}
		for(i=0; i<roomPriceJsonObj.length; i++) {
			itemObj = roomPriceJsonObj[i];
			day = itemObj.day;
			data2[day-1] = itemObj.valueFloat;
		}
		
		var roomRevenueTotal = 0;
		var roomRevenue;
		
		for(i=0; i<31; i++) {
			roomRevenue = data1[i] * data2[i];
			roomRevenueTotal = roomRevenueTotal + roomRevenue;
		}

		return roomRevenueTotal;
	}

	getDaysInMonth(year, month) {
		return new Date(year, month, 0).getDate();
	}

	setTab(tab) {
		this.setState({
			tab: tab,
		});
	}
	
	onClickYearMonth() {
		var showPopupMenu1YN = !this.state.showPopupMenu1YN;
		this.setState({showPopupMenu1YN: showPopupMenu1YN});
	}

	renderTopHeader() {

		var yearMonthString = this.state.selectedYear + " " + this.props.getMonthString(this.state.selectedMonth);
		var dataTip = ""; //this.props.getLang(DashboardLang, "selectYearMonth");

		return (
			<div className="DashboardMainTopHeaderContainer">
				<div 
					className="DashboardMainTopHeaderItem1" 
					onClick={() => this.onClickYearMonth()}
					data-tip={dataTip}
				>
					{yearMonthString}
					{this.state.showPopupMenu1YN && (
						<YearMonthPopupMenu
							fullYearYN={false}
							selectedYear={this.state.selectedYear}
							selectedMonth={this.state.selectedMonth}
							dashboardAppUserType={this.props.dashboardAppUserType}
							getMonthString={this.props.getMonthString}
							setSelectedMonth={this.setSelectedMonth}
							setSelectedYear={this.setSelectedYear}
							closePopupMenu={this.closePopupMenu1}
						/>
					)}
				</div>
			</div>
		);
	}

	closePopupMenu1() {
		this.setState({showPopupMenu1YN: false});
	}

	setSelectedMonth(month) {
		var selectedMonth = this.state.selectedMonth;
		if (selectedMonth === month) {
			return;
		}
		var daysInMonth = this.getDaysInMonth(this.state.selectedYear, month);
		this.setState({
			selectedMonth: month,
			daysInMonth: daysInMonth,
		});
		this.serverGetDashboardDataForOneMonth(this.state.dashboardVariableListJsonObj, this.state.selectedYear, month);
	}

	setSelectedYear(year) {
		var selectedYear = this.state.selectedYear;
		if (selectedYear === year) {
			return;
		}
		var daysInMonth = this.getDaysInMonth(year, this.state.selectedMonth);
		this.setState({
			selectedYear: year,
			daysInMonth: daysInMonth,
		});
		this.serverGetDashboardDataForOneMonth(this.state.dashboardVariableListJsonObj, year, this.state.selectedMonth);
	}
	
	renderRevenue() {
		return (
			<DashboardMainRevenue
				calcHelpersObj={this.props.calcHelpersObj}
				dashboardVariableListJsonObj={this.state.dashboardVariableListJsonObj}
				currentYear={this.state.currentYear}
				currentMonth={this.state.currentMonth}
				selectedYear={this.state.selectedYear}
				selectedMonth={this.state.selectedMonth}
				daysInMonth={this.state.daysInMonth}
				totalRooms={this.state.totalRooms}
				compareMonthTotalRoomRevenue={this.state.compareMonthTotalRoomRevenue}
				compareMonthTotalOtherRevenue={this.state.compareMonthTotalOtherRevenue}
				setTab={this.setTab}
				getDaysInMonth={this.getDaysInMonth}
				getLang={this.props.getLang}
				getEURString={this.props.getEURString}
			/>
		);
	}

	renderExpenses() {
		var calcHelpersObj = this.props.calcHelpersObj;
		var dashboardVariableListJsonObj = this.state.dashboardVariableListJsonObj;
		var totalRevenue = calcHelpersObj.calcTotalRevenue(dashboardVariableListJsonObj, this.state.daysInMonth);
		return (
			<DashboardMainExpenses
				calcHelpersObj={this.props.calcHelpersObj}
				dashboardAppUserType={this.props.dashboardAppUserType}
				dashboardUserType={this.props.dashboardUserType}
				dashboardID={this.props.dashboardID}
				baseURLAPI={this.props.baseURLAPI}
				currentYear={this.state.currentYear}
				currentMonth={this.state.currentMonth}
				selectedYear={this.state.selectedYear}
				selectedMonth={this.state.selectedMonth}
				daysInMonth={this.state.daysInMonth}
				totalRooms={this.state.totalRooms}
				totalRevenue={totalRevenue}
				compareMonthTotalRoomRevenue={this.state.compareMonthTotalRoomRevenue}
				setTab={this.setTab}
				getDaysInMonth={this.getDaysInMonth}
				getLang={this.props.getLang}
				getEURString={this.props.getEURString}
				convertTextNewlineToBr={this.props.convertTextNewlineToBr}
			/>
		);
	}

	renderGraph(tab) {
		return (
			<DashboardMainGraph
				calcHelpersObj={this.props.calcHelpersObj}
				tab={this.state.tab}
				currentYear={this.state.currentYear}
				currentMonth={this.state.currentMonth}
				selectedYear={this.state.selectedYear}
				selectedMonth={this.state.selectedMonth}
				daysInMonth={this.state.daysInMonth}
				dashboardVariableListJsonObj={this.state.dashboardVariableListJsonObj}
				getLang={this.props.getLang}
				getEURString={this.props.getEURString}
				getDayOfWeek={this.props.getDayOfWeek}
				getWeekdayString={this.props.getWeekdayString}
				getWeekdayStringShort={this.props.getWeekdayStringShort}
			/>
		);
	}
	
	renderTab() {
		
		var tab = this.state.tab;
		
		if (tab === "revenue") {
			return this.renderRevenue();
		}
		
		if (tab === "expenses") {
			return this.renderExpenses();
		}
		
		return this.renderGraph(tab);
	}

	render() {

		return (
			<div className="DashboardMainTopContainer">
				<ReactTooltip
					type="info"
					multiline={true}
					delayShow={200}
				/>
				<DashboardMainToolbar
					dashboardAppUserType={this.props.dashboardAppUserType}
					dashboardUserType={this.props.dashboardUserType}
					dashboardID={this.props.dashboardID}
					dashboardName={this.props.dashboardName}
					dashboardVariableListJsonObj={this.state.dashboardVariableListJsonObj}
					tab={this.state.tab}
					setTab={this.setTab}
					getLang={this.props.getLang}
				/>
				{this.renderTopHeader()}
				{this.renderTab()}
			</div>
		);
	}
}

export default DashboardMain;

