import React  from 'react';
import './App.css';
import './Daily.css';
import DailyLang from './DailyLang.json';

class DailyOccupancy extends React.Component {

	constructor(props) {

		super(props);
		
		this.state = {
			innerWidth: 0,
			innerHeight: 0,
			currentYear: 0,
			currentMonth: 0,
			jsonObj: null,
			averageRoomsMonthArray: null,
			averageRoomPriceMonthArray: null,
			averageDailyRevenueMonthArray: null,
			totalRevenueMonthArray: null,
			totalRoomsMonthArray: null,
			dayCountMonthArray: null,
			averageRoomPriceYear: 0,
			averageRoomsYear: 0,
			occupancyRateYear: 0,
			totalRevenueYear: 0,
			totalRooms: 0,
			dayCountYear: 0,
			dashboardVariableListJsonObj: null,
			compareToYear: 0,
			compareAverageRoomsMonthArray: null,
			compareAverageRoomPriceMonthArray: null,
			compareTotalRevenueMonthArray: null,
		};
		
		this.updateDimensions = this.updateDimensions.bind(this);
		this.serverGetDashboardVariableList = this.serverGetDashboardVariableList.bind(this);		
		this.serverDataConvertCsvToJson = this.serverDataConvertCsvToJson.bind(this);		
		this.calcRoomsSumMonth = this.calcRoomsSumMonth.bind(this);
		this.calcAverageMonthYear = this.calcAverageMonthYear.bind(this);
		this.onClickYear = this.onClickYear.bind(this);
		this.renderHeaderOneYear = this.renderHeaderOneYear.bind(this);
		this.renderHeaderAllYears = this.renderHeaderAllYears.bind(this);
		this.renderNoReport = this.renderNoReport.bind(this);
		this.renderHeader = this.renderHeader.bind(this);
		this.renderOneLine = this.renderOneLine.bind(this);		
		this.getItemMonthDay = this.getItemMonthDay.bind(this);
		this.renderOneMonth = this.renderOneMonth.bind(this);
		this.renderAllMonths = this.renderAllMonths.bind(this);		
		this.renderYearSummaryOneMonth = this.renderYearSummaryOneMonth.bind(this);
		this.renderYearSummary = this.renderYearSummary.bind(this);
		this.renderCompareToYear = this.renderCompareToYear.bind(this);
	}

	componentDidMount() {
		this.updateDimensions();
		window.addEventListener('resize', this.updateDimensions);
		var dateObj = new Date();
		var currentYear = dateObj.getFullYear();
		var currentMonth = dateObj.getMonth() + 1;
		this.setState({
			currentYear: currentYear,
			currentMonth: currentMonth,
		});
		this.serverDataConvertCsvToJson();
		var dashboardID = this.props.dashboardID;
		if (dashboardID > 0) {
			this.serverGetDashboardVariableList(dashboardID);
		}
	}

	componentWillUnmount() {
		document.removeEventListener('resize', this.updateDimensions, true);
	};
	
	updateDimensions = () => {
		this.setState({ innerWidth: window.innerWidth, innerHeight: window.innerHeight });
	};
	
	async serverGetDashboardVariableList(dashboardID) {

		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/getdashboardvariablelist?dashboardid=" + dashboardID + "&variablefrequency=daily";
		console.log(url);
		
		const res = await fetch(url);
		const dashboardVariableListJsonObj = await res.json();
		
		var i;
		var itemObj;
		
		for(i=0; i<dashboardVariableListJsonObj.length; i++) {
			itemObj = dashboardVariableListJsonObj[i];
			itemObj.values = null;
		}

		this.setState({
			dashboardVariableListJsonObj: dashboardVariableListJsonObj,
		});
	}
	
	async serverDataConvertCsvToJson() {

		var baseURLAPI = this.props.baseURLAPI;
		var dashboardID = this.props.dashboardID;
		var dataUrl = "/dashboard/" + dashboardID + "/roomsWholeYear/current.csv"
		var url = baseURLAPI + "/dataconvertcsvtojson?url=" +  encodeURIComponent(dataUrl);
		
		/*if ((dashboardID !== 1) && (dashboardID !== 2)) {
			return;
		}*/
		
		const res = await fetch(url);
		const jsonObj = await res.json();

		this.calcAverageMonthYear(jsonObj);
		this.setState({jsonObj: jsonObj});
	}
	
	calcRoomsSumMonth(jsonObj, month1) {

		var i;
		var itemObj;
		var dayCount = 0;
		var rooms;
		var roomsSum = 0;
		var revenue;
		var revenueSum = 0;

		for(i=1; i<=31; i++) {
			itemObj = this.getItemMonthDay(jsonObj, month1, i);
			if (itemObj !== null) {
				rooms = parseInt(itemObj.rooms);
				revenue = parseFloat(itemObj.revenue);
				roomsSum = roomsSum + rooms;
				revenueSum = revenueSum + revenue;
				dayCount ++;
			}
		}

		return [roomsSum, revenueSum, dayCount]
	}

	calcAverageMonthYear(jsonObj) {
		
		if (jsonObj === null) {
			return;
		}

		var calcHelpersObj = this.props.calcHelpersObj;
		var dashboardID = this.props.dashboardID;
		var totalRooms = calcHelpersObj.getTotalRooms(dashboardID);
		var i;
		var itemObj;
		var averageRoomsMonthArray = [];
		var averageRoomPriceMonthArray = [];
		var averageDailyRevenueMonthArray = [];
		var totalRevenueMonthArray = [];
		var totalRoomsMonthArray = [];
		var dayCountMonthArray = [];
		var roomsSumMonth;
		var revenueSumMonth;
		var dayCountMonth;
		var roomsPerDay;
		var roomPrice;
		var roomsSumYear = 0;
		var dayCountYear = 0;
		var averageDailyRevenue;
		var totalRevenueYear = 0;
		
		for(i=1; i<=12; i++) {
			[roomsSumMonth, revenueSumMonth, dayCountMonth] = this.calcRoomsSumMonth(jsonObj, i);
			roomsSumYear = roomsSumYear + roomsSumMonth;
			if (revenueSumMonth > 0) {
				dayCountYear = dayCountYear + dayCountMonth;
			}
			roomsPerDay = 0;
			averageDailyRevenue = 0;
			if (dayCountMonth > 0) {
				roomsPerDay = roomsSumMonth / dayCountMonth;
				averageDailyRevenue = revenueSumMonth / dayCountMonth;
			}
			if (roomsSumMonth === 0) {
				roomPrice = 0;
			} else {
				roomPrice = revenueSumMonth / roomsSumMonth;
			}
			averageRoomsMonthArray.push(roomsPerDay);
			averageRoomPriceMonthArray.push(roomPrice);
			totalRevenueMonthArray.push(revenueSumMonth);
			totalRoomsMonthArray.push(roomsSumMonth);
			dayCountMonthArray.push(dayCountMonth);
			averageDailyRevenueMonthArray.push(averageDailyRevenue);
			totalRevenueYear = totalRevenueYear + revenueSumMonth;
		}
		
		if (dayCountYear === 0) {
			dayCountYear = 1;
		}
		var averageRoomsYear = roomsSumYear / dayCountYear;
		var averageRoomPriceYear = 0;
		if (roomsSumYear > 0) {
			averageRoomPriceYear = totalRevenueYear / roomsSumYear;
		}
		var occupancyRateYear = 100 * averageRoomsYear / totalRooms;

		this.setState({
			averageRoomsMonthArray: averageRoomsMonthArray,
			averageRoomPriceMonthArray: averageRoomPriceMonthArray,
			averageDailyRevenueMonthArray: averageDailyRevenueMonthArray,
			totalRevenueMonthArray: totalRevenueMonthArray,
			totalRoomsMonthArray: totalRoomsMonthArray,
			dayCountMonthArray: dayCountMonthArray,
			averageRoomsYear: averageRoomsYear,
			occupancyRateYear: occupancyRateYear,
			averageRoomPriceYear: averageRoomPriceYear,
			totalRevenueYear: totalRevenueYear,
			totalRooms: totalRooms,
			dayCountYear: dayCountYear,
		});
	}
	
	async onClickYear(year) {
		
		var compareToYear = this.state.compareToYear;

		if (year === compareToYear) {
			compareToYear = 0;
		} else {
			compareToYear = year;
		}

		var calcHelpersObj = this.props.calcHelpersObj;
		var dashboardVariableListJsonObj = this.state.dashboardVariableListJsonObj;
		var currentYear = this.state.currentYear;
		var currentMonth = this.state.currentMonth;
		var totalRooms = this.state.totalRooms;
		var dashboardVariableID;
		var jsonObj;
		var month;
		var monthValue;
		var dayValue;
		var daysInMonth;
		var compareAverageRoomsMonthArray = [];
		var compareAverageRoomPriceMonthArray = [];
		var compareTotalRevenueMonthArray = [];
		var compareTotalRevenueYear = 0;
		var compareDayCountYear = 0;
		var roomsSumYear = 0;

		if (compareToYear > 0) {
			
			dashboardVariableID = calcHelpersObj.getDashboardVariableIDFromVariableName(dashboardVariableListJsonObj, "rooms");
			jsonObj = await calcHelpersObj.serverGetDashboardValueYear(dashboardVariableID, year);
			for(month=0; month<12; month++) {
				monthValue = calcHelpersObj.getMonthValue(currentYear, currentMonth, year, month+1, "rooms", jsonObj);
				roomsSumYear = roomsSumYear + monthValue;
				daysInMonth = calcHelpersObj.getDaysInMonth(compareToYear, month);
				dayValue = monthValue / daysInMonth;
				compareAverageRoomsMonthArray.push(dayValue);
				if (monthValue > 0) {
					compareDayCountYear = compareDayCountYear + daysInMonth;
				}
			}

			dashboardVariableID = calcHelpersObj.getDashboardVariableIDFromVariableName(dashboardVariableListJsonObj, "roomprice");
			jsonObj = await calcHelpersObj.serverGetDashboardValueYear(dashboardVariableID, year);
			for(month=0; month<12; month++) {
				monthValue = calcHelpersObj.getMonthValue(currentYear, currentMonth, year, month+1, "roomprice", jsonObj);
				compareAverageRoomPriceMonthArray.push(monthValue);
			}

			jsonObj = await calcHelpersObj.calcRoomRevenueValueYear(dashboardVariableListJsonObj, year);
			for(month=0; month<12; month++) {
				monthValue = calcHelpersObj.getMonthValue(currentYear, currentMonth, year, month+1, "roomrevenue", jsonObj);
				compareTotalRevenueMonthArray.push(monthValue);
				compareTotalRevenueYear = compareTotalRevenueYear + monthValue;
			}

			if (compareDayCountYear === 0) {
				compareDayCountYear = 1;
			}
			var compareAverageRoomsYear = roomsSumYear / compareDayCountYear;
			var compareAverageRoomPriceYear = 0;
			if (roomsSumYear > 0) {
				compareAverageRoomPriceYear = compareTotalRevenueYear / roomsSumYear;
			}
			var compareOccupancyRateYear = 100 * compareAverageRoomsYear / totalRooms;
		}

		this.setState({
			compareToYear: compareToYear,
			compareAverageRoomsMonthArray: compareAverageRoomsMonthArray,
			compareAverageRoomPriceMonthArray: compareAverageRoomPriceMonthArray,
			compareTotalRevenueMonthArray: compareTotalRevenueMonthArray,
			compareTotalRevenueYear: compareTotalRevenueYear,
			compareAverageRoomsYear: compareAverageRoomsYear,
			compareAverageRoomPriceYear: compareAverageRoomPriceYear,
			compareOccupancyRateYear: compareOccupancyRateYear,
			compareDayCountYear: compareDayCountYear,
		});
	}
	
	renderHeaderOneYear(year, selected) {
		
		var className = "";
		
		if (selected) {
			className = "YearlyRevenueTopHeaderOneYearSelected";
		} else {
			className = "YearlyRevenueTopHeaderOneYear";
		}
		
		return (
			<div key={year} className={className} onClick={() => this.onClickYear(year)}>{year}</div>
		);
	}

	renderHeaderAllYears() {
		
		var innerWidth = this.state.innerWidth;
		
		var yearCount = 10;
		if (innerWidth < 1000) {
			yearCount = 5;
		}

		var currentYear = this.state.currentYear;
		var compareToYear = this.state.compareToYear;
		var i;
		var rows = [];
		var year;
		var selected;

		for(i=0; i<yearCount; i++) {
			year = currentYear - yearCount + i;
			if (year === compareToYear) {
				selected = true;
			} else {
				selected = false;
			}
			rows.push(this.renderHeaderOneYear(year, selected));
		}

		return (
			<div className="YearlyRevenueTopHeaderContainer">{rows}</div>
		);
	}
	
	renderNoReport() {

		var dashboardName = this.props.dashboardName;
		var string1 = this.props.getLang(DailyLang, "noOccupancyReportForProperty");
		var string2 = string1 + " " + dashboardName;

		return (
			<div>
				<div style={{height: "20px"}}></div>
				{string2}
				<div style={{height: "500px"}}></div>
			</div>
		);
	}

	renderHeader() {

		var jsonObj = this.state.jsonObj;
		var headerObj = jsonObj.header;
		var unixTimeSec = headerObj.unixTimeSec;
		var timeString = this.props.getDateTimeStringFromUnixTimeSec(unixTimeSec);

		return (
			<div>
				{timeString}
			</div>
		);
	}

	renderOneLine(index, itemObj) {

		var totalRooms = this.state.totalRooms;
		var year = itemObj.year;
		var month = itemObj.month;
		var day = itemObj.day;
		var rooms = itemObj.rooms;
		var revenue = itemObj.revenue;
		var roomPrice = itemObj.roomPrice;
		var dateString = day + "." + month + "." + year;
		var revenueString = this.props.getEURString(revenue, 0);
		var roomPriceString = Math.round(roomPrice);

		var occupancyRate = Math.round(100 * rooms / totalRooms);

		return (
			<div key={index} className="DailyOccupancyBoxLineContainer">
				<div className="DailyOccupancyBoxLeft">{dateString}</div>
				<div className="DailyOccupancyBoxRight">{rooms} x {roomPriceString} = <b>{revenueString}</b> - {occupancyRate}%</div>
			</div>
		)
	}
	
	getItemMonthDay(jsonObj, month1, day1) {
		
		if (jsonObj === null) {
			return null;
		}

		var dataObj = jsonObj.data;
		var i;
		var itemObj;
		var month2;
		var day2;
		
		for(i=0; i<dataObj.length; i++) {
			itemObj = dataObj[i];
			month2 = itemObj.month;
			day2 = itemObj.day;
			if ((month1 === month2) && (day1 === day2)) {
				return itemObj;
			}
		}
		
		return null;
	}
	
	renderOneMonth(month1, mobileYN) {
		
		var i;
		var itemObj;
		var jsxCode;
		var jsxArray = [];
		
		for(i=1; i<=31; i++) {
			itemObj = this.getItemMonthDay(this.state.jsonObj, month1, i);
			if (itemObj !== null) {
				jsxCode = this.renderOneLine(i, itemObj);
				jsxArray.push(jsxCode);
			}
		}
		
		var currentYear = this.state.currentYear;
		var totalRooms = this.state.totalRooms;
		var averageRoomsMonthArray = this.state.averageRoomsMonthArray;
		var totalRevenueMonthArray = this.state.totalRevenueMonthArray;
		var totalRoomsMonthArray = this.state.totalRoomsMonthArray;
		var dayCountMonthArray = this.state.dayCountMonthArray;
		var roomsPerDay = averageRoomsMonthArray[month1-1];
		var occupancyRate = Math.round(100 * roomsPerDay / totalRooms);
		var totalRevenue = totalRevenueMonthArray[month1-1];
		var rooms = totalRoomsMonthArray[month1-1];
		var dayCountMonth = dayCountMonthArray[month1-1];
		
		if (totalRevenue === 0) {
			return null;
		}

		var roomPrice = 0;
		if (rooms > 0) {
			roomPrice = totalRevenue / rooms;
		}

		var monthString = this.props.getMonthString(month1);
		var totalRevenueString = this.props.getEURString(totalRevenue, 0);

		var trevparDay = totalRevenue / totalRooms / dayCountMonth;
		var trevparMonth = totalRevenue / totalRooms;
		
		var trevparString = this.props.getEURString(trevparDay, 0) + " (" + this.props.getEURString(trevparMonth, 0) + ")";

		return (
			<div key={month1}>
				{mobileYN && (<div style={{height: "20px"}}></div>)}
				<div className="DailyOccupancyOneBox">
					<div className="DailyOccupancyBoxTitle">{monthString} {currentYear}</div>
					{jsxArray}
					<div className="DailyOccupancyBoxLineContainer">
						<div className="DailyOccupancyBoxLeft"><b>{this.props.getLang(DailyLang, "average")}:</b></div>
						<div className="DailyOccupancyBoxRight">{Math.round(roomsPerDay)} x {Math.round(roomPrice)} - {occupancyRate}%</div>
					</div>
					<div className="DailyOccupancyBoxLineContainer">
						<div className="DailyOccupancyBoxLeft"><b>RRevPAR:</b></div>
						<div className="DailyOccupancyBoxRight">{trevparString}</div>
					</div>
					<div className="DailyOccupancyBoxLineContainer">
						<div className="DailyOccupancyBoxLeft"><b>{this.props.getLang(DailyLang, "total")}:</b></div>
						<div className="DailyOccupancyBoxRight">{totalRevenueString}</div>
					</div>				
					<div style={{height: "20px"}}></div>
				</div>
			</div>
		);
	}
	
	renderAllMonths() {

		var innerWidth = this.state.innerWidth;
		
		var mobileYN = false;
		if (innerWidth < 1000) {
			mobileYN = true;
		}
		
		var i;
		var jsxCode;
		var jsxArray = [];
		
		for(i=1; i<=12; i++) {
			jsxCode = this.renderOneMonth(i, mobileYN);
			jsxArray.push(jsxCode);
		}

		if (mobileYN) {
			return (
				<center>
					{jsxArray}
				</center>
			);
		}

		return (
			<div className="DailyOccupancyBoxContainer">
				{jsxArray}
			</div>
		);
	}

	renderYearSummaryOneMonth(month1) {

		var averageRoomsMonthArray = this.state.averageRoomsMonthArray;
		var averageRoomPriceMonthArray = this.state.averageRoomPriceMonthArray;
		var totalRevenueMonthArray = this.state.totalRevenueMonthArray;

		var totalRooms = this.state.totalRooms;
		var averageRoomsMonth = averageRoomsMonthArray[month1-1];
		var roomPrice = averageRoomPriceMonthArray[month1-1];
		var revenue = totalRevenueMonthArray[month1-1];
		var occupancyRate = Math.round(100 * averageRoomsMonth / totalRooms);
		var monthString = this.props.getMonthString(month1);
		var roomPriceString = Math.round(roomPrice);
		var revenueString = this.props.getEURString(revenue, 0);
		
		if (revenue === 0) {
			return null;
		}

		return (
			<div key={month1} className="DailyOccupancyBoxLineContainer">
				<div className="DailyOccupancyBoxLeft">{monthString}</div>
				<div className="DailyOccupancyBoxRight">{Math.round(averageRoomsMonth)} x {roomPriceString} => <b>{revenueString}</b> - {occupancyRate}%</div>
			</div>
		)
	}
	
	renderYearSummary() {

		var i;
		var jsxCode;
		var jsxArray = [];
		var currentYear = this.state.currentYear;
		var averageRoomsYear = this.state.averageRoomsYear;
		var occupancyRateYear = this.state.occupancyRateYear;
		var averageRoomPriceYear = this.state.averageRoomPriceYear;
		var totalRevenueYear = this.state.totalRevenueYear;
		var totalRevenueYearString = this.props.getEURString(totalRevenueYear, 0);
		var totalRooms = this.state.totalRooms;
		var dayCountYear = this.state.dayCountYear;
		var trevparDay = totalRevenueYear / totalRooms / dayCountYear;
		var trevparYear = totalRevenueYear / totalRooms;
		
		var trevparString = this.props.getEURString(trevparDay, 0) + " (" + this.props.getEURString(trevparYear, 0) + ")";
		
		for(i=1; i<=12; i++) {
			jsxCode = this.renderYearSummaryOneMonth(i);
			jsxArray.push(jsxCode);
		}

		return (
			<div className="DailyOccupancyOneBox">
				<div className="DailyOccupancyBoxTitle">{this.props.getLang(DailyLang, "fullYear")} {currentYear}</div>
				{jsxArray}
				<div className="DailyOccupancyBoxLineContainer">
					<div className="DailyOccupancyBoxLeft"><b>{this.props.getLang(DailyLang, "average")}:</b></div>
					<div className="DailyOccupancyBoxRight">{Math.round(averageRoomsYear)} x {Math.round(averageRoomPriceYear)} - {Math.round(occupancyRateYear)}%</div>
				</div>
				<div className="DailyOccupancyBoxLineContainer">
					<div className="DailyOccupancyBoxLeft"><b>RRevPAR:</b></div>
					<div className="DailyOccupancyBoxRight">{trevparString}</div>
				</div>
				<div className="DailyOccupancyBoxLineContainer">
					<div className="DailyOccupancyBoxLeft"><b>{this.props.getLang(DailyLang, "total")}:</b></div>
					<div className="DailyOccupancyBoxRight">{totalRevenueYearString}</div>
				</div>				
			</div>
		);
	}
	
	renderCompareToYearOneMonth(month1) {

		var compareAverageRoomsMonthArray = this.state.compareAverageRoomsMonthArray;
		var compareAverageRoomPriceMonthArray = this.state.compareAverageRoomPriceMonthArray;
		var compareTotalRevenueMonthArray = this.state.compareTotalRevenueMonthArray;

		var totalRooms = this.state.totalRooms;
		var averageRoomsMonth = compareAverageRoomsMonthArray[month1-1];
		var roomPrice = compareAverageRoomPriceMonthArray[month1-1];
		var revenue = compareTotalRevenueMonthArray[month1-1];
		var occupancyRate = Math.round(100 * averageRoomsMonth / totalRooms);
		var monthString = this.props.getMonthString(month1);
		var roomPriceString = Math.round(roomPrice);
		var revenueString = this.props.getEURString(revenue, 0);
		
		if (revenue === 0) {
			return null;
		}

		return (
			<div key={month1} className="DailyOccupancyBoxLineContainer">
				<div className="DailyOccupancyBoxLeft">{monthString}</div>
				<div className="DailyOccupancyBoxRight">{Math.round(averageRoomsMonth)} x {roomPriceString} => <b>{revenueString}</b> - {occupancyRate}%</div>
			</div>
		)
	}
	

	renderCompareToYear() {

		var compareToYear = this.state.compareToYear;
		if (compareToYear === 0) {
			return null;
		}

		var totalRooms = this.state.totalRooms;
		var compareDayCountYear = this.state.compareDayCountYear;
		var compareAverageRoomsYear = this.state.compareAverageRoomsYear;
		var compareAverageRoomPriceYear = this.state.compareAverageRoomPriceYear;
		var compareOccupancyRateYear = this.state.compareOccupancyRateYear;
		var compareTotalRevenueYear = this.state.compareTotalRevenueYear;
		var totalRevenueYearString = this.props.getEURString(compareTotalRevenueYear, 0);
		var i;
		var jsxCode;
		var jsxArray = [];

		var trevparDay = compareTotalRevenueYear / totalRooms / compareDayCountYear;
		var trevparYear = compareTotalRevenueYear / totalRooms;
		
		var trevparString = this.props.getEURString(trevparDay, 0) + " (" + this.props.getEURString(trevparYear, 0) + ")";

		for(i=1; i<=12; i++) {
			jsxCode = this.renderCompareToYearOneMonth(i);
			jsxArray.push(jsxCode);
		}
		
		return (
			<div className="DailyOccupancyOneBox">
				<div className="DailyOccupancyBoxTitle">{this.props.getLang(DailyLang, "fullYear")} {compareToYear}</div>
				{jsxArray}
				<div className="DailyOccupancyBoxLineContainer">
					<div className="DailyOccupancyBoxLeft"><b>{this.props.getLang(DailyLang, "average")}:</b></div>
					<div className="DailyOccupancyBoxRight">{Math.round(compareAverageRoomsYear)} x {Math.round(compareAverageRoomPriceYear)} - {Math.round(compareOccupancyRateYear)}%</div>
				</div>
				<div className="DailyOccupancyBoxLineContainer">
					<div className="DailyOccupancyBoxLeft"><b>RRevPAR:</b></div>
					<div className="DailyOccupancyBoxRight">{trevparString}</div>
				</div>
				<div className="DailyOccupancyBoxLineContainer">
					<div className="DailyOccupancyBoxLeft"><b>{this.props.getLang(DailyLang, "total")}:</b></div>
					<div className="DailyOccupancyBoxRight">{totalRevenueYearString}</div>
				</div>				
			</div>		
		);
	}

	render() {

		var jsonObj = this.state.jsonObj;
		if (jsonObj === null) {
			return;			
		}

		return (
			<div>
				<div style={{height: "20px"}}></div>
				{this.renderHeader()}
				<div style={{height: "20px"}}></div>
				<center>
				{this.renderYearSummary()}
				{this.renderHeaderAllYears()}
				{this.renderCompareToYear()}
				</center>
				{this.renderAllMonths()}
			</div>
		);
	}
}

export default DailyOccupancy;

