import React  from 'react';
import './App.css';
import './SelectDashboardPopupMenu.css';

class SelectDashboardPopupMenu extends React.Component {

	constructor(props) {

		super(props);
		this.ref = React.createRef();

		this.handleClickOutside = this.handleClickOutside.bind(this);
		this.handleDashboardClick = this.handleDashboardClick.bind(this);
		this.renderOneDashboard = this.renderOneDashboard.bind(this);
		this.renderAllDashboards = this.renderAllDashboards.bind(this);
	}

	componentDidMount() {
		document.addEventListener('click', this.handleClickOutside.bind(this), true);
	}

	componentWillUnmount() {
		document.removeEventListener('click', this.handleClickOutside.bind(this), true);
	};
	
	handleClickOutside(event) {
		if (this.ref.current && !this.ref.current.contains(event.target)) {
			this.props.closePopupMenu();
		}
	};

	handleDashboardClick(dashboardID) {
		this.props.closePopupMenu();
		this.props.setSelectedDashboard(dashboardID);
	}

	renderOneDashboard(itemObj) {
		
		var dashboardID = itemObj.dashboardID;
		var dashboardName = itemObj.dashboardName;
		
		return (
			<div 
				key={dashboardID} 
				className="SelectDashboardPopupMenuOneDashboard"
				onClick={() => this.handleDashboardClick(dashboardID)}
			>
				{dashboardName}
			</div>
		);
	}

	renderAllDashboards() {

		var dashboardListJsonObj = this.props.dashboardListJsonObj;
		if (dashboardListJsonObj === null) {
			return null;
		}

		var i;
		var itemObj;
		var jsxCode;
		var jsxArray = [];

		for (i=0; i<dashboardListJsonObj.length; i++) {
			itemObj = dashboardListJsonObj[i];
			jsxCode = this.renderOneDashboard(itemObj);
			jsxArray.push(jsxCode);
		}
		return (
			<div className="SelectDashboardPopupMenuDashboards">{jsxArray}</div>
		);
	}

	render() {
		
		return (
			<div ref={this.ref} className="SelectDashboardPopupMenuContainer1">
				<div className="SelectDashboardPopupMenuContainer">
					{this.renderAllDashboards()}
				</div>
			</div>
		);
	}
}

export default SelectDashboardPopupMenu;