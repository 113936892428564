import React  from 'react';
import './App.css';
import './Compare.css';
import DashboardLang from './DashboardLang.json';
import YearMonthPopupMenu from "./YearMonthPopupMenu.js"
import SelectDashboardPopupMenu from "./SelectDashboardPopupMenu.js"

import { useNavigate } from "react-router-dom";

class Compare extends React.Component {

	constructor(props) {

		super(props);

		this.state = {
			dashboardListJsonObjWithPermissions: [],
			dashboardID1: 0,
			dashboardID2: 0,
			datePickerIndex: 0,
			showDatePicker1YN: false,
			showDatePicker2YN: false,
			currentYear: 0,
			currentMonth: 0,
			year1: 0,
			month1: 0,
			year2: 0,
			month2: 0,
			totalDays1: 0,
			totalDays2: 0,
			dashboardPickerIndex: 0,
			showDashboardPicker1YN: false,
			showDashboardPicker2YN: false,
			revenueVariableList1: null,
			expensesVariableList1: null,
			revenueVariableList2: null,
			expensesVariableList2: null,
			revenueVariableListDisplay1: null,
			expensesVariableListDisplay1: null,
			revenueVariableListDisplay2: null,
			expensesVariableListDisplay2: null,
		};

		this.getDashboardListJsonObjWithPermissions = this.getDashboardListJsonObjWithPermissions.bind(this);
		this.serverGetDashboardVariableList = this.serverGetDashboardVariableList.bind(this);
		this.serverGetDashboardValueMonthMulti = this.serverGetDashboardValueMonthMulti.bind(this);
		this.getValueFromDashboardVariableID = this.getValueFromDashboardVariableID.bind(this);
		this.getValueFromVariableName = this.getValueFromVariableName.bind(this);
		this.calcRevenueMonth = this.calcRevenueMonth.bind(this);
		this.calcExpensesMonth = this.calcExpensesMonth.bind(this);
		this.calcRevenueYear = this.calcRevenueYear.bind(this);
		this.calcExpensesYear = this.calcExpensesYear.bind(this);
		this.calcRevenueAndExpenses = this.calcRevenueAndExpenses.bind(this);
		this.getDashboardNameFromDashboardID = this.getDashboardNameFromDashboardID.bind(this);
		this.onClickYearMonth = this.onClickYearMonth.bind(this);
		this.closeDatePicker = this.closeDatePicker.bind(this);
		this.setSelectedMonth = this.setSelectedMonth.bind(this);
		this.setSelectedYear = this.setSelectedYear.bind(this);
		this.onClickDashboard = this.onClickDashboard.bind(this);
		this.closeDashboardPicker = this.closeDashboardPicker.bind(this);
		this.setSelectedDashboard = this.setSelectedDashboard.bind(this);		
		this.getTotalDaysFromRoomsJson = this.getTotalDaysFromRoomsJson.bind(this);
		this.getValueString = this.getValueString.bind(this);
		this.renderOneLine = this.renderOneLine.bind(this);		
		this.calcRoomsDivHousekeepingEmployees = this.calcRoomsDivHousekeepingEmployees.bind(this);
		this.calcPersonsDivBarEmployees = this.calcPersonsDivBarEmployees.bind(this);
		this.calcPersonsDivKitchenEmployees = this.calcPersonsDivKitchenEmployees.bind(this);
		this.calcCPOR = this.calcCPOR.bind(this);
		this.calcGOPPAR = this.calcGOPPAR.bind(this);
		this.calcTRevPAR = this.calcTRevPAR.bind(this);
		this.calcOccupancyRate = this.calcOccupancyRate.bind(this);
		this.renderData = this.renderData.bind(this);
		this.renderDatePicker = this.renderDatePicker.bind(this);
		this.renderDashboardName = this.renderDashboardName.bind(this);
		this.renderTop = this.renderTop.bind(this);
		this.renderBottom = this.renderBottom.bind(this);
	}
	
	async componentDidMount() {

		var dashboardListJsonObj = this.props.dashboardListJsonObj;
		var dashboardListJsonObjWithPermissions = this.getDashboardListJsonObjWithPermissions(dashboardListJsonObj);

		if (dashboardListJsonObjWithPermissions.length === 0) {
			return;
		}

		var dashboardID1 = dashboardListJsonObjWithPermissions[0].dashboardID;
		var dashboardID2 = dashboardID1;

		if (dashboardListJsonObj.length > 1) {
			dashboardID2 = dashboardListJsonObj[1].dashboardID;
		}
		
		var currentYear = 0;
		var currentMonth = 0;

		var currentDateTime = new Date();
		currentYear = currentDateTime.getFullYear();
		currentMonth = currentDateTime.getMonth() + 1;
		
		// temp
		//currentYear = 2023;
		//currentMonth = 7;

		var revenueVariableList1 = await this.serverGetDashboardVariableList(dashboardID1, "daily");
		var expensesVariableList1 = await this.serverGetDashboardVariableList(dashboardID1, "monthly");
		var revenueVariableList2 = await this.serverGetDashboardVariableList(dashboardID2, "daily");
		var expensesVariableList2 = await this.serverGetDashboardVariableList(dashboardID2, "monthly");

		this.setState({
			dashboardListJsonObjWithPermissions: dashboardListJsonObjWithPermissions,
			dashboardID1: dashboardID1,
			dashboardID2: dashboardID2,
			currentYear: currentYear,
			currentMonth: currentMonth,
			year1: currentYear,
			month1: currentMonth,
			year2: currentYear,
			month2: currentMonth,
			revenueVariableList1: revenueVariableList1,
			expensesVariableList1: expensesVariableList1,
			revenueVariableList2: revenueVariableList2,
			expensesVariableList2: expensesVariableList2,
			revenueVariableListDisplay1: null,
			expensesVariableListDisplay1: null,
			revenueVariableListDisplay2: null,
			expensesVariableListDisplay2: null,
		});

		await this.calcRevenueAndExpenses(1, dashboardID1, currentYear, currentMonth, revenueVariableList1, expensesVariableList1);
		await this.calcRevenueAndExpenses(2, dashboardID2, currentYear, currentMonth, revenueVariableList2, expensesVariableList2);
	}
	
	getDashboardListJsonObjWithPermissions(dashboardListJsonObj1) {

		var i;
		var itemObj;
		var dashboardUserType = 0;
		var dashboardListJsonObj2 = [];
		
		for(i=0; i<dashboardListJsonObj1.length; i++) {
			itemObj = dashboardListJsonObj1[i];
			dashboardUserType = itemObj.dashboardUserType;
			if ((dashboardUserType === 3) || (dashboardUserType === 102)) {
				dashboardListJsonObj2.push(itemObj);
			}
		}
		
		return dashboardListJsonObj2;
	}


	async serverGetDashboardVariableList(dashboardID, variableFrequency) {

		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/getdashboardvariablelist?dashboardid=" + dashboardID + "&variablefrequency=" + variableFrequency;
		
		const res = await fetch(url);
		const jsonObj = await res.json();
		
		var i;
		var itemObj;
		
		for(i=0; i<jsonObj.length; i++) {
			itemObj = jsonObj[i];
			itemObj.value = 0;
		}

		return jsonObj;
	}
	
	async serverGetDashboardValueMonthMulti(dashboardID, year, month) {

		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/getdashboardvaluemonthmulti?dashboardid=" + dashboardID;
		url = url + "&variablefrequency=monthly";
		url = url + "&year=" + year;
		url = url + "&month=" + month;
		
		const res = await fetch(url);
		var responseText = await res.text();

		var jsonObj = null;
		if (responseText !== "") {
			jsonObj = JSON.parse(responseText);
		}
		
		return jsonObj;
	}

	async calcRevenueMonth(dashboardID, year, month, revenueVariableList) {

		var calcHelpersObj = this.props.calcHelpersObj;
		var i;
		var itemObj;
		var variableName;
		var dashboardVariableID;
		var dashboardVariableIDArray = [];
		var rooms = 0;
		
		for(i=0; i<revenueVariableList.length; i++) {
			itemObj = revenueVariableList[i];
			variableName = itemObj.variableName;
			if (variableName !== "roomprice") {
				dashboardVariableID = itemObj.dashboardVariableID;
				dashboardVariableIDArray.push(dashboardVariableID);
			}
		}
		
		var jsonObj = await calcHelpersObj.serverGetDashboardMonthTotalMultiVariables(dashboardVariableIDArray, year, month);
		for(i=0; i<jsonObj.length; i++) {
			itemObj = jsonObj[i];
			dashboardVariableID = itemObj.dashboardVariableID;
			variableName = calcHelpersObj.getDashboardVariableNameFromVariableID(revenueVariableList, dashboardVariableID);
			itemObj.variableName = variableName;
			itemObj.value = itemObj.monthTotal;
			if (variableName === "rooms") {
				rooms = itemObj.monthTotal;
			}
		}
		
		var roomRevenue = 0;
		var totalRevenue = 0;
		var roomPrice = 0;
		
		// calc room revenue
		var currentYear = this.state.currentYear;
		var currentMonth = this.state.currentMonth;
		
		var jsonObj2 = await calcHelpersObj.calcRoomRevenueValueYear(revenueVariableList, year);
		roomRevenue = calcHelpersObj.getMonthValue(currentYear, currentMonth, year, month, "roomrevenue", jsonObj2);

		// calc total revenue
		var currencyYN;
		var value;

		for(i=0; i<jsonObj.length; i++) {
			itemObj = jsonObj[i];
			variableName = itemObj.variableName;
			value = itemObj.monthTotal;
			currencyYN = calcHelpersObj.isVariableCurrencyYN(variableName);
			if (variableName === "roomprice") {
				currencyYN = false;
			}
			if (currencyYN) {
				totalRevenue = totalRevenue + value;
			}
		}

		totalRevenue = totalRevenue + roomRevenue;
		
		if (rooms !== 0) {
			roomPrice = roomRevenue / rooms;
		}
		
		// calc total working days
		dashboardVariableID = calcHelpersObj.getDashboardVariableIDFromVariableName(jsonObj, "rooms");
		jsonObj2 = await calcHelpersObj.serverGetDashboardValueMonth(dashboardVariableID, year, month);
		var totalDays = this.getTotalDaysFromRoomsJson(year, jsonObj2);

		return [jsonObj, roomRevenue, totalRevenue, roomPrice, totalDays];
	}
	
	async calcExpensesMonth(dashboardID, year, month) {

		var calcHelpersObj = this.props.calcHelpersObj;
		var jsonObj = await this.serverGetDashboardValueMonthMulti(dashboardID, year, month);
		var totalExpenses = 0;
		var totalStaff = 0;
		
		var i;
		var itemObj;
		var variableName;
		var variableType;
		var currencyYN;
		var staffYN;
		var value;
		
		for(i=0; i<jsonObj.length; i++) {
			itemObj = jsonObj[i];
			variableName = itemObj.variableName;
			currencyYN = calcHelpersObj.isVariableCurrencyYN(variableName);
			variableType = itemObj.variableType;
			value = 0;
			if (variableType === "int") {
				value = itemObj.valueInt;
			}
			if (variableType === "float") {
				value = itemObj.valueFloat;
			}
			itemObj.value = value;
			if (currencyYN) {
				totalExpenses = totalExpenses + value;
			}
		}

		for(i=0; i<jsonObj.length; i++) {
			itemObj = jsonObj[i];
			variableName = itemObj.variableName;
			staffYN = calcHelpersObj.isVariableStaffYN(variableName);
			if (staffYN) {
				variableType = itemObj.variableType;
				value = 0;
				if (variableType === "int") {
					value = itemObj.valueInt;
				}
				if (variableType === "float") {
					value = itemObj.valueFloat;
				}
				totalStaff = totalStaff + value;
			}
		}

		return [jsonObj, totalExpenses, totalStaff];
	}
	
	async calcRevenueYear(dashboardID, year, revenueVariableList) {

		var calcHelpersObj = this.props.calcHelpersObj;
		var i;
		var itemObj;
		var variableName;
		var dashboardVariableID;
		var dashboardVariableIDArray = [];
		var rooms = 0;
		
		for(i=0; i<revenueVariableList.length; i++) {
			itemObj = revenueVariableList[i];
			variableName = itemObj.variableName;
			if (variableName !== "roomprice") {
				dashboardVariableID = itemObj.dashboardVariableID;
				dashboardVariableIDArray.push(dashboardVariableID);
			}
		}
		
		var jsonObj = await calcHelpersObj.serverGetDashboardYearTotalMultiVariables(dashboardVariableIDArray, year);
		for(i=0; i<jsonObj.length; i++) {
			itemObj = jsonObj[i];
			dashboardVariableID = itemObj.dashboardVariableID;
			variableName = calcHelpersObj.getDashboardVariableNameFromVariableID(revenueVariableList, dashboardVariableID);
			itemObj.variableName = variableName;
			itemObj.value = itemObj.yearTotal;
			if (variableName === "rooms") {
				rooms = itemObj.yearTotal;
			}
		}
		
		var roomRevenue = 0;
		var totalRevenue = 0;
		var roomPrice = 0;
		var value;
		
		// calc room revenue
		//var currentYear = this.state.currentYear;
		//var currentMonth = this.state.currentMonth;
		
		var jsonObj2 = await calcHelpersObj.calcRoomRevenueValueYear(revenueVariableList, year);
		for(i=0; i<jsonObj2.length; i++) {
			itemObj = jsonObj2[i];
			value = itemObj.valueInt;
			roomRevenue = roomRevenue + value;
		}

		// calc total revenue
		var currencyYN;

		for(i=0; i<jsonObj.length; i++) {
			itemObj = jsonObj[i];
			variableName = itemObj.variableName;
			value = itemObj.yearTotal;
			currencyYN = calcHelpersObj.isVariableCurrencyYN(variableName);
			if (variableName === "roomprice") {
				currencyYN = false;
			}
			if (currencyYN) {
				totalRevenue = totalRevenue + value;
			}
		}

		totalRevenue = totalRevenue + roomRevenue;
		
		if (rooms !== 0) {
			roomPrice = roomRevenue / rooms;
		}

		// calc total working days
		dashboardVariableID = calcHelpersObj.getDashboardVariableIDFromVariableName(jsonObj, "rooms");
		jsonObj2 = await calcHelpersObj.serverGetDashboardValueYear(dashboardVariableID, year);
		var totalDays = this.getTotalDaysFromRoomsJson(year, jsonObj2);
		
		return [jsonObj, roomRevenue, totalRevenue, roomPrice, totalDays];
	}
	
	async calcExpensesYear(dashboardID, year, expensesVariableList) {

		var calcHelpersObj = this.props.calcHelpersObj;
		var i;
		var itemObj;
		var variableName;
		var dashboardVariableID;
		var dashboardVariableIDArray = [];
		
		for(i=0; i<expensesVariableList.length; i++) {
			itemObj = expensesVariableList[i];
			variableName = itemObj.variableName;
			if (variableName !== "roomprice") {
				dashboardVariableID = itemObj.dashboardVariableID;
				dashboardVariableIDArray.push(dashboardVariableID);
			}
		}
		
		var jsonObj = await calcHelpersObj.serverGetDashboardYearTotalMultiVariables(dashboardVariableIDArray, year);
		for(i=0; i<jsonObj.length; i++) {
			itemObj = jsonObj[i];
			dashboardVariableID = itemObj.dashboardVariableID;
			variableName = calcHelpersObj.getDashboardVariableNameFromVariableID(expensesVariableList, dashboardVariableID);
			itemObj.variableName = variableName;
			itemObj.value = itemObj.yearTotal;
		}

		var totalExpenses = 0;
		var totalStaff = 0;

		var currencyYN;
		var staffYN;
		var value;
		
		for(i=0; i<jsonObj.length; i++) {
			itemObj = jsonObj[i];
			value = itemObj.value;
			variableName = itemObj.variableName;
			currencyYN = calcHelpersObj.isVariableCurrencyYN(variableName);
			if (currencyYN) {
				totalExpenses = totalExpenses + value;
			}
			staffYN = calcHelpersObj.isVariableStaffYN(variableName);
			if (staffYN) {
				totalStaff = totalStaff + value;
			}
		}

		return [jsonObj, totalExpenses, totalStaff];
	}
	
	getValueFromDashboardVariableID(jsonObj, dashboardVariableID1) {
		
		if (jsonObj === null) {
			return 0;
		}

		dashboardVariableID1 = parseInt(dashboardVariableID1);

		var i;
		var itemObj;
		var dashboardVariableID2;
		var value;

		for(i=0; i<jsonObj.length; i++) {
			itemObj = jsonObj[i];
			dashboardVariableID2 = parseInt(itemObj.dashboardVariableID);
			if (dashboardVariableID1 === dashboardVariableID2) {
				value = itemObj.value;
				return value;
			}
		}
		
		return 0;
	}
	
	getValueFromVariableName(jsonObj, variableName1) {

		if (jsonObj === null) {
			return 0;
		}

		var i;
		var itemObj;
		var variableName2;
		var value;

		for(i=0; i<jsonObj.length; i++) {
			itemObj = jsonObj[i];
			variableName2 = itemObj.variableName;
			if (variableName1 === variableName2) {
				value = itemObj.value;
				return value;
			}
		}
		
		return 0;
	}

	async calcRevenueAndExpenses(dashboardIndex, dashboardID, year, month, revenueVariableList, expensesVariableList) {
		
		if (dashboardIndex === 1) {
			this.setState({
				revenueVariableListDisplay1: null,
				expensesVariableListDisplay1: null,
			});
		} else {
			this.setState({
				revenueVariableListDisplay2: null,
				expensesVariableListDisplay2: null,
			});
		}		

		var revenueJsonObj = null;
		var expensesJsonObj = null;
		var roomRevenue = 0;
		var totalRevenue = 0;
		var roomPrice = 0;
		var totalExpenses = 0;
		var totalStaff = 0;
		var revenueVariableListCopy = [];
		var expensesVariableListCopy = [];
		var i;
		var itemObj;
		
		for(i=0; i<revenueVariableList.length; i++) {
			itemObj = revenueVariableList[i];
			revenueVariableListCopy.push(itemObj);
		}
		
		for(i=0; i<expensesVariableList.length; i++) {
			itemObj = expensesVariableList[i];
			expensesVariableListCopy.push(itemObj);
		}
		
		var totalDays = 0;

		if (month === 0) {
			[revenueJsonObj, roomRevenue, totalRevenue, roomPrice, totalDays] = await this.calcRevenueYear(dashboardID, year, revenueVariableListCopy);
			[expensesJsonObj, totalExpenses, totalStaff] = await this.calcExpensesYear(dashboardID, year, expensesVariableListCopy);
		} else {
			[revenueJsonObj, roomRevenue, totalRevenue, roomPrice, totalDays] = await this.calcRevenueMonth(dashboardID, year, month, revenueVariableListCopy);
			[expensesJsonObj, totalExpenses, totalStaff] = await this.calcExpensesMonth(dashboardID, year, month);
		}
		
		var dashboardVariableID;
		var variableName;
		var value;

		// revenue

		for(i=0; i<revenueVariableListCopy.length; i++) {
			itemObj = revenueVariableListCopy[i];
			variableName = itemObj.variableName;
			dashboardVariableID = itemObj.dashboardVariableID;
			value = this.getValueFromDashboardVariableID(revenueJsonObj, dashboardVariableID);
			itemObj.value = value;
			if (variableName === "roomprice") {
				itemObj.value = roomPrice;
			}
		}

		itemObj = {};
		itemObj.dashboardVariableID = 0;
		itemObj.variableName = "totalrevenue";
		itemObj.value = totalRevenue;
		revenueVariableListCopy.push(itemObj);

		itemObj = {};
		itemObj.dashboardVariableID = 0;
		itemObj.variableName = "roomrevenue";
		itemObj.value = roomRevenue;
		revenueVariableListCopy.push(itemObj);

		// expenses

		for(i=0; i<expensesVariableListCopy.length; i++) {
			itemObj = expensesVariableListCopy[i];
			dashboardVariableID = itemObj.dashboardVariableID;
			value = this.getValueFromDashboardVariableID(expensesJsonObj, dashboardVariableID);
			itemObj.value = value;
		}

		itemObj = {};
		itemObj.dashboardVariableID = 0;
		itemObj.variableName = "totalexpenses";
		itemObj.value = totalExpenses;
		expensesVariableListCopy.push(itemObj);

		itemObj = {};
		itemObj.dashboardVariableID = 0;
		itemObj.variableName = "totalstaff";
		itemObj.value = totalStaff;
		expensesVariableListCopy.push(itemObj);
		
		var ebitda = totalRevenue - totalExpenses;
		if (totalExpenses === 0) {
			ebitda = 0;
		}

		itemObj = {};
		itemObj.dashboardVariableID = 0;
		itemObj.variableName = "ebitda";
		itemObj.value = ebitda;
		expensesVariableListCopy.push(itemObj);

		if (dashboardIndex === 1) {
			this.setState({
				revenueVariableListDisplay1: revenueVariableListCopy,
				expensesVariableListDisplay1: expensesVariableListCopy,
				totalDays1: totalDays,
			});
		} else {
			this.setState({
				revenueVariableListDisplay2: revenueVariableListCopy,
				expensesVariableListDisplay2: expensesVariableListCopy,
				totalDays2: totalDays,
			});
		}		
	}

	getDashboardNameFromDashboardID(dashboardID1) {

		var i;
		var itemObj;
		var dashboardID2;
		var dashboardName = "";
		var dashboardListJsonObjWithPermissions = this.state.dashboardListJsonObjWithPermissions;
		
		for(i=0; i<dashboardListJsonObjWithPermissions.length; i++) {
			itemObj = dashboardListJsonObjWithPermissions[i];
			dashboardID2 = itemObj.dashboardID;
			if (dashboardID1 === dashboardID2) {
				dashboardName = itemObj.dashboardName;
				return dashboardName;
			}
		}
		
		return "";
	}
	
	onClickYearMonth(datePickerIndex) {

		var showDatePickerYN;

		if (datePickerIndex === 1) {
			showDatePickerYN = this.state.showDatePicker1YN;
			showDatePickerYN = !showDatePickerYN;
			this.setState({
				datePickerIndex: datePickerIndex,
				showDatePicker1YN: showDatePickerYN,
			});
		} else {
			showDatePickerYN = this.state.showDatePicker2YN;
			showDatePickerYN = !showDatePickerYN;
			this.setState({
				datePickerIndex: datePickerIndex,
				showDatePicker2YN: showDatePickerYN,
			});
		}
	}

	closeDatePicker() {
		this.setState({
			showDatePicker1YN: false,
			showDatePicker2YN: false,
		});
	}

	async setSelectedMonth(month) {

		var datePickerIndex = this.state.datePickerIndex;
		var year1 = this.state.year1;
		var year2 = this.state.year2;
		var month1 = this.state.month1;
		var month2 = this.state.month2;

		if (datePickerIndex === 1) {
			if (month1 === month) {
				return;
			}
			year2 = year1;
			this.setState({
				month1: month,
				month2: month,
				year2: year2,
			});
			await this.calcRevenueAndExpenses(1, this.state.dashboardID1, year1, month, this.state.revenueVariableList1, this.state.expensesVariableList1);
			await this.calcRevenueAndExpenses(2, this.state.dashboardID2, year2, month, this.state.revenueVariableList2, this.state.expensesVariableList2);
		} else {
			if (month2 === month) {
				return;
			}
			if (month === 0) {
				if (month1 > 0) {
					month1 = 0;
				}
			} else {
				if (month1 === 0) {
					month1 = month;
				}
			}
			this.setState({
				month1: month1,
				month2: month,
			});
			await this.calcRevenueAndExpenses(1, this.state.dashboardID1, year1, month1, this.state.revenueVariableList1, this.state.expensesVariableList1);
			await this.calcRevenueAndExpenses(2, this.state.dashboardID2, year2, month, this.state.revenueVariableList2, this.state.expensesVariableList2);
		}

	}

	async setSelectedYear(year) {

		var datePickerIndex = this.state.datePickerIndex;
		var year1 = this.state.year1;
		var year2 = this.state.year2;
		var month1 = this.state.month1;
		var month2 = this.state.month2;

		if (datePickerIndex === 1) {
			if (year1 === year) {
				return;
			}
			this.setState({
				year1: year,
				year2: year,
				month2: month1,
			});
			await this.calcRevenueAndExpenses(1, this.state.dashboardID1, year, month1, this.state.revenueVariableList1, this.state.expensesVariableList1);
			await this.calcRevenueAndExpenses(2, this.state.dashboardID2, year, month2, this.state.revenueVariableList2, this.state.expensesVariableList2);
		} else {
			if (year2 === year) {
				return;
			}
			this.setState({
				year2: year,
			});
			await this.calcRevenueAndExpenses(2, this.state.dashboardID2, year, month2, this.state.revenueVariableList2, this.state.expensesVariableList2);
		}
	}
	
	onClickDashboard(dashboardPickerIndex) {

		var showDashboardPickerYN;

		if (dashboardPickerIndex === 1) {
			showDashboardPickerYN = this.state.showDashboardPicker1YN;
			showDashboardPickerYN = !showDashboardPickerYN;
			this.setState({
				dashboardPickerIndex: dashboardPickerIndex,
				showDashboardPicker1YN: showDashboardPickerYN,
			});
		} else {
			showDashboardPickerYN = this.state.showDashboardPicker2YN;
			showDashboardPickerYN = !showDashboardPickerYN;
			this.setState({
				dashboardPickerIndex: dashboardPickerIndex,
				showDashboardPicker2YN: showDashboardPickerYN,
			});
		}
	}
	
	closeDashboardPicker() {
		this.setState({
			showDashboardPicker1YN: false,
			showDashboardPicker2YN: false,
		});
	}
	
	async setSelectedDashboard(dashboardID) {

		var dashboardPickerIndex = this.state.dashboardPickerIndex;
		var dashboardID1 = this.state.dashboardID1;
		var dashboardID2 = this.state.dashboardID2;

		if (dashboardPickerIndex === 1) {
			if (dashboardID1 === dashboardID) {
				return;
			}
			var revenueVariableList1 = await this.serverGetDashboardVariableList(dashboardID, "daily");
			var expensesVariableList1 = await this.serverGetDashboardVariableList(dashboardID, "monthly");
			this.setState({
				dashboardID1: dashboardID,
				revenueVariableList1: revenueVariableList1,
				expensesVariableList1: expensesVariableList1,
			});
			await this.calcRevenueAndExpenses(1, dashboardID, this.state.year1, this.state.month1, revenueVariableList1, expensesVariableList1);
		} else {
			if (dashboardID2 === dashboardID) {
				return;
			}
			var revenueVariableList2 = await this.serverGetDashboardVariableList(dashboardID, "daily");
			var expensesVariableList2 = await this.serverGetDashboardVariableList(dashboardID, "monthly");
			this.setState({
				dashboardID2: dashboardID,
				revenueVariableList2: revenueVariableList2,
				expensesVariableList2: expensesVariableList2,
			});
			await this.calcRevenueAndExpenses(2, dashboardID, this.state.year2, this.state.month2, revenueVariableList2, expensesVariableList2);
		}
	}

	renderDatePicker(datePickerIndex, year, month) {

		var yearMonthString = year + " " + this.props.getMonthString(month);
		
		var showDatePickerYN = false;
		
		if (datePickerIndex === 1) {
			showDatePickerYN = this.state.showDatePicker1YN;
		} else {
			showDatePickerYN = this.state.showDatePicker2YN;
		}

		return (
			<div className="CompareDatePickerContainer1">
				<div 
					className="CompareDatePickerContainer2" 
					onClick={() => this.onClickYearMonth(datePickerIndex)}
				>
					{yearMonthString}
					{showDatePickerYN && (
						<YearMonthPopupMenu
							key={datePickerIndex}
							fullYearYN={true}
							selectedYear={year}
							selectedMonth={month}
							dashboardAppUserType={this.props.dashboardAppUserType}
							getMonthString={this.props.getMonthString}
							setSelectedMonth={this.setSelectedMonth}
							setSelectedYear={this.setSelectedYear}
							closePopupMenu={this.closeDatePicker}
						/>
					)}
				</div>
			</div>
		);
	}

	renderDashboardName(dashboardPickerIndex, dashboardName) {
		
		var showDashboardPickerYN = false;
		
		if (dashboardPickerIndex === 1) {
			showDashboardPickerYN = this.state.showDashboardPicker1YN;
		} else {
			showDashboardPickerYN = this.state.showDashboardPicker2YN;
		}
		
		return (
			<div className="CompareDashboardPickerContainer1">
				<div 
					className="CompareDashboardPickerContainer2" 
					onClick={() => this.onClickDashboard(dashboardPickerIndex)}
				>
					{dashboardName}
					{showDashboardPickerYN && (
						<SelectDashboardPopupMenu
							key={dashboardPickerIndex}
							dashboardListJsonObj={this.state.dashboardListJsonObjWithPermissions}
							dashboardAppUserType={this.props.dashboardAppUserType}
							setSelectedDashboard={this.setSelectedDashboard}
							closePopupMenu={this.closeDashboardPicker}
						/>
					)}
				</div>
			</div>
		);
	}

	renderTop() {

		var dashboardID1 = this.state.dashboardID1;
		var dashboardID2 = this.state.dashboardID2;
		var dashboardName1 = this.getDashboardNameFromDashboardID(dashboardID1);
		var dashboardName2 = this.getDashboardNameFromDashboardID(dashboardID2);
		
		return (
			<tr>
				<td className="CompareVariableHeader"></td>
				<td className="CompareValue1Header">
					{this.renderDatePicker(1, this.state.year1, this.state.month1)}
					{this.renderDashboardName(1, dashboardName1)}
				</td>
				<td className="CompareValue2Header">
					{this.renderDatePicker(2, this.state.year2, this.state.month2)}
					{this.renderDashboardName(2, dashboardName2)}
				</td>
			</tr>
		);
	}
	
	getTotalDaysFromRoomsJson(year, jsonObj) {

		if (jsonObj === null) {
			return 0;
		}
		
		if (jsonObj.length === 0) {
			return 0;
		}
		
		var i;
		var itemObj;
		var month;
		var day;
		var monthMin;
		var dayMin;
		var monthMax;
		var dayMax;
		var value;
		var totalDays = 0;
		
		itemObj = jsonObj[0];
		month = itemObj.month;
		day = itemObj.day;
		monthMin = month;
		dayMin = day;
		monthMax = month;
		dayMax = day;

		for(i=0; i<jsonObj.length; i++) {

			itemObj = jsonObj[i];
			month = itemObj.month;
			day = itemObj.day;

			value = itemObj.valueInt;
			if (value > 0) {
				if (month > monthMax) {
					monthMax = month;
					dayMax = day;
				} else {
					if (month === monthMax) {
						if (day > dayMax) {
							dayMax = day;
						}
					}
				}
				if (month < monthMin) {
					monthMin = month;
					dayMin = day;
				} else {
					if (month === monthMin) {
						if (day < dayMin) {
							dayMin = day;
						}
					}
				}
			}
		}
		
		var date1 = new Date(year, monthMin, dayMin);
		var date2 = new Date(year, monthMax, dayMax);
		 
		// calculate the time difference of two dates
		var diff1 = date2.getTime() - date1.getTime();
		 
		//  calculate num of days between two dates
		totalDays = Math.round(diff1 / (1000 * 3600 * 24)) + 1;

		return totalDays;
	}

	getValueString(dashboardID, year, month, totalDays, variableName, value) {
		
		var calcHelpersObj = this.props.calcHelpersObj;
		var value2 = 0;
		
		if (value === 0) {
			return "";
		}

		if (variableName === "rooms") {
			if (totalDays > 0) {
				value2 = value/totalDays;
			}
			return Math.round(value2) + " (" + value + ")";
		}
		
		if (variableName === "persons") {
			if (totalDays > 0) {
				value2 = value/totalDays;
			}
			return Math.round(value2) + " (" + value + ")";
		}
		
		if (variableName === "parking") {
			if (totalDays > 0) {
				value2 = value/totalDays;
			}
			return Math.round(value2) + " (" + value + ")";
		}
		
		if (variableName === "faultyrooms") {
			if (totalDays > 0) {
				value2 = value/totalDays;
			}
			return Math.round(value2) + " (" + value + ")";
		}
		
		if (variableName === "totalrooms") {
			var totalRooms = calcHelpersObj.getTotalRooms(dashboardID);
			return totalRooms;
		}
		
		if (variableName === "roomsdivhousekeepingemployees") {
			if (totalDays > 0) {
				value2 = value/totalDays;
			}
			if (month === 0) {
				value2 = value/30.5;
			}
			return Math.round(100 * value2) / 100 + " (" + Math.round(value) + ")";
		}
				
		if (variableName === "personsdivbarempoyees") {
			if (totalDays > 0) {
				value2 = value/totalDays;
			}
			if (month === 0) {
				value2 = value/30.5;
			}
			return Math.round(100 * value2) / 100 + " (" + Math.round(value) + ")";
		}
				
		if (variableName === "personsdivkitchenempoyees") {
			if (totalDays > 0) {
				value2 = value/totalDays;
			}
			if (month === 0) {
				value2 = value/30.5;
			}
			return Math.round(100 * value2) / 100 + " (" + Math.round(value) + ")";
		}
				
		if (variableName === "goppar") {
			if (totalDays > 0) {
				value2 = value/totalDays;
			}
			return this.props.getEURString(value2, 0) + " (" + this.props.getEURString(value, 0) + ")";
		}

		if (variableName === "trevpar") {
			if (totalDays > 0) {
				value2 = value/totalDays;
			}
			return this.props.getEURString(value2, 0) + " (" + this.props.getEURString(value, 0) + ")";
		}
		
		var staffYN = calcHelpersObj.isVariableStaffYN(variableName);
		if (month === 0) {
			if (variableName === "totalstaff") {
				staffYN = true;
			}
			if (staffYN) {
				if (totalDays > 0) {
					var monthCount = totalDays / 30.5;
					monthCount = Math.round(monthCount);
					value2 = Math.round(value/monthCount);
				}
				return value2 + " (" + Math.round(value) + ")";
			}
		}

		return "";
	}
	
	renderOneLine(index, variableName, value1, value2) {
		
		var calcHelpersObj = this.props.calcHelpersObj;
		var variableString = "";
		var valueString1 = "";
		var valueString2 = "";
		var currencyYN = false;
		var decimalPlaces = 0;
		var dashboardID1 = this.state.dashboardID1;
		var dashboardID2 = this.state.dashboardID2;
		var year1 = this.state.year1;
		var year2 = this.state.year2;
		var month1 = this.state.month1;
		var month2 = this.state.month2;
		var totalDays1 = this.state.totalDays1;
		var totalDays2 = this.state.totalDays2;
		
		if (variableName === "") {
			variableString = "";
			return (
				<tr key={index}>
					<td>&nbsp;</td>
					<td></td>
					<td></td>
				</tr>
			);
		} else {
			variableString = this.props.getLang(DashboardLang, variableName);
			currencyYN = calcHelpersObj.isVariableCurrencyYN(variableName);
		}


		if (value1 !== 0) {
			valueString1 = this.getValueString(dashboardID1, year1, month1, totalDays1, variableName, value1);
			if (valueString1 === "") {
				valueString1 = value1;
				if (currencyYN) {
					valueString1 = this.props.getEURString(value1, decimalPlaces);
				}
			}
		}
		
		if (value2 !== 0) {
			valueString2 = this.getValueString(dashboardID2, year2, month2, totalDays2, variableName, value2);
			if (valueString2 === "") {
				valueString2 = value2;
				if (currencyYN) {
					valueString2 = this.props.getEURString(value2, decimalPlaces);
				}
			}
		}
		
		var className = "CompareValueLine1";
		if (index % 2 === 0) {
			className = "CompareValueLine2";
		}
		
		return (
			<tr className={className} key={index}>
				<td>{variableString}</td>
				<td>{valueString1}</td>
				<td>{valueString2}</td>
			</tr>
		);
	}
	
	calcRoomsDivHousekeepingEmployees(revenueVariableList, expensesVariableList) {

		var rooms = this.getValueFromVariableName(revenueVariableList, "rooms");
		var housekeepingEmployees = this.getValueFromVariableName(expensesVariableList, "housekeepingemployees");
		
		var value = 0;
		if (housekeepingEmployees > 0) {
			value = rooms / housekeepingEmployees;
		}
		
		return value;
	}
	
	calcPersonsDivBarEmployees(revenueVariableList, expensesVariableList) {

		var persons = this.getValueFromVariableName(revenueVariableList, "persons");
		var barEmployees = this.getValueFromVariableName(expensesVariableList, "baremployees");
		
		var value = 0;
		if (barEmployees > 0) {
			value = persons / barEmployees;
		}
		
		return value;
	}
	
	calcPersonsDivKitchenEmployees(revenueVariableList, expensesVariableList) {

		var persons = this.getValueFromVariableName(revenueVariableList, "persons");
		var kitchenEmployees = this.getValueFromVariableName(expensesVariableList, "kitchenemployees");
		
		var value = 0;
		if (kitchenEmployees > 0) {
			value = persons / kitchenEmployees;
		}
		
		return value;
	}
	
	calcCPOR(revenueVariableList, expensesVariableList) {

		var rooms = this.getValueFromVariableName(revenueVariableList, "rooms");
		var totalExpenses = this.getValueFromVariableName(expensesVariableList, "totalexpenses");
		
		var value = 0;
		if (rooms > 0) {
			value = totalExpenses / rooms;
		}
		
		return value;
	}
	
	calcGOPPAR(dashboardID, revenueVariableList, expensesVariableList) {

		var calcHelpersObj = this.props.calcHelpersObj;
		var totalRooms = calcHelpersObj.getTotalRooms(dashboardID);

		var ebitda = this.getValueFromVariableName(expensesVariableList, "ebitda");
		
		var value = 0;
		if (totalRooms > 0) {
			value = ebitda / totalRooms;
		}
		
		return value;
	}
	
	calcTRevPAR(dashboardID, revenueVariableList, expensesVariableList) {

		var calcHelpersObj = this.props.calcHelpersObj;
		var totalRooms = calcHelpersObj.getTotalRooms(dashboardID);

		var totalRevenue = this.getValueFromVariableName(revenueVariableList, "totalrevenue");
		
		var value = 0;
		if (totalRooms > 0) {
			value = totalRevenue / totalRooms;
		}
		
		return value;
	}
	
	calcOccupancyRate(dashboardID, revenueVariableList, expensesVariableList, totalDays) {

		var calcHelpersObj = this.props.calcHelpersObj;
		var totalRooms = calcHelpersObj.getTotalRooms(dashboardID);

		var rooms = this.getValueFromVariableName(revenueVariableList, "rooms");
		
		var value = 0;
		var value2 = 0;
		if (totalRooms > 0) {
			if (totalDays > 0) {
				value2 = totalRooms * totalDays;
				value = rooms / value2;
			}
			value = Math.round(100*value);
		}
		
		return value;
	}
	
	renderData() {
		
		/*if (this.state.month1 === 0) {
			return(<div>Full year / Cijela godina - not implemented yet</div>);
		}*/

		var dashboardID1 = this.state.dashboardID1;
		var dashboardID2 = this.state.dashboardID2;
		var revenueVariableListDisplay1 = this.state.revenueVariableListDisplay1;
		var expensesVariableListDisplay1 = this.state.expensesVariableListDisplay1;
		var revenueVariableListDisplay2 = this.state.revenueVariableListDisplay2;
		var expensesVariableListDisplay2 = this.state.expensesVariableListDisplay2;

		if ((revenueVariableListDisplay1 === null) || (expensesVariableListDisplay1 === null) || (revenueVariableListDisplay2 === null) || (expensesVariableListDisplay2 === null)) {
			return null;
		}
		
		var i;
		var itemObj;
		var variableName;
		var revenueVariableArray = [];
		var expensesVariableArray = [];
		
		for(i=0; i<revenueVariableListDisplay1.length; i++) {
			itemObj = revenueVariableListDisplay1[i];
			variableName = itemObj.variableName;
			revenueVariableArray.push(variableName);
		}

		for(i=0; i<revenueVariableListDisplay2.length; i++) {
			itemObj = revenueVariableListDisplay2[i];
			variableName = itemObj.variableName;
			if (!revenueVariableArray.includes(variableName)) {
				revenueVariableArray.push(variableName);
			}
		}

		for(i=0; i<expensesVariableListDisplay1.length; i++) {
			itemObj = expensesVariableListDisplay1[i];
			variableName = itemObj.variableName;
			expensesVariableArray.push(variableName);
		}

		for(i=0; i<expensesVariableListDisplay2.length; i++) {
			itemObj = expensesVariableListDisplay2[i];
			variableName = itemObj.variableName;
			if (!expensesVariableArray.includes(variableName)) {
				expensesVariableArray.push(variableName);
			}
		}

		var totalDays1 = this.state.totalDays1;
		var totalDays2 = this.state.totalDays2;

		var index = 0;
		var value1;
		var value2;
		var jsxCode;
		var jsxArray = [];
		
		variableName = "totalrevenue";
		value1 = this.getValueFromVariableName(revenueVariableListDisplay1, variableName);
		value2 = this.getValueFromVariableName(revenueVariableListDisplay2, variableName);
		jsxCode = this.renderOneLine(index, variableName, value1, value2);
		jsxArray.push(jsxCode);
		index ++;

		variableName = "totalexpenses";
		value1 = this.getValueFromVariableName(expensesVariableListDisplay1, variableName);
		value2 = this.getValueFromVariableName(expensesVariableListDisplay2, variableName);
		jsxCode = this.renderOneLine(index, variableName, value1, value2);
		jsxArray.push(jsxCode);
		index ++;

		variableName = "ebitda";
		value1 = this.getValueFromVariableName(expensesVariableListDisplay1, variableName);
		value2 = this.getValueFromVariableName(expensesVariableListDisplay2, variableName);
		jsxCode = this.renderOneLine(index, variableName, value1, value2);
		jsxArray.push(jsxCode);
		index ++;
		
		variableName = "totalstaff";
		value1 = this.getValueFromVariableName(expensesVariableListDisplay1, variableName);
		value2 = this.getValueFromVariableName(expensesVariableListDisplay2, variableName);
		jsxCode = this.renderOneLine(index, variableName, value1, value2);
		jsxArray.push(jsxCode);
		index ++;

		variableName = "roomsdivhousekeepingemployees";
		value1 = this.calcRoomsDivHousekeepingEmployees(revenueVariableListDisplay1, expensesVariableListDisplay1);
		value2 = this.calcRoomsDivHousekeepingEmployees(revenueVariableListDisplay2, expensesVariableListDisplay2);
		jsxCode = this.renderOneLine(index, variableName, value1, value2);
		jsxArray.push(jsxCode);
		index ++;

		variableName = "personsdivbarempoyees";
		value1 = this.calcPersonsDivBarEmployees(revenueVariableListDisplay1, expensesVariableListDisplay1);
		value2 = this.calcPersonsDivBarEmployees(revenueVariableListDisplay2, expensesVariableListDisplay2);
		jsxCode = this.renderOneLine(index, variableName, value1, value2);
		jsxArray.push(jsxCode);
		index ++;
		
		variableName = "personsdivkitchenempoyees";
		value1 = this.calcPersonsDivKitchenEmployees(revenueVariableListDisplay1, expensesVariableListDisplay1);
		value2 = this.calcPersonsDivKitchenEmployees(revenueVariableListDisplay2, expensesVariableListDisplay2);
		jsxCode = this.renderOneLine(index, variableName, value1, value2);
		jsxArray.push(jsxCode);
		index ++;		

		variableName = "cpor";
		value1 = this.calcCPOR(revenueVariableListDisplay1, expensesVariableListDisplay1);
		value2 = this.calcCPOR(revenueVariableListDisplay2, expensesVariableListDisplay2);
		jsxCode = this.renderOneLine(index, variableName, value1, value2);
		jsxArray.push(jsxCode);
		index ++;		

		variableName = "goppar";
		value1 = this.calcGOPPAR(dashboardID1, revenueVariableListDisplay1, expensesVariableListDisplay1);
		value2 = this.calcGOPPAR(dashboardID2, revenueVariableListDisplay2, expensesVariableListDisplay2);
		jsxCode = this.renderOneLine(index, variableName, value1, value2);
		jsxArray.push(jsxCode);
		index ++;		

		variableName = "trevpar";
		value1 = this.calcTRevPAR(dashboardID1, revenueVariableListDisplay1, expensesVariableListDisplay1);
		value2 = this.calcTRevPAR(dashboardID2, revenueVariableListDisplay2, expensesVariableListDisplay2);
		jsxCode = this.renderOneLine(index, variableName, value1, value2);
		jsxArray.push(jsxCode);
		index ++;		

		variableName = "occupancyrate";
		value1 = this.calcOccupancyRate(dashboardID1, revenueVariableListDisplay1, expensesVariableListDisplay1, totalDays1);
		value2 = this.calcOccupancyRate(dashboardID2, revenueVariableListDisplay2, expensesVariableListDisplay2, totalDays2);
		jsxCode = this.renderOneLine(index, variableName, value1, value2);
		jsxArray.push(jsxCode);
		index ++;		
		
		jsxCode = this.renderOneLine(index, "totalworkingdays", totalDays1, totalDays2);
		jsxArray.push(jsxCode);
		index ++;		

		jsxCode = this.renderOneLine(index, "", 0, 0);
		jsxArray.push(jsxCode);
		index ++;
		
		jsxCode = this.renderOneLine(index, "", 0, 0);
		jsxArray.push(jsxCode);
		index ++;
		
		for(i=0; i<revenueVariableArray.length; i++) {
			variableName = revenueVariableArray[i];
			if (variableName !== "totalrevenue") {
				value1 = this.getValueFromVariableName(revenueVariableListDisplay1, variableName);
				value2 = this.getValueFromVariableName(revenueVariableListDisplay2, variableName);
				jsxCode = this.renderOneLine(index, variableName, value1, value2);
				jsxArray.push(jsxCode);
				index ++;
			}
		}
		
		jsxCode = this.renderOneLine(index, "", 0, 0);
		jsxArray.push(jsxCode);
		index ++;
		
		jsxCode = this.renderOneLine(index, "", 0, 0);
		jsxArray.push(jsxCode);
		index ++;
		
		for(i=0; i<expensesVariableArray.length; i++) {
			variableName = expensesVariableArray[i];
			if ((variableName !== "totalexpenses") && (variableName !== "ebitda")  && (variableName !== "totalstaff")) {
				value1 = this.getValueFromVariableName(expensesVariableListDisplay1, variableName);
				value2 = this.getValueFromVariableName(expensesVariableListDisplay2, variableName);
				jsxCode = this.renderOneLine(index, variableName, value1, value2);
				jsxArray.push(jsxCode);
				index ++;
			}
		}
		
		return jsxArray;
	}
	
	renderBottom() {
		return (
			<div>
				<div>EBITDA - Earnings before interest, taxes, depeciation and amortization / Zarada prije poreza i amortizacije</div>
				<div>CPOR - Cost per occupied room / Trošak po zauzetoj sobi</div>
				<div>GOPPAR - Gross operating profit per available room / Zarada djeljeno ukupni broj soba</div>
				<div>TRevPAR - Total revenue per available room / Ukupni prihod djeljeno ukupni broj soba</div>
				<div style={{height: "10px"}}></div>
				<a
					className="CompareURL" 
					href="https://webflow.canarytechnologies.com/hotel-terminology"
					target="_blank"
					rel="noreferrer"
				>
					https://webflow.canarytechnologies.com/hotel-terminology
				</a>
			</div>
		);
	}
	
	render() {

		var dashboardListJsonObjWithPermissions = this.state.dashboardListJsonObjWithPermissions;
		if (dashboardListJsonObjWithPermissions.length === 0) {
			return;
		}

		return (
			<div>
				<div style={{height: "10px"}}></div>
				<table>
					<tbody>
						{this.renderTop()}
						<tr>
							<td className="CompareVariable">&nbsp;</td>
						</tr>
						{this.renderData()}
					</tbody>
				</table>
				<div style={{height: "30px"}}></div>
				<hr/>
				<div style={{height: "20px"}}></div>
				{this.renderBottom()}
				<div style={{height: "50px"}}></div>
			</div>
		);
	}
}

export default function CompareFunction(props) {
	const navigate = useNavigate();
	return <Compare {...props} navigate={navigate} />;
}
