import React  from 'react';
import './App.css';
import './DashboardMain.css';
import DashboardLang from './DashboardLang.json';

class DashboardMainExpenses extends React.Component {

	constructor(props) {

		super(props);

		this.state = {
			dashboardVariableListMonthlyJsonObj: null,
			selectedYear: 0,
			selectedMonth: 0,
		};

		this.serverGetDashboardVariableListMonthly = this.serverGetDashboardVariableListMonthly.bind(this);
		this.findItemFromDashboardVariableID = this.findItemFromDashboardVariableID.bind(this);
		this.serverGetDashboardValueMonthMulti = this.serverGetDashboardValueMonthMulti.bind(this);
		this.serverGetDashboardDataForOneMonth = this.serverGetDashboardDataForOneMonth.bind(this);
		this.createItemObjForTotalExpenses = this.createItemObjForTotalExpenses.bind(this);
		this.createItemObjForEBITDA = this.createItemObjForEBITDA.bind(this);
		this.createItemObjForTotalStaff = this.createItemObjForTotalStaff.bind(this);
		this.createItemObjForTotalSalary = this.createItemObjForTotalSalary.bind(this);
		this.renderOneBoxNumber = this.renderOneBoxNumber.bind(this);
		this.renderOneBoxText = this.renderOneBoxText.bind(this);		
		this.setBoxRenderYN = this.setBoxRenderYN.bind(this);
		this.renderAllBoxes = this.renderAllBoxes.bind(this);
	}
	
	componentDidMount() {

		var dashboardID = this.props.dashboardID;
		var selectedYear = this.props.selectedYear;
		var selectedMonth = this.props.selectedMonth;
		this.setState({
			selectedYear: selectedYear,
			selectedMonth: selectedMonth,
		});

		this.serverGetDashboardVariableListMonthly(dashboardID, selectedYear, selectedMonth);
	}

	componentDidUpdate() {

		var dashboardID = this.props.dashboardID;
		var selectedYear1 = this.state.selectedYear;
		var selectedMonth1 = this.state.selectedMonth;
		var selectedYear2 = this.props.selectedYear;
		var selectedMonth2 = this.props.selectedMonth;
		
		if ((selectedYear1 === selectedYear2) && (selectedMonth1 === selectedMonth2)) {
			return;
		}

		this.setState({
			selectedYear: selectedYear2,
			selectedMonth: selectedMonth2,
		});

		this.serverGetDashboardVariableListMonthly(dashboardID, selectedYear2, selectedMonth2);
	}

	async serverGetDashboardVariableListMonthly(dashboardID, dateYear, dateMonth) {

		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/getdashboardvariablelist?dashboardid=" + dashboardID + "&variablefrequency=monthly";
		
		const res = await fetch(url);
		const dashboardVariableListMonthlyJsonObj = await res.json();
		
		var i;
		var itemObj;
		
		for(i=0; i<dashboardVariableListMonthlyJsonObj.length; i++) {
			itemObj = dashboardVariableListMonthlyJsonObj[i];
		}

		this.serverGetDashboardDataForOneMonth(dashboardVariableListMonthlyJsonObj, dateYear, dateMonth);
	}
	
	async serverGetDashboardValueMonthMulti(dashboardID, year, month) {

		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/getdashboardvaluemonthmulti?dashboardid=" + dashboardID;
		url = url + "&variablefrequency=monthly";
		url = url + "&year=" + year;
		url = url + "&month=" + month;
		
		const res = await fetch(url);
		var responseText = await res.text();

		var jsonObj = null;
		if (responseText !== "") {
			jsonObj = JSON.parse(responseText);
		}
		
		return jsonObj;
	}

	findItemFromDashboardVariableID(jsonObj, dashboardVariableID1) {

		var i;
		var itemObj;
		var dashboardVariableID2;

		for(i=0; i<jsonObj.length; i++) {
			itemObj = jsonObj[i];
			dashboardVariableID2 = itemObj.dashboardVariableID;
			if (dashboardVariableID1 === dashboardVariableID2) {
				return itemObj;
			}
		}

		return null;
	}
	
	async serverGetDashboardDataForOneMonth(dashboardVariableListMonthlyJsonObj, dateYear, dateMonth) {
		
		if (dashboardVariableListMonthlyJsonObj === null) {
			return;
		}
		
		this.setState({dashboardVariableListMonthlyJsonObj: null});
		
		var dashboardID = this.props.dashboardID;
		var i;
		var itemObj;
		var dashboardVariableID;
		var variableType;
		var jsonObj;
		var valueItemObj;
		
		jsonObj = await this.serverGetDashboardValueMonthMulti(dashboardID, dateYear, dateMonth);

		for(i=0; i<dashboardVariableListMonthlyJsonObj.length; i++) {
			itemObj = dashboardVariableListMonthlyJsonObj[i];
			dashboardVariableID = itemObj.dashboardVariableID;
			valueItemObj = this.findItemFromDashboardVariableID(jsonObj, dashboardVariableID);
			itemObj.valueItemObj = valueItemObj;
		}
		
		this.setState({dashboardVariableListMonthlyJsonObj: dashboardVariableListMonthlyJsonObj});
	}
	
	createItemObjForTotalExpenses(dashboardVariableListMonthlyJsonObj) {

		var calcHelpersObj = this.props.calcHelpersObj;
		var i;
		var itemObj = {};
		var variableName;
		var variableType;
		var currencyYN;
		var count;
		var valueInt;
		var valueFloat;
		var value;
		var sum = 0;
		var valueItemObj;
		
		for(i=0; i<dashboardVariableListMonthlyJsonObj.length; i++) {
			itemObj = dashboardVariableListMonthlyJsonObj[i];
			variableName = itemObj.variableName;
			variableType = itemObj.variableType;
			currencyYN = calcHelpersObj.isVariableCurrencyYN(variableName);
			if (currencyYN) {
				valueItemObj = itemObj.valueItemObj;			
				if (valueItemObj !== null) {
					value = 0;
					valueInt = valueItemObj.valueInt;
					valueFloat = valueItemObj.valueFloat;
					if (variableType === "int") {
						value = valueInt;
					}
					if (variableType === "float") {
						value = valueFloat;
					}
					sum = sum + value;
				}
			}
		}
		
		valueItemObj = {};
		valueItemObj.valueInt = sum;
		valueItemObj.valueFloat = 0;

		var itemObj2 = {};
		itemObj2.variableName = "totalexpenses";
		itemObj2.variableType = "int";
		itemObj2.valueItemObj = valueItemObj;
		
		return itemObj2;
	}
	
	createItemObjForTotalStaff(dashboardVariableListMonthlyJsonObj) {

		var calcHelpersObj = this.props.calcHelpersObj;
		var i;
		var itemObj = {};
		var variableName;
		var variableType;
		var staffYN;
		var count;
		var valueInt;
		var valueFloat;
		var value;
		var sum = 0;
		var valueItemObj;
		
		for(i=0; i<dashboardVariableListMonthlyJsonObj.length; i++) {
			itemObj = dashboardVariableListMonthlyJsonObj[i];
			variableName = itemObj.variableName;
			variableType = itemObj.variableType;
			staffYN = calcHelpersObj.isVariableStaffYN(variableName);
			if (staffYN) {
				valueItemObj = itemObj.valueItemObj;			
				if (valueItemObj !== null) {
					value = 0;
					valueInt = valueItemObj.valueInt;
					valueFloat = valueItemObj.valueFloat;
					if (variableType === "int") {
						value = valueInt;
					}
					if (variableType === "float") {
						value = valueFloat;
					}
					sum = sum + value;
				}
			}
		}
		
		valueItemObj = {};
		valueItemObj.valueInt = sum;
		valueItemObj.valueFloat = 0;

		var itemObj2 = {};
		itemObj2.variableName = "totalstaff";
		itemObj2.variableType = "int";
		itemObj2.valueItemObj = valueItemObj;
		
		return itemObj2;
	}
	
	createItemObjForTotalSalary(dashboardVariableListMonthlyJsonObj) {

		var calcHelpersObj = this.props.calcHelpersObj;
		var i;
		var itemObj = {};
		var variableName;
		var variableType;
		var salaryYN;
		var count;
		var valueInt;
		var valueFloat;
		var value;
		var sum = 0;
		var valueItemObj;
		
		for(i=0; i<dashboardVariableListMonthlyJsonObj.length; i++) {
			itemObj = dashboardVariableListMonthlyJsonObj[i];
			variableName = itemObj.variableName;
			variableType = itemObj.variableType;
			salaryYN = calcHelpersObj.isVariableSalaryYN(variableName);
			if (salaryYN) {
				valueItemObj = itemObj.valueItemObj;			
				if (valueItemObj !== null) {
					value = 0;
					valueInt = valueItemObj.valueInt;
					valueFloat = valueItemObj.valueFloat;
					if (variableType === "int") {
						value = valueInt;
					}
					if (variableType === "float") {
						value = valueFloat;
					}
					sum = sum + value;
				}
			}
		}
		
		valueItemObj = {};
		valueItemObj.valueInt = sum;
		valueItemObj.valueFloat = 0;

		var itemObj2 = {};
		itemObj2.variableName = "totalsalary";
		itemObj2.variableType = "int";
		itemObj2.valueItemObj = valueItemObj;
		
		return itemObj2;
	}	

	createItemObjForEBITDA(dashboardVariableListMonthlyJsonObj, dashboardVariableListMonthlyJsonObj2) {

		var calcHelpersObj = this.props.calcHelpersObj;

		var totalRevenueValue = this.props.totalRevenue;
		var totalExpensesValue = this.getVariableValue(dashboardVariableListMonthlyJsonObj2, "totalexpenses");
		var pdvValue = this.getVariableValue(dashboardVariableListMonthlyJsonObj, "pdv");
		
		var totalRevenueValue2 = totalRevenueValue - pdvValue;
		var totalExpensesValue2 = totalExpensesValue - pdvValue;
		var ebitdaValue = totalRevenueValue2 - totalExpensesValue2;
		
		var valueItemObj;
		valueItemObj = {};
		valueItemObj.valueInt = ebitdaValue;
		valueItemObj.valueFloat = 0;

		var itemObj2 = {};
		itemObj2.variableName = "ebitda";
		itemObj2.variableType = "int";
		itemObj2.valueItemObj = valueItemObj;
		itemObj2.totalRevenue = totalRevenueValue2;
		itemObj2.totalExpensesValue = totalExpensesValue2;
		
		return itemObj2;
	}	

	getVariableValue(dashboardVariableListMonthlyJsonObj, variableName1) {
		
		var i;
		var variableName2;
		var variableType;
		var itemObj;
		var valueItemObj;
		var valueInt = 0;
		var valueFloat = 0;
		var value = 0;
		
		for(i=0; i<dashboardVariableListMonthlyJsonObj.length; i++) {

			itemObj = dashboardVariableListMonthlyJsonObj[i];
			variableName2 = itemObj.variableName;

			if (variableName1 === variableName2) {

				valueItemObj = itemObj.valueItemObj;
				variableType = itemObj.variableType;

				if (valueItemObj !== null) {	

					valueInt = valueItemObj.valueInt;
					valueFloat = valueItemObj.valueFloat;
					
					if (variableType === "int") {
						value = valueInt;
					}

					if (variableType === "float") {
						value = valueFloat;
					}

					return value;
				}
			}
		}

		return 0;
	}

	renderOneBoxNumber(itemObj, index, dashboardVariableListMonthlyJsonObj, showSalaryYN) {
		
		var calcHelpersObj = this.props.calcHelpersObj;
		var currentYear = this.props.currentYear;
		var currentMonth = this.props.currentMonth;
		var selectedYear = this.props.selectedYear;
		var selectedMonth = this.props.selectedMonth;
		var variableName = itemObj.variableName;
		var variableType = itemObj.variableType;
		
		if (variableType === "text") {
			return null;
		}
		
		var salaryYN;
		var skipVariableYN = false;
		if (!showSalaryYN) {
			salaryYN = calcHelpersObj.isVariableSalaryYN(variableName);
			if (salaryYN) {
				skipVariableYN = true;
				return null;				
			}
			if (variableName === "totalsalary") {
				skipVariableYN = true;
			}
		}
		

		if (skipVariableYN) {
			return null;
		}

		var currentYearMonthYN = false;
		if ((currentYear === selectedYear) && (currentMonth === selectedMonth)) {
			currentYearMonthYN = true;
		}
		
		var currencyYN = calcHelpersObj.isVariableCurrencyYN(variableName);

		var i;
		var valueItemObj = itemObj.valueItemObj;
		var count = 0;
		var valueInt = 0;
		var valueFloat = 0;
		var value = 0;
		
		value = 0;

		if (valueItemObj !== null) {

			valueInt = valueItemObj.valueInt;
			valueFloat = valueItemObj.valueFloat;
			
			if (variableType === "int") {
				value = valueInt;
			}

			if (variableType === "float") {
				value = valueFloat;
			}
		}
		
		var decimalPlaces = 0;
		if (variableType === "float") {
			decimalPlaces = 2;
		}
		
		var valueString = value;
		
		if (currencyYN) {
			valueString = this.props.getEURString(value, decimalPlaces);
		}
		
		var variableString = this.props.getLang(DashboardLang, variableName);		

		var staffVariableName = calcHelpersObj.getStaffVariableFromSalaryYN(variableName);
		var staffCount = 0;
		var staffString = "";
		var averageSalary = 0;
		var averageSalaryString = "";
		if (staffVariableName !== "") {
			staffCount = this.getVariableValue(dashboardVariableListMonthlyJsonObj, staffVariableName);
			staffString = this.props.getLang(DashboardLang, "numemployees") + ": " + staffCount;
			if (staffCount > 0) {
				averageSalary = parseInt(value / staffCount);
				averageSalaryString = this.props.getLang(DashboardLang, "averagesalary") + ": " + this.props.getEURString(averageSalary, 0);
			}
		}

		var pdvValue = 0;
		var expensesMinusPDV = 0;
		var salaryValue = 0;
		var expensesMinusPDVAndSalary = 0;
		var minusPDVString = "";
		var minusSalaryString = "";
		if (variableName === "totalexpenses") {
			pdvValue = this.getVariableValue(dashboardVariableListMonthlyJsonObj, "pdv");
			expensesMinusPDV = value - pdvValue;
			if (pdvValue > 0) {
				minusPDVString = "- " + this.props.getLang(DashboardLang, "pdv") + " = " + this.props.getEURString(expensesMinusPDV, 0);
			}
			salaryValue = this.getVariableValue(dashboardVariableListMonthlyJsonObj, "totalsalary");
			if (salaryValue > 0) {
				expensesMinusPDVAndSalary = expensesMinusPDV - salaryValue;
				minusSalaryString = "- " + this.props.getLang(DashboardLang, "totalsalary") + " = " + this.props.getEURString(expensesMinusPDVAndSalary, 0);
			}
		}

		var ebitdaCalcString = "";
		var ebitdaRatioString = "";
		var totalRevenue = 0;
		var totalExpenses = 0;
		var ebitdaRatio = 0;
		if (variableName === "ebitda") {
			totalRevenue = itemObj.totalRevenue;
			totalExpenses = itemObj.totalExpensesValue;
			ebitdaCalcString = this.props.getEURString(totalRevenue, 0) + " - " + this.props.getEURString(totalExpenses, 0);
			if (totalRevenue > 0) {
				ebitdaRatio = value / totalRevenue;
				ebitdaRatioString = this.props.getEURString(value, 0) + " / " + this.props.getEURString(totalRevenue, 0) + " = " + Math.round(100 * ebitdaRatio) + "%";
			}
		}

		return (
			<div key={index} className="DashboardMainExpensesOneBoxNumber">
				<div className="DashboardMainExpensesBoxTitle">{variableString}</div>
				<div className="DashboardMainExpensesBoxAverage">{valueString}</div>
				<div className="DashboardMainExpensesBoxCompare">{staffString}</div>
				<div className="DashboardMainExpensesBoxCompare">{averageSalaryString}</div>
				<div className="DashboardMainExpensesBoxCompare">{minusPDVString}</div>
				<div className="DashboardMainExpensesBoxCompare">{minusSalaryString}</div>
				<div className="DashboardMainExpensesBoxCompare">{ebitdaCalcString}</div>
				<div className="DashboardMainExpensesBoxCompare">{ebitdaRatioString}</div>
				{/*<div className={classNamePercengage}>{percentageChangeString}</div>
				<div className="DashboardMainExpensesBoxCompare">{compareYear}: {compareMonthAverageString}</div>*/}
			</div>
		);
	}

	renderOneBoxText(itemObj, index) {

		var variableName = itemObj.variableName;
		var variableType = itemObj.variableType;
		
		if (variableType !== "text") {
			return null;
		}
		
		var variableString = this.props.getLang(DashboardLang, variableName);		

		var i;
		var valueItemObj = itemObj.valueItemObj;
		var count = 0;
		var valueString = "";
		
		if (valueItemObj !== null) {

			if (variableType === "text") {
				valueString = valueItemObj.valueString;
			}
		}
		
		var valueStringBr = this.props.convertTextNewlineToBr(valueString, "valueString", "DashboardMainExpensesBoxText");

		return (
			<div key={index} className="DashboardMainExpensesOneBoxText">
				<div className="DashboardMainExpensesBoxTitle">{variableString}</div>
				<div style={{height: "5px"}}></div>
				<div className="DashboardMainExpensesBoxText">{valueStringBr}</div>
				<div style={{height: "5px"}}></div>
			</div>
		);
	}

	setBoxRenderYN(dashboardVariableListMonthlyJsonObj, variableName1, boxRenderYN) {

		var i;
		var variableName2;
		var itemObj;

		for(i=0; i<dashboardVariableListMonthlyJsonObj.length; i++) {
			itemObj = dashboardVariableListMonthlyJsonObj[i];
			variableName2 = itemObj.variableName;
			if (variableName1 === variableName2) {
				itemObj.boxRenderYN = boxRenderYN;
				return;
			}
		}
	}
		
	renderAllBoxes() {
		
		var dashboardVariableListMonthlyJsonObj = this.state.dashboardVariableListMonthlyJsonObj;
		if (dashboardVariableListMonthlyJsonObj === null) {
			return;
		}
		
		var calcHelpersObj = this.props.calcHelpersObj;
		var dashboardAppUserType = this.props.dashboardAppUserType;
		var dashboardUserType = this.props.dashboardUserType;
		
		var showSalaryYN = false;
		if (dashboardAppUserType >= 100) {
			showSalaryYN = true;
		}
		if (dashboardUserType === 3) {
			showSalaryYN = true;
		}
		if (dashboardUserType === 102) {
			showSalaryYN = true;
		}
		
		var itemObj;
		var dashboardVariableListMonthlyJsonObj2 = [];
		
		itemObj = this.createItemObjForTotalExpenses(dashboardVariableListMonthlyJsonObj);
		dashboardVariableListMonthlyJsonObj2.push(itemObj);
		
		itemObj = this.createItemObjForEBITDA(dashboardVariableListMonthlyJsonObj, dashboardVariableListMonthlyJsonObj2);
		dashboardVariableListMonthlyJsonObj2.push(itemObj);
		
		itemObj = this.createItemObjForTotalStaff(dashboardVariableListMonthlyJsonObj);
		dashboardVariableListMonthlyJsonObj2.push(itemObj);
		
		itemObj = this.createItemObjForTotalSalary(dashboardVariableListMonthlyJsonObj);
		dashboardVariableListMonthlyJsonObj2.push(itemObj);

		for(i=0; i<dashboardVariableListMonthlyJsonObj.length; i++) {
			itemObj = dashboardVariableListMonthlyJsonObj[i];
			dashboardVariableListMonthlyJsonObj2.push(itemObj);
		}
		dashboardVariableListMonthlyJsonObj = dashboardVariableListMonthlyJsonObj2;
		
		var i;
		var variableName;
		var variableName2;

		// mark items to skip box render
		{
			for(i=0; i<dashboardVariableListMonthlyJsonObj.length; i++) {
				itemObj = dashboardVariableListMonthlyJsonObj[i];
				itemObj.boxRenderYN = true;
			}
			
			for(i=0; i<dashboardVariableListMonthlyJsonObj.length; i++) {
				itemObj = dashboardVariableListMonthlyJsonObj[i];
				variableName = itemObj.variableName;
				variableName2 = calcHelpersObj.getStaffVariableFromSalaryYN(variableName);
				if (variableName2 !== "") {
					if (showSalaryYN) {
						this.setBoxRenderYN(dashboardVariableListMonthlyJsonObj, variableName2, false);
					}
				}
			}
		}
		
		var jsxCode;
		var jsxArray1 = [];
		var jsxArray2 = [];
		
		for(i=0; i<dashboardVariableListMonthlyJsonObj.length; i++) {
			itemObj = dashboardVariableListMonthlyJsonObj[i];
			if (itemObj.boxRenderYN) {
				jsxCode = this.renderOneBoxNumber(itemObj, i, dashboardVariableListMonthlyJsonObj, showSalaryYN);
				jsxArray1.push(jsxCode);
			}
		}
		
		for(i=0; i<dashboardVariableListMonthlyJsonObj.length; i++) {
			itemObj = dashboardVariableListMonthlyJsonObj[i];
			jsxCode = this.renderOneBoxText(itemObj, i);
			jsxArray2.push(jsxCode);
		}
		
		return (
			<div>
				<div className="DashboardMainExpensesBoxContainer1">
					{jsxArray1}
				</div>
				<div className="DashboardMainExpensesBoxContainer2">
					{jsxArray2}
				</div>
			</div>
		);
	}
	

	render() {
		return (
			<div className="DashboardMainExpensesTopContainer">
				{this.renderAllBoxes()}
				<div style={{height: "100px"}}></div>
			</div>
		);
	}
}

export default DashboardMainExpenses;

