import React  from 'react';
import './App.css';
import './Dashboard.css';
import DashboardLang from './DashboardLang.json';

class ToolbarPopupMenu extends React.Component {

	constructor(props) {

		super(props);
		this.ref = React.createRef();

		this.handleClickOutside = this.handleClickOutside.bind(this);
		this.handleClickLink = this.handleClickLink.bind(this);
		this.renderOneLink = this.renderOneLink.bind(this);
		this.renderAllLinks = this.renderAllLinks.bind(this);
	}

	componentDidMount() {
		document.addEventListener('click', this.handleClickOutside, true);
	}

	componentWillUnmount() {
		document.removeEventListener('click', this.handleClickOutside, true);
	};
	
	handleClickOutside(event) {
		if (this.ref.current && !this.ref.current.contains(event.target)) {
			this.props.closePopupMenu();
		}
	};

	handleClickLink(variableName) {
		this.props.handleSelectLink(variableName);
		this.props.closePopupMenu();
	}

	renderOneLink(variableNameArray, index) {

		var variableName = variableNameArray[index];
		var variableString = this.props.getLang(DashboardLang, variableName);

		return (
			<div 
				key={index} 
				className="ToolbarPopupMenuOneLink"
				onClick={() => this.handleClickLink(variableName)}
			>
				{variableString}
			</div>
		);		
	}

	renderAllLinks() {

		var i;
		var jsxCode;
		var jsxArray = [];
		var variableNameArray = this.props.variableNameArray;

		for (i=0; i<variableNameArray.length; i++) {
			jsxCode = this.renderOneLink(variableNameArray, i);
			jsxArray.push(jsxCode);
		}

		return jsxArray;
	}

	render() {
		
		return (
			<div ref={this.ref} className="ToolbarPopupMenuContainer1">
				<div className="ToolbarPopupMenuContainer">
					{this.renderAllLinks()}
				</div>
			</div>
		);
	}
}

export default ToolbarPopupMenu;