import React  from 'react';
import './App.css';
import './Recent.css';
import RecentLang from './RecentLang.json';

import { useNavigate } from "react-router-dom";

class RecentRooms extends React.Component {

	constructor(props) {

		super(props);

		this.state = {
			dashboardListJsonObj: null,
		};

		this.serverGetDashboardValueRecent = this.serverGetDashboardValueRecent.bind(this);
		this.getAllValuesForDashboard = this.getAllValuesForDashboard.bind(this);
		this.serverGetDashboardValues = this.serverGetDashboardValues.bind(this);
		this.handleSelectDashboard = this.handleSelectDashboard.bind(this);				
		this.renderOneBoxDay = this.renderOneBoxDay.bind(this);
		this.renderOneBox = this.renderOneBox.bind(this);
		this.renderAllDashboards = this.renderAllDashboards.bind(this);				
	}
	
	componentDidMount() {
		this.serverGetDashboardValues();
	}
	
	async serverGetDashboardValueRecent(dashboardID, variableName1, variableName2, count) {

		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/getdashboardvaluerecent?dashboardid=" + dashboardID;
		url = url + "&variablename1=" + encodeURIComponent(variableName1);
		url = url + "&variablename2=" + encodeURIComponent(variableName2);
		url = url + "&count=" + count;
		
		const res = await fetch(url);
		var responseText = await res.text();
		
		var jsonObj = null;
		var dashboardUserType = 0;
		if (responseText !== "") {
			jsonObj = JSON.parse(responseText);
		}
		
		return jsonObj;
	}
	
	async getAllValuesForDashboard(itemObj) {
		var dashboardID = itemObj.dashboardID;
		var jsonObj = await this.serverGetDashboardValueRecent(dashboardID, "rooms", "roomprice", 7);
		itemObj.days = jsonObj;
	}

	async serverGetDashboardValues() {

		var email = this.props.email;
		var dashboardAppUserType = this.props.dashboardAppUserType;
		var dashboardListJsonObj = this.props.dashboardListJsonObj;
		
		var dashboardListJsonObj2 = [];
		var i;
		var itemObj;
		var dashboardID;
		var dashboardUserType;
		
		for(i=0; i<dashboardListJsonObj.length; i++) {
			itemObj = dashboardListJsonObj[i];
			if (dashboardAppUserType >= 100) {
				await this.getAllValuesForDashboard(itemObj);
				dashboardListJsonObj2.push(itemObj);
			} else {
				dashboardID = itemObj.dashboardID;
				await this.getAllValuesForDashboard(itemObj);
				dashboardListJsonObj2.push(itemObj);
			}
		}
		
		this.setState({
			dashboardListJsonObj: dashboardListJsonObj2,
		});
	}
	
	handleSelectDashboard(dashboardID) {
		this.props.setSelectedDashboard(dashboardID);
		const navigate = this.props.navigate;
  		navigate("/monthly");
	}

	renderOneBoxDay(itemObj) {

		var year = itemObj.year;
		var month = itemObj.month;
		var day = itemObj.day;
		var key = year + "-" + month + "-" + day;
		var rooms = itemObj.value1;
		var roomPrice = itemObj.value2;
		var roomRevenue = rooms * roomPrice;
		var dayOfWeek = this.props.getDayOfWeek(year, month, day);
		var weekdayString = this.props.getWeekdayStringShort(dayOfWeek);
		weekdayString = weekdayString.toUpperCase();
		
		return (
			<div key={key} className="RecentBoxDayContainer">
				<div className="RecentBoxDayLeft">{weekdayString} {day}.{month}.</div>
				<div className="RecentBoxDayRight">{rooms} x {this.props.getEURString(roomPrice, 0)} = <b>{this.props.getEURString(roomRevenue, 0)}</b></div>
			</div>
		);
	}

	renderOneBox(itemObj) {

		var dashboardID = itemObj.dashboardID;
		var dashboardName = itemObj.dashboardName;
		var daysArray = itemObj.days;
		
		if (daysArray !== null) {
			daysArray.sort(function(a, b) {

				var year1 = a.year;
				var month1 = a.month;
				var day1 = a.day;
				var year2 = b.year;
				var month2 = b.month;
				var day2 = b.day;

				if (year1 > year2) {
					return -1;
				}
				if (year1 < year2) {
					return 1;
				}

				if (month1 > month2) {
					return -1;
				}
				if (month1 < month2) {
					return 1;
				}

				if (day1 > day2) {
					return -1;
				}
				if (day1 < day2) {
					return 1;
				}
				return 0;
			});
		}

		var i;
		var itemObj2;
		var jsxCode;
		var jsxArray = [];
		
		if (daysArray !== null) {
			for(i=0; i<daysArray.length; i++) {
				itemObj2 = daysArray[i];
				jsxCode = this.renderOneBoxDay(itemObj2);
				jsxArray.push(jsxCode);
			}
		}

		return (
			<div key={dashboardID} className="RecentOneBox" onClick={() => this.handleSelectDashboard(dashboardID)}>
				<div className="RecentBoxTitle">{dashboardName}</div>
				{jsxArray}
			</div>
		);
	}

	renderAllDashboards() {

		var dashboardListJsonObj = this.state.dashboardListJsonObj;
		if (dashboardListJsonObj === null) {
			return (<div>Loading data...</div>);
		}

		var length = dashboardListJsonObj.length;
		var i;
		var itemObj;
		var jsxCode;
		var jsxArray = [];
		
		for(i=0; i<length; i++) {
			itemObj = dashboardListJsonObj[i];
			jsxCode = this.renderOneBox(itemObj);
			jsxArray.push(jsxCode);
		}
		
		return (
			<div className="RecentBoxContainer">
				{jsxArray}
			</div>
		);
	}

	render() {
		return (
			<div className="SelectDashboardTopContainer">
				<div style={{height: "20px"}}></div>
				{this.renderAllDashboards()}
			</div>
		);
	}
}

export default function RecentRoomsFunction(props) {
	const navigate = useNavigate();
	return <RecentRooms {...props} navigate={navigate} />;
}
