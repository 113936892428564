import React  from 'react';
import './App.css';
import './Daily.css';
import DailyLang from './DailyLang.json';
import RecentMainToolbar from './RecentMainToolbar.js';

import RecentRooms from './RecentRooms.js';
import RecentOccupancy from './RecentOccupancy.js';

class RecentMain extends React.Component {

	constructor(props) {

		super(props);
		
		this.state = {
			tab: "rooms",
		};
		
		this.setTab = this.setTab.bind(this);
		this.renderRooms = this.renderRooms.bind(this);
		this.renderOccupancy = this.renderOccupancy.bind(this);
		this.renderTab = this.renderTab.bind(this);
	}

	componentDidMount() {
	}
	
	componentDidUpdate() {
	}

	setTab(tab) {
		this.setState({
			tab: tab,
		});
	}
	
	renderRooms() {
		return (
			<RecentRooms
				calcHelpersObj={this.props.calcHelpersObj}
				baseURLAPI={this.props.baseURLAPI}
				email={this.props.email}
				dashboardAppUserType={this.props.dashboardAppUserType}
				dashboardListJsonObj={this.props.dashboardListJsonObj}
				setTab={this.setTab}
				getLang={this.props.getLang}
				getEURString={this.props.getEURString}
				getDateTimeStringFromUnixTimeSec={this.props.getDateTimeStringFromUnixTimeSec}
				getMonthString={this.props.getMonthString}
				getDayOfWeek={this.props.getDayOfWeek}
				getWeekdayStringShort={this.props.getWeekdayStringShort}
			/>
		);
	}

	renderOccupancy() {
		return (
			<RecentOccupancy
				calcHelpersObj={this.props.calcHelpersObj}
				baseURLAPI={this.props.baseURLAPI}
				email={this.props.email}
				dashboardAppUserType={this.props.dashboardAppUserType}
				dashboardListJsonObj={this.props.dashboardListJsonObj}
				setTab={this.setTab}
				getLang={this.props.getLang}
				getEURString={this.props.getEURString}
				getDateTimeStringFromUnixTimeSec={this.props.getDateTimeStringFromUnixTimeSec}
				getMonthString={this.props.getMonthString}
				getDayOfWeek={this.props.getDayOfWeek}
				getWeekdayStringShort={this.props.getWeekdayStringShort}
			/>
		);
	}

	renderTab() {
		
		var tab = this.state.tab;
		
		if (tab === "rooms") {
			return this.renderRooms();
		}

		if (tab === "occupancy") {
			return this.renderOccupancy();
		}
	}

	render() {

		return (
			<div className="RecentMainTopContainer">
				<RecentMainToolbar
					dashboardAppUserType={this.props.dashboardAppUserType}
					dashboardUserType={this.props.dashboardUserType}
					dashboardVariableListJsonObj={this.state.dashboardVariableListJsonObj}
					tab={this.state.tab}
					setTab={this.setTab}
					getLang={this.props.getLang}
				/>
				{this.renderTab()}
			</div>
		);
	}
}

export default RecentMain;

