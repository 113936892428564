import React  from 'react';
import './App.css';
import './DashboardEdit.css';
import DashboardLang from './DashboardLang.json';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ReactTooltip from 'react-tooltip';
import DashboardEditToolbar from './DashboardEditToolbar.js';

class DashboardEditDaily extends React.Component {

	constructor(props) {

		super(props);
		
		this.state = {
			dateYear: 0,
			dateMonth: 0,
			dateDay: 0,
			dashboardVariableListJsonObj: null,
		};
		
		this.serverGetDashboardVariableList = this.serverGetDashboardVariableList.bind(this);
		this.serverGetDashboardValueDay = this.serverGetDashboardValueDay.bind(this);
		this.serverGetDashboardDataForOneDay = this.serverGetDashboardDataForOneDay.bind(this);
		this.serverSetDashboardValue = this.serverSetDashboardValue.bind(this);
		this.deleteDashboardMonthTotalRecord = this.deleteDashboardMonthTotalRecord.bind(this);
		this.getTwoDigits = this.getTwoDigits.bind(this);
		this.getDateStringFromYMD = this.getDateStringFromYMD.bind(this);
		this.getDateObjFromYMD = this.getDateObjFromYMD.bind(this);
		this.prevDate = this.prevDate.bind(this);
		this.nextDate = this.nextDate.bind(this);
		this.datePickerSetDate = this.datePickerSetDate.bind(this);
		this.onValueChanged = this.onValueChanged.bind(this);
		this.onValueSave = this.onValueSave.bind(this);
		this.renderOneVariable = this.renderOneVariable.bind(this);
		this.renderAllVariables = this.renderAllVariables.bind(this);
	}

	componentDidMount() {

		var dateYear = 0;
		var dateMonth = 0;
		var dateDay = 0;

		var currentDateTime = new Date();
		dateYear = currentDateTime.getFullYear();
		dateMonth = currentDateTime.getMonth() + 1;
		dateDay = currentDateTime.getDate();

		this.setState({
			dateYear: dateYear,
			dateMonth: dateMonth,
			dateDay: dateDay,
		});

		var dashboardID = this.props.dashboardID;
		this.serverGetDashboardVariableList(dashboardID, dateYear, dateMonth, dateDay);
	}
	
	async serverGetDashboardVariableList(dashboardID, dateYear, dateMonth, dateDay) {

		this.setState({dashboardVariableListJsonObj: null});

		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/getdashboardvariablelist?dashboardid=" + dashboardID + "&variablefrequency=daily";
		
		const res = await fetch(url);
		const dashboardVariableListJsonObj = await res.json();
		
		var i;
		var itemObj;
		
		for(i=0; i<dashboardVariableListJsonObj.length; i++) {
			itemObj = dashboardVariableListJsonObj[i];
			itemObj.value = "";
		}

		this.serverGetDashboardDataForOneDay(dashboardVariableListJsonObj, dateYear, dateMonth, dateDay);
	}
	
	async serverGetDashboardValueDay(dashboardVariableID, year, month, day) {

		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/getdashboardvalueday?dashboardvariableid=" + dashboardVariableID;
		url = url + "&year=" + year;
		url = url + "&month=" + month;
		url = url + "&day=" + day;
		
		const res = await fetch(url);
		var responseText = await res.text();

		var jsonObj = null;
		if (responseText !== "") {
			jsonObj = JSON.parse(responseText);
		}
		
		return jsonObj;
	}
	
	async serverGetDashboardDataForOneDay(dashboardVariableListJsonObj, dateYear, dateMonth, dateDay) {
		
		this.setState({dashboardVariableListJsonObj: null});

		if (dashboardVariableListJsonObj === null) {
			return;
		}
		
		var i;
		var itemObj1;
		var dashboardVariableID;
		var variableType;
		var jsonObj;
		var itemObj2;
		var valueInt;
		var valueFloat;
		var valueString;
		
		for(i=0; i<dashboardVariableListJsonObj.length; i++) {
			itemObj1 = dashboardVariableListJsonObj[i];
			dashboardVariableID = itemObj1.dashboardVariableID;
			variableType = itemObj1.variableType;
			jsonObj = await this.serverGetDashboardValueDay(dashboardVariableID, dateYear, dateMonth, dateDay);
			itemObj1.value = "";
			itemObj1.fullName = "";
			itemObj1.unixTimeSec = 0;
			if (jsonObj !== null) {
				if (jsonObj.length === 1) {
					itemObj2 = jsonObj[0];
					valueInt = itemObj2.valueInt;
					valueFloat = itemObj2.valueFloat;
					valueString = itemObj2.valueString;
					if (variableType === "int") {
						itemObj1.value = valueInt;
					}
					if (variableType === "float") {
						itemObj1.value = valueFloat;
					}
					if (variableType === "string") {
						itemObj1.value = valueString;
					}
					itemObj1.fullName = itemObj2.fullName;
					itemObj1.unixTimeSec = itemObj2.unixTimeSec;
				}
			}
		}

		this.setState({dashboardVariableListJsonObj: dashboardVariableListJsonObj});
	}
	
	async serverSetDashboardValue(dashboardVariableID, year, month, day, valueInt, valueFloat, valueString, userID, fullName) {

		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/setdashboardvalue?dashboardvariableid=" + dashboardVariableID;
		url = url + "&year=" + year;
		url = url + "&month=" + month;
		url = url + "&day=" + day;
		url = url + "&valueint=" + valueInt;
		url = url + "&valuefloat=" + valueFloat;
		url = url + "&valuestring=" + encodeURIComponent(valueString);
		url = url + "&userid=" + userID;
		url = url + "&fullname=" + encodeURIComponent(fullName);
		
		const res = await fetch(url);
		await res.text();
	}
	
	async deleteDashboardMonthTotalRecord(dashboardVariableID, year, month) {

		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/deletedashboardmonthtotalrecord?dashboardvariableid=" + dashboardVariableID;
		url = url + "&year=" + year;
		url = url + "&month=" + month;
		
		const res = await fetch(url);
		await res.text();
	}
	
	getTwoDigits(number1) {
		var number2;
		number2 = number1;
		if (number1 < 10) {
			number2 = "0" + number1;
		}
		return number2;
	}

	getDateStringFromYMD(year, month, day) {
		var dateString;
		dateString = this.getTwoDigits(day) + "." + this.getTwoDigits(month) + "." + year + ".";
		return dateString;
	}
	
	getDateObjFromYMD(year, month, day) {

		var dateObj = new Date();
		dateObj.setFullYear(year, month-1, day);
		dateObj.setHours(0);
		dateObj.setMinutes(0);
		dateObj.setSeconds(0);
		dateObj.setMilliseconds(0);

		return dateObj;
	}
	
	prevDate() {

		var dateYear = this.state.dateYear;
		var dateMonth = this.state.dateMonth;
		var dateDay = this.state.dateDay;
		
		var dateObj = new Date();
		dateObj.setFullYear(dateYear, dateMonth-1, dateDay);
		dateObj.setHours(0);
		dateObj.setMinutes(0);
		dateObj.setSeconds(0);
		dateObj.setMilliseconds(0);
		
		var unixTimeSec = Math.floor(dateObj.getTime() / 1000);
		var unixTimeSec2 = unixTimeSec - 24*3600;
		
		var dateObj2 = new Date(unixTimeSec2 * 1000);

		var dateYear2 = dateObj2.getFullYear();
		var dateMonth2 = dateObj2.getMonth() + 1;
		var dateDay2 = dateObj2.getDate();

		this.setState({
			dateYear: dateYear2,
			dateMonth: dateMonth2,
			dateDay: dateDay2,
		});

		this.serverGetDashboardDataForOneDay(this.state.dashboardVariableListJsonObj, dateYear2, dateMonth2, dateDay2);
	}
	
	nextDate() {

		var dateYear = this.state.dateYear;
		var dateMonth = this.state.dateMonth;
		var dateDay = this.state.dateDay;
		
		var dateObj = new Date();
		dateObj.setFullYear(dateYear, dateMonth-1, dateDay);
		dateObj.setHours(0);
		dateObj.setMinutes(0);
		dateObj.setSeconds(0);
		dateObj.setMilliseconds(0);
		
		var unixTimeSec = Math.floor(dateObj.getTime() / 1000);
		var unixTimeSec2 = unixTimeSec + 24*3600;
		
		var dateObj2 = new Date(unixTimeSec2 * 1000);

		var dateYear2 = dateObj2.getFullYear();
		var dateMonth2 = dateObj2.getMonth() + 1;
		var dateDay2 = dateObj2.getDate();

		this.setState({
			dateYear: dateYear2,
			dateMonth: dateMonth2,
			dateDay: dateDay2,
		});

		this.serverGetDashboardDataForOneDay(this.state.dashboardVariableListJsonObj, dateYear2, dateMonth2, dateDay2);
	}
		
	datePickerSetDate(dateObj) {

		var dateYear = dateObj.getFullYear();
		var dateMonth = dateObj.getMonth() + 1;
		var dateDay = dateObj.getDate();

		this.setState({
			dateYear: dateYear,
			dateMonth: dateMonth,
			dateDay: dateDay,
		});

		this.serverGetDashboardDataForOneDay(this.state.dashboardVariableListJsonObj, dateYear, dateMonth, dateDay);
	}
	
	onValueChanged(dashboardVariableID1, value) {

		var dashboardVariableListJsonObj = this.state.dashboardVariableListJsonObj;
		if (dashboardVariableListJsonObj === null) {
			return;
		}
		
		var i;
		var itemObj;
		var dashboardVariableID2;
		
		for(i=0; i<dashboardVariableListJsonObj.length; i++) {
			itemObj = dashboardVariableListJsonObj[i];
			dashboardVariableID2 = itemObj.dashboardVariableID;
			if (dashboardVariableID1 === dashboardVariableID2) {
				itemObj.value = value;
			}
		}
		
		this.setState({dashboardVariableListJsonObj: dashboardVariableListJsonObj});
	}

	onValueSave(dashboardVariableID, variableType, value) {

		var valueInt = 0;
		var valueFloat = 0;
		var valueString = "";
		
		if (variableType === "int") {
			valueInt = parseInt(value);
		}
		
		if (variableType === "float") {
			valueFloat = parseFloat(value);
		}
		
		if (variableType === "string") {
			valueString = value;
		}
		
		this.serverSetDashboardValue(dashboardVariableID, this.state.dateYear, this.state.dateMonth, this.state.dateDay, valueInt, valueFloat, valueString, this.props.userID, this.props.fullName);
		this.deleteDashboardMonthTotalRecord(dashboardVariableID, this.state.dateYear, this.state.dateMonth);
	}

	renderOneVariable(itemObj) {
		
		var key = itemObj.dashboardVariableID;
		var dashboardVariableID = itemObj.dashboardVariableID;
		var variableName = itemObj.variableName;		
		var variableType = itemObj.variableType;
		var value = itemObj.value;
		var fullName = itemObj.fullName;
		var unixTimeSec = itemObj.unixTimeSec;

		var editString = "";		
		if (unixTimeSec > 0) {
			editString = fullName + " - " + this.props.getDateTimeStringFromUnixTimeSec(unixTimeSec);
		}
		
		var variableString = this.props.getLang(DashboardLang, variableName);
		
		var inputType = "text";
		if (variableType === "int") {
			inputType = "number";
		}
		if (variableType === "float") {
			inputType = "number";
		}

		return (
			<tr key={key}>
				<td className="DashboardEditVariable">{variableString}</td>
				<td>
					<input
						className="DashboardEditNumber" 
						id={dashboardVariableID}
						type={inputType} 
						value={value}
						onChange={(event) => this.onValueChanged(dashboardVariableID, event.target.value)}
						onBlur={(event) => this.onValueSave(dashboardVariableID, variableType, event.target.value)}
					/>
				</td>
				<td>
					{editString}
				</td>
			</tr>
		);
	}

	renderAllVariables() {

		var dashboardVariableListJsonObj = this.state.dashboardVariableListJsonObj;
		if (dashboardVariableListJsonObj === null) {
			return;
		}

		var i;
		var itemObj;
		var jsxCode;
		var jsxArray = [];
		
		for(i=0; i<dashboardVariableListJsonObj.length; i++) {
			itemObj = dashboardVariableListJsonObj[i];
			jsxCode = this.renderOneVariable(itemObj);
			jsxArray.push(jsxCode);
		}
		
		return (
			<table>
				<tbody>
					{jsxArray}
				</tbody>
			</table>
		);
	}
	
	render() {
		
		var dashboardAppUserType = this.props.dashboardAppUserType;
		var dashboardUserType = this.props.dashboardUserType;

		var allowedYN = false;
		if (dashboardAppUserType >= 100) {
			allowedYN = true;
		}
		if ((dashboardUserType === 100) || (dashboardUserType === 101) || (dashboardUserType === 102)) {
			allowedYN = true;
		}
		
		if (!allowedYN) {
			return null;
		}

		var dashboardVariableListJsonObj = this.state.dashboardVariableListJsonObj;
		if (dashboardVariableListJsonObj === null) {
			return (<div><br/>Loading data...</div>);
		}

		var dateObj = this.getDateObjFromYMD(this.state.dateYear, this.state.dateMonth, this.state.dateDay);

		return (
			<div>
				<ReactTooltip
					type="info"
					multiline={true}
					delayShow={200}
				/>
				<DashboardEditToolbar
					dashboardAppUserType={this.props.dashboardAppUserType}
					dashboardUserType={this.props.dashboardUserType}
					dashboardID={this.props.dashboardID}
					dashboardName={this.props.dashboardName}
					frequency="daily"
					getLang={this.props.getLang}
				/>
				<div className="DashboardEditTopContainer">
					<div className="DashboardEditTopTopTitle">{this.props.getLang(DashboardLang, "tasksDailyReport")}</div>
					<div className="DashboardEditDateContainer">
						<div className="DashboardEditDatePrev" onClick={() => this.prevDate()}>&lt;&lt;</div>
						<DatePicker 
							className="DashboardEditDateString" 
							selected={dateObj} onChange={(date) => this.datePickerSetDate(date)} 
							dateFormat="dd.MM.yyyy."
						/>
						<div className="DashboardEditDateNext" onClick={() => this.nextDate()}>&gt;&gt;</div>
					</div>
				</div>
				<div style={{height: "15px"}}/>
				{this.renderAllVariables()}
				<div style={{height: "100px"}}/>
			</div>
		);
	}
}

export default DashboardEditDaily;

