import React  from 'react';
import './App.css';
import './DashboardMain.css';
import DashboardLang from './DashboardLang.json';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import DashboardMainGraphCustomTooltip from './DashboardMainGraphCustomTooltip.js';

class DashboardMainGraph extends React.Component {

	constructor(props) {
		
		super(props);
		
		this.state = {
			width: 0,
			height: 0,
		}

		this.updateDimensions = this.updateDimensions.bind(this);
		this.getMinValue = this.getMinValue.bind(this);
		this.getMaxValue = this.getMaxValue.bind(this);
		this.getAverageValue = this.getAverageValue.bind(this);		
		this.getValues = this.getValues.bind(this);
		this.getData = this.getData.bind(this);
	}
	
	componentDidMount() {
		window.addEventListener('resize', this.updateDimensions);
	}

	updateDimensions = () => {
		this.setState({ width: window.innerWidth, height: window.innerHeight });
	};
	
	getMinValue(dataArray, currentYearMonthYN) {
		
		if (dataArray === null) {
			return 0;
		}
		
		var count = dataArray.length;

		if (count === 0) {
			return 0;
		}

		var i;
		var value;
		var minValue = dataArray[0];

		for(i=1; i<count; i++) {
			value = dataArray[i];
			if ((value > 0) || (!currentYearMonthYN)) {
				if (value < minValue) {
					minValue = value;
				}
			}
		}

		return minValue;
	}

	getMaxValue(dataArray, currentYearMonthYN) {

		if (dataArray === null) {
			return 0;
		}
		
		var count = dataArray.length;

		if (count === 0) {
			return 0;
		}

		var i;
		var value;
		var maxValue = dataArray[0];

		for(i=1; i<count; i++) {
			value = dataArray[i];
			if (value > maxValue) {
				maxValue = value;
			}
		}

		return maxValue;
	}
	
	getAverageValue(variableName, dataArray, currentYearMonthYN) {

		if (dataArray === null) {
			return 0;
		}
		
		var count = dataArray.length;

		if (count === 0) {
			return 0;
		}

		var i;
		var value;
		var sum = 0;
		var count2 = 0;

		for(i=0; i<count; i++) {
			value = dataArray[i];
			if ((value > 0) || (!currentYearMonthYN)) {
				sum = sum + value;
				count2 = count2 + 1;
			}
		}
		
		var average = 0;
		if (count2 > 0) {
			average = Math.round(sum / count2);
			if (variableName === "roomprice") {
				average = Math.round(sum / count2 * 100) / 100;
			}
		}
		
		return average;
	}
	
	getValues(variableName1) {

		var i;
		var data1 = [];
		var daysInMonth = this.props.daysInMonth;
		
		for(i=0; i<daysInMonth; i++) {
			data1.push(0);
		}

		var dashboardVariableListJsonObj = this.props.dashboardVariableListJsonObj;
		var itemObj;
		var variableName2;
		var variableType = "";
		var jsonObj = null;

		for(i=0; i<dashboardVariableListJsonObj.length; i++) {
			itemObj = dashboardVariableListJsonObj[i];
			variableName2 = itemObj.variableName;
			if (variableName1 === variableName2) {
				variableType = itemObj.variableType;
				jsonObj = itemObj.values;
			}
		}
		
		if (jsonObj === null) {
			return null;
		}

		var day;
		var valueInt;
		var valueFloat;
		
		for(i=0; i<jsonObj.length; i++) {
			itemObj = jsonObj[i];
			day = itemObj.day;
			valueInt = itemObj.valueInt;
			valueFloat = itemObj.valueFloat;
			if (variableType === "int") {
				data1[day-1] = valueInt;
			}
			if (variableType === "float") {
				data1[day-1] = valueFloat;
			}
		}
		
		return data1;
	}
		
	getData(variableName) {
		
		var i;
		var data1 = null;
		
		if (variableName === "roomrevenue") {
			var dataRooms = this.getValues("rooms");
			var dataRoomPrice = this.getValues("roomprice");
			if ((dataRooms !== null) && (dataRoomPrice !== null)) {
				data1 = [];
				for(i=0; i<dataRooms.length; i++) {
					var rooms = dataRooms[i];
					var roomPrice = dataRoomPrice[i];
					var roomRevenue = rooms * roomPrice;
					data1.push(roomRevenue);
				}
			}
		}
		
		if (data1 === null) {
			data1 = this.getValues(variableName);
		}

		if (data1 === null) {
			return [null, null];
		}

		var oneRow;
		var labelText;
		var data2 = [];
		
		for(i=0; i<data1.length; i++) {

			oneRow = {};

			// time
			oneRow.time = i+1;

			var varibleString = this.props.getLang(DashboardLang, variableName);
			oneRow[varibleString] = data1[i];
		
			data2.push(oneRow);
		}
		
		return [data1, data2];
	}

	render() {
		
		var dashboardVariableListJsonObj = this.props.dashboardVariableListJsonObj;
		if (dashboardVariableListJsonObj === null) {
			return null;
		}

		var calcHelpersObj = this.props.calcHelpersObj;
		var tab = this.props.tab;
		var variableName = tab;
		var varibleString = this.props.getLang(DashboardLang, tab);
		
		var width1 = window.innerWidth - 10;
		var height1 = window.innerHeight - 200;

		var currentYear = this.props.currentYear;
		var currentMonth = this.props.currentMonth;
		var selectedYear = this.props.selectedYear;
		var selectedMonth = this.props.selectedMonth;

		var currentYearMonthYN = false;
		if ((currentYear === selectedYear) && (currentMonth === selectedMonth)) {
			currentYearMonthYN = true;
		}

		var data1
		var data2;
		var [data1, data2] = this.getData(tab);
		var minValue = this.getMinValue(data1, currentYearMonthYN);
		var maxValue = this.getMaxValue(data1, currentYearMonthYN);
		var averageValue = this.getAverageValue(variableName, data1, currentYearMonthYN);

		var variableType = "int";
		var currencyYN = calcHelpersObj.isVariableCurrencyYN(variableName);
		
		var decimalPlaces = 0;
		if (variableType === "float") {
			decimalPlaces = 2;
		}

		var minValueString = minValue;
		var maxValueString = maxValue;
		var averageValueString = averageValue;
		
		if (currencyYN) {
			minValueString = this.props.getEURString(minValue, decimalPlaces);
			maxValueString = this.props.getEURString(maxValue, decimalPlaces);
			averageValueString = this.props.getEURString(averageValue, decimalPlaces);
		}
		
		return (
			<div>
				<div>
					<LineChart
						width={width1}
						height={height1}
						data={data2}
						margin={{
							top: 5,
							right: 30,
							left: 20,
							bottom: 5,
						}}
					>
						<CartesianGrid strokeDasharray="3 3" />
						<XAxis dataKey="time" />
						<YAxis />
						<Tooltip content={
							<DashboardMainGraphCustomTooltip
								selectedYear={this.props.selectedYear}
								selectedMonth={this.props.selectedMonth}
								variableType={variableType}
								currencyYN={currencyYN}
								getLang={this.props.getLang}
								getEURString={this.props.getEURString}								
								getDayOfWeek={this.props.getDayOfWeek}
								getWeekdayString={this.props.getWeekdayString}
								getWeekdayStringShort={this.props.getWeekdayStringShort}
							/>}
							cursor={false} 
						/>
								
						<Line type="monotone" dataKey={varibleString} stroke="red"/>
					</LineChart>
				</div>
				<div>
					{varibleString} - Min: {minValueString}, Max: {maxValueString}, {this.props.getLang(DashboardLang, "average")}: {averageValueString}
				</div>
			</div>
		);
	}
}

export default DashboardMainGraph;

