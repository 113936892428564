import React  from 'react';
import './App.css';
import './YearlyExpenses.css';
import DashboardLang from './DashboardLang.json';

class YearlyExpensesCustomTooltip extends React.Component {

	constructor(props) {

		super(props);

		this.renderOneLine = this.renderOneLine.bind(this);		
	}

	renderOneLine(index, itemObj) {

		var currencyYN = this.props.currencyYN;
		var variableType = this.props.variableType;
		var name = itemObj.dataKey;
		var color = itemObj.color;
		var payload = itemObj.payload;
		var value = payload[name];

		const divStyle = { color: color };

		var decimalPlaces = 0;
		if (variableType === "float") {
			decimalPlaces = 2;
		}
		
		var valueString = value;
		if (currencyYN) {
			valueString = this.props.getEURString(value, decimalPlaces);
		}

		return (
			<div key={index} style={divStyle}>{name}: {valueString}</div>
		);
	}
	
	render() {
		
		var active = this.props.active;
		var payload = this.props.payload;
		
		if (!active) {
			return null;
		}

		if (payload === null) {
			return null;
		}
		
		var itemObj = payload[0];
		var onePayload = itemObj.payload;
		var name = onePayload.name;
		var monthString = name;
		var i;
		var jsxCode;
		var jsxArray = [];
		
		for(i=0; i<payload.length; i++) {
			itemObj = payload[i];
			jsxCode = this.renderOneLine(i, itemObj);
			jsxArray.push(jsxCode);
		}
		
		return (
			<div className="YearlyExpensesCustomTooltip">
				<div>
					<div>{monthString}</div>
					<div>{jsxArray}</div>
				</div>
			</div>
		);
	}
}

export default YearlyExpensesCustomTooltip;

