import React  from 'react';
import './App.css';
import './Daily.css';
import DailyLang from './DailyLang.json';

class DailyExpenses extends React.Component {

	constructor(props) {

		super(props);
		
		this.state = {
			jsonObj: null,
			uraArray: null,
			searchText: "",
			minAmount: 0,
		};
		
		this.serverDataGetExpensesList = this.serverDataGetExpensesList.bind(this);		
		this.extractUraArray = this.extractUraArray.bind(this);		
		this.onItemClicked = this.onItemClicked.bind(this);
		this.onSearchTextChanged = this.onSearchTextChanged.bind(this);
		this.onMinAmountChanged = this.onMinAmountChanged.bind(this);
		this.checkIfSearchTextFoundYN = this.checkIfSearchTextFoundYN.bind(this);
		this.renderNoReport = this.renderNoReport.bind(this);
		this.renderHeader = this.renderHeader.bind(this);
		this.parseFloatWithSemicolon = this.parseFloatWithSemicolon.bind(this);
		this.renderOneExpenseLine = this.renderOneExpenseLine.bind(this);
		this.renderOneExpense = this.renderOneExpense.bind(this);
		this.renderOneExpenseLineOpen = this.renderOneExpenseLineOpen.bind(this);
		this.renderOneExpenseOpen = this.renderOneExpenseOpen.bind(this);		
		this.renderAllExpenses = this.renderAllExpenses.bind(this);
	}

	componentDidMount() {
		this.serverDataGetExpensesList();
	}

	async serverDataGetExpensesList() {

		this.setState({
			jsonObj: null,
			uraArray: null,
		});

		var baseURLAPI = this.props.baseURLAPI;
		var dashboardID = this.props.dashboardID;
		var dataUrl = "/dashboard/" + dashboardID + "/expenses/current.csv"
		var url = baseURLAPI + "/datagetexpenseslist?url=" +  encodeURIComponent(dataUrl);
		
		if (dashboardID !== 1) {
			return;
		}

		const res = await fetch(url);
		const jsonObj = await res.json();

		this.extractUraArray(jsonObj);
	}
	
	extractUraArray(jsonObj) {
		
		var uraArray = [];
		var dataObj = jsonObj.data;
		var i;
		var itemObj1;
		var ulazniRacunBrojPrev = 0;
		var ulazniRacunBroj;
		var uraObj = null;
		
		for(i=0; i<dataObj.length; i++) {
			itemObj1 = dataObj[i];
			ulazniRacunBroj = itemObj1.ulazniRacunBroj;
			if (ulazniRacunBrojPrev !== ulazniRacunBroj) {
				if (uraObj !== null) {
					uraArray.push(uraObj);
				}
				ulazniRacunBrojPrev = ulazniRacunBroj;
				uraObj = {};
				uraObj.ulazniRacunBroj = ulazniRacunBroj;
				uraObj.openYN = false;
				uraObj.data = [];
			}
			uraObj.data.push(itemObj1);
		}
		
		if (uraObj !== null) {
			uraArray.push(uraObj);
		}

		this.setState({
			jsonObj: jsonObj,
			uraArray: uraArray,
		});
	}
	
	onItemClicked(index) {

		var uraArray = this.state.uraArray;
		var itemObj = uraArray[index];

		var openYN = itemObj.openYN;
		openYN = !openYN;
		itemObj.openYN = openYN;

		this.setState({
			uraArray: uraArray,
		});
	}
	
	onSearchTextChanged(value) {
		var searchText = value.toLowerCase();
		this.setState({searchText: searchText});
	}
	
	onMinAmountChanged(value) {
		var minAmount = parseInt(value);
		this.setState({minAmount: minAmount});
	}
	
	renderNoReport() {

		var dashboardName = this.props.dashboardName;
		var string1 = this.props.getLang(DailyLang, "noExpensesReportForProperty");
		var string2 = string1 + " " + dashboardName;

		return (
			<div>
				<div style={{height: "20px"}}></div>
				{string2}
				<div style={{height: "500px"}}></div>
			</div>
		);
	}

	renderHeader() {

		var jsonObj = this.state.jsonObj;
		var headerObj = jsonObj.header;
		var unixTimeSec = headerObj.unixTimeSec;
		var timeString = this.props.getDateTimeStringFromUnixTimeSec(unixTimeSec);

		return (
			<div>
				{timeString}
				<div style={{height: "20px"}}></div>
				<fieldset className="ViewItemFieldset">
					<legend className="ViewItemLegend">Search</legend> 
					<input
						className="DailyExpensesInput1" 
						id="searchText" 
						type="text" 
						placeholder="text"
						onChange={(event) => this.onSearchTextChanged(event.target.value)}
					/>
					-
					<input
						className="DailyExpensesInput2" 
						id="minAmount" 
						type="number" 
						placeholder="min"
						onChange={(event) => this.onMinAmountChanged(event.target.value)}
					/>				
				</fieldset>
			</div>
		);
	}
	
	parseFloatWithSemicolon(string1) {
		var string2 = string1.replace(".", "");
		var string3 = string2.replace(",", ".");
		return parseFloat(string3);
	}

	renderOneExpenseLine(index, itemObj, potrazuje, konto, nazivKonta) {

		var ulazniRacunBroj = itemObj.ulazniRacunBroj;
		var datum = itemObj.datum;
		var opisKnjizenja = itemObj.opisKnjizenja;
		var poslovniPartner = itemObj.poslovniPartner;
		var potrazujeString = this.props.getEURString(potrazuje, 2);
		
		var className = "DailyExpensesLine1";
		if (index % 2 === 0) {
			className = "DailyExpensesLine2";
		}
		
		return (
			<tr key={index} className={className}>
				<td className="DailyExpensesUlazniRacunBrojItem" onClick={() => this.onItemClicked(index)}>{ulazniRacunBroj}</td>
				<td className="DailyExpensesDatumItem">{datum}</td>
				<td className="DailyExpensesKontoItem">{konto}</td>
				<td className="DailyExpensesNazivKontaItem">{nazivKonta}</td>
				<td className="DailyExpensesPoslovniPartnerItem">{poslovniPartner}</td>
				<td className="DailyExpensesPotrazujeItem">{potrazujeString}</td>
			</tr>
		);
	}
	
	checkIfSearchTextFoundYN(itemObj, searchText) {

		var ulazniRacunBroj = itemObj.ulazniRacunBroj;
		var ulazniRacunBrojString = ulazniRacunBroj.toString();
		var konto = itemObj.konto;
		var kontoString = konto.toString();
		var nazivKonta = itemObj.nazivKonta;
		var opisKnjizenja = itemObj.opisKnjizenja;
		var poslovniPartner = itemObj.poslovniPartner;

		ulazniRacunBrojString = ulazniRacunBrojString.toLowerCase();
		konto = konto.toLowerCase();
		nazivKonta = nazivKonta.toLowerCase();
		opisKnjizenja = opisKnjizenja.toLowerCase();
		poslovniPartner = poslovniPartner.toLowerCase();

		if (ulazniRacunBrojString.includes(searchText)) {
			return true;
		}
		
		if (kontoString.includes(searchText)) {
			return true;
		}
		
		if (nazivKonta.includes(searchText)) {
			return true;
		}
		
		if (opisKnjizenja.includes(searchText)) {
			return true;
		}
		
		if (poslovniPartner.includes(searchText)) {
			return true;
		}
		
		return false;
	}

	renderOneExpense(index, itemObj) {

		var dataObj = itemObj.data;
		var i;
		var itemObj2;
		var potrazuje1;
		var potrazuje2;
		var potrazuje3 = 0;
		var displayBillYN = false;
		var searchText = this.state.searchText;
		var minAmount = this.state.minAmount;

		displayBillYN = false;
		for(i=0; i<dataObj.length; i++) {
			itemObj2 = dataObj[i];
			if (this.checkIfSearchTextFoundYN(itemObj2, searchText)) {
				displayBillYN = true;
			}
			potrazuje1 = itemObj2.potrazuje;
			potrazuje2 = this.parseFloatWithSemicolon(potrazuje1);
			if (potrazuje2 !== 0) {
				potrazuje3 = potrazuje2;
			}
		}

		if (searchText.length < 3) {
			displayBillYN = true;
		}
		
		if (minAmount > 0) {
			if (Math.abs(potrazuje3) < minAmount) {
				displayBillYN = false;
			}
		}
		
		if (!displayBillYN) {
			return [0, null];
		}

		itemObj2 = dataObj[dataObj.length-1];
		var konto = itemObj2.konto;
		var nazivKonta = itemObj2.nazivKonta;
		var jsxCode = null;
		
		for(i=0; i<dataObj.length; i++) {
			itemObj2 = dataObj[i];
			potrazuje1 = itemObj2.potrazuje;
			potrazuje2 = this.parseFloatWithSemicolon(potrazuje1);
			if (potrazuje2 !== 0) {
				jsxCode = this.renderOneExpenseLine(index, itemObj2, potrazuje2, konto, nazivKonta);
				return [potrazuje2, jsxCode];
			}
		}

		return [0, null];
	}
	
	renderOneExpenseLineOpen(index1, index2, itemObj) {

		var ulazniRacunBroj = ""; //itemObj.ulazniRacunBroj;
		var datum = ""; //itemObj.datum;
		var opisKnjizenja = itemObj.opisKnjizenja;
		var poslovniPartner = itemObj.poslovniPartner;
		var konto = itemObj.konto;
		var nazivKonta = itemObj.nazivKonta;
		var duguje = itemObj.duguje;
		var potrazuje = itemObj.potrazuje;
		var amountString = duguje + " / " + potrazuje; //this.props.getEURString(potrazuje, 2);
		
		var key = index1 + "-" + index2;
		var className = "DailyExpensesLineOpen";
		
		return (
			<tr key={key} className={className}>
				<td className="DailyExpensesUlazniRacunBrojItem">{ulazniRacunBroj}</td>
				<td className="DailyExpensesDatumItem">{datum}</td>
				<td className="DailyExpensesKontoItem">{konto}</td>
				<td className="DailyExpensesNazivKontaItem">{nazivKonta}</td>
				<td className="DailyExpensesPoslovniPartnerItem">{poslovniPartner}</td>
				<td className="DailyExpensesPotrazujeItem">{amountString}</td>
			</tr>
		);
	}
	
	renderOneExpenseOpen(index, itemObj) {

		var dataObj = itemObj.data;
		var i;
		var itemObj2;
		var jsxCode;
		var jsxArray = [];

		for(i=0; i<dataObj.length; i++) {
			itemObj2 = dataObj[i];
			jsxCode = this.renderOneExpenseLineOpen(index, i, itemObj2);
			jsxArray.push(jsxCode);
		}

		return jsxArray;
	}

	renderAllExpenses() {

		var i;
		var uraArray = this.state.uraArray;
		var length = uraArray.length;
		var itemObj;
		var jsxCode;
		var jsxArray = [];
		var potrazuje;
		var openYN;
		var total = 0;
		
		// temp
		//length = 1;
		
		for(i=0; i<length; i++) {
			itemObj = uraArray[i];
			[potrazuje, jsxCode] = this.renderOneExpense(i, itemObj);
			if (jsxCode !== null) {
				total = total + potrazuje;
				jsxArray.push(jsxCode);
				openYN = itemObj.openYN;
				if (openYN) {
					jsxCode = this.renderOneExpenseOpen(i, itemObj);
					jsxArray.push(jsxCode);
				}
			}
		}
		
		var totalString = this.props.getEURString(total, 2);
		
		return (
			<div>
				<table>
					<tbody>
						<tr>
							<td className="DailyExpensesUlazniRacunBrojHeader">{this.props.getLang(DailyLang, "ulazniRacunBroj")}</td>
							<td className="DailyExpensesDatumHeader">{this.props.getLang(DailyLang, "datum")}</td>
							<td className="DailyExpensesKontoHeader">{this.props.getLang(DailyLang, "konto")}</td>
							<td className="DailyExpensesNazivKontaHeader">{this.props.getLang(DailyLang, "nazivKonta")}</td>
							<td className="DailyExpensesPoslovniPartnerHeader">{this.props.getLang(DailyLang, "poslovniPartner")}</td>
							<td className="DailyExpensesPotrazujeHeader">{this.props.getLang(DailyLang, "potrazuje")}</td>
						</tr>
						{jsxArray}
						<tr>
							<td className="DailyExpensesUlazniRacunBrojHeader">{this.props.getLang(DailyLang, "total")}:</td>
							<td></td>
							<td></td>
							<td></td>
							<td></td>
							<td className="DailyExpensesPotrazujeHeader">{totalString}</td>
						</tr>
					</tbody>
				</table>
			</div>
		);
	}
	
	render() {

		var dashboardID = this.props.dashboardID;
		if (dashboardID !== 1) {
			return this.renderNoReport();
		}

		var jsonObj = this.state.jsonObj;
		if (jsonObj === null) {
			return;			
		}

		return (
			<div>
				<div style={{height: "20px"}}></div>
				{this.renderHeader()}
				<div style={{height: "20px"}}></div>
				{this.renderAllExpenses()}
				<div style={{height: "50px"}}></div>
			</div>
		);
	}
}

export default DailyExpenses;

