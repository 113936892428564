import React  from 'react';
import './App.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LoginLang from './LoginLang.json';
import { readEmailPassword, storeEmailPassword } from './LoginLocalStorage.js';

import { useNavigate } from "react-router-dom";

class Login extends React.Component {

	constructor(props) {

		super(props);
		
		this.state = {
			email: "",
			password: "",
		};

		this.onKeyDown = this.onKeyDown.bind(this);
		this.onEmailChanged = this.onEmailChanged.bind(this);
		this.onPasswordChanged = this.onPasswordChanged.bind(this);
		this.serverLogin = this.serverLogin.bind(this);
		this.onClickLoginButton = this.onClickLoginButton.bind(this);
	}
	
	componentDidMount() {
		var email;
		var password;
		[email, password] = readEmailPassword();
		this.setState({email: email});
		if ((email !== "") && (email !== null)) {
			this.serverLogin(email, password);
		}
	}
	
	onKeyDown(keyCode) {
		if (keyCode === 13) {
			this.onClickLoginButton();
		}
	}

	onEmailChanged(value) {
		this.setState({email: value});
	}
	
	onPasswordChanged(value) {
		this.setState({password: value});
	}
	
	async serverLogin(email, password) {

		var managementBaseURLAPI = this.props.managementBaseURLAPI;
		var url = managementBaseURLAPI + "/login?email=" + encodeURI(email);
		url = url + "&password=" + encodeURI(password);
		
		var message = this.props.getLang(LoginLang, "incorrectLoginMessage");
		
		try {
			const res = await fetch(url);
			var jsonObj = await res.json();
		} catch (e) {
			toast(message, {
				position: "top-right",
				autoClose: 5000,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
			});
			return;
		}
		
		var dbtouch = jsonObj.dbtouch;
		var userID = jsonObj.userID;
		var sessionID = jsonObj.sessionID;
		
		this.props.setUserID(userID);
		this.props.setSessionID(sessionID);

		if (dbtouch === 1) {
			this.props.serverDBTouchGetUserData(userID);
		} else {
			this.props.serverGetUserData(userID);
		}

		storeEmailPassword(email, password);
		
		const navigate = this.props.navigate;
  		navigate("/monthly");
	}
		
	onClickLoginButton() {
		this.serverLogin(this.state.email, this.state.password);
	}
	
	render() {
		
		return (		
			<div className="ViewContainer">

				<div style={{height: "30px"}}></div>
				<div className="ViewTopTitle">Plurato login</div>
				<div className="ViewTopText">{this.props.getLang(LoginLang, "infoMessage")}</div>

				<div style={{height: "30px"}}></div>
				<div className="LoginBlockContainer">

					<div style={{height: "15px"}}></div>
					<div className="ViewBlockTitle">Login info</div>
					<div style={{height: "30px"}}></div>
					<div className="ViewItemContainer">
						<fieldset className="ViewItemFieldset">
							<legend className="ViewItemLegend">Email</legend> 
							<input
								className="ViewItemInput" 
								id="email" 
								type="text" 
								autoFocus={true}
								onChange={(event) => this.onEmailChanged(event.target.value)}
								onKeyDown={(event) => this.onKeyDown(event.keyCode)}
							/>
						</fieldset>
					</div>
					<div style={{height: "10px"}}></div>
					<div className="ViewItemContainer">
						<fieldset className="ViewItemFieldset">
							<legend className="ViewItemLegend">{this.props.getLang(LoginLang, "password")}</legend> 
							<input
								className="ViewItemInput" 
								id="password" 
								type="password" 
								onChange={(event) => this.onPasswordChanged(event.target.value)}
								onKeyDown={(event) => this.onKeyDown(event.keyCode)}
							/>
						</fieldset>
					</div>
					

					<div style={{height: "50px"}}></div>
					<div className="ViewContainerButtons">
						<div style={{width: "30px"}}></div>
						<button className="ViewSaveButton" onClick={this.onClickLoginButton}>Login</button>
					</div>
					<div style={{height: "50px"}}></div>
				</div>

				<div style={{height: "100px"}}></div>
			</div>
		);
	}
}

export default function LoginFunction(props) {
	const navigate = useNavigate();
	return <Login {...props} navigate={navigate} />;
}

